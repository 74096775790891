import React from 'react';
import PropTypes from 'prop-types';
import { useModal } from 'hooks/useModal';
import AskSpecificPointModal from 'components/modals/AskSpecificPointModal';

/**
 * AskSpecificPointModal container
 * @param {String} id
 */
const AskSpecificPointContainer = ({ id }) => {
	const { onClose } = useModal(id);

	return (
		<AskSpecificPointModal
			onClose={onClose}
		/>
	)
};

AskSpecificPointContainer.propTypes = {
	id: PropTypes.string.isRequired,
}

export default React.memo(AskSpecificPointContainer);
