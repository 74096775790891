import React, { memo, useCallback, useMemo } from 'react';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';

import InviteCollaborator from 'containers/collaborators/InviteCollaborator';
import BaseDataTable from 'components/common/BaseDataTable';
import { ROUTES } from 'constants/routes';
import { STATUS_PENDING } from 'constants/common';

import { useStyles } from './styles';

/**
 * Local component for usage in PeoplePage component
 * Displays buttons that displayed by hovering on table row with pending user
 */
const PendingUserHoverCell = ({
  user,
  classes,
  resendButtonName,
  cancelButtonName,
  resendInvite,
  revokeInvite,
}) => {
  const handleResendClick = useCallback(() => {
    resendInvite(user);
  }, [resendInvite, user]);
  const handleRevokeClick = useCallback(() => {
    revokeInvite(user);
  }, [revokeInvite, user]);

  return (
    <ButtonGroup color="secondary" variant="text" className={classes.buttons}>
      <Button className={classes.textButton} onClick={handleResendClick}>
        {resendButtonName}
      </Button>
      <Button className={classes.textButton} onClick={handleRevokeClick}>
        {cancelButtonName}
      </Button>
    </ButtonGroup>
  );
};

/**
 * Local component for usage in PeoplePage component
 * Displays buttons that displayed by hovering on table row with confirmed user
 */
const ConfirmedUserHoverCell = ({
  user,
  editProfile,
  removeProfile,
  classes,
  editButtonName,
  addButtonName,
  removeButtonName,
  isCurrentUser,
  addToProject,
  canEditUser,
  canAddUserToProject,
}) => {
  const handleEditClick = useCallback(() => editProfile(user), [
    editProfile,
    user,
  ]);
  const handleRemoveClick = useCallback(() => removeProfile(user), [
    editProfile,
    user,
  ]);
  const handleAddToProjectClick = useCallback(() => addToProject(user), [
    addToProject,
    user,
  ]);

  return canEditUser || canAddUserToProject || isCurrentUser ? (
    <ButtonGroup color="secondary" variant="text" className={classes.buttons}>
      {(canEditUser || isCurrentUser) && (
        <Button className={classes.textButton} onClick={handleEditClick}>
          {editButtonName}
        </Button>
      )}
      {canAddUserToProject && !isCurrentUser && (
        <Button
          className={classes.textButton}
          onClick={handleAddToProjectClick}
        >
          {addButtonName}
        </Button>
      )}
      {canEditUser && !isCurrentUser && (
        <Button className={classes.textButton} onClick={handleRemoveClick}>
          {removeButtonName}
        </Button>
      )}
    </ButtonGroup>
  ) : null;
};

/**
 * Local component for usage in PeoplePage component
 * Displays header role cell with icon
 */
const RoleHeaderCell = ({ title, classes }) => (
  <div className={classes.roleHeaderCell}>
    <div>{title}</div>
    <Link to={ROUTES.faq}>
      <HelpOutlineIcon
        fontSize="small"
        color="primary"
        className={classes.faqLink}
      />
    </Link>
  </div>
);

/**
 * Local component for usage in PeoplePage component
 * Displays role cell
 */
const RoleCell = ({ title, classes, status }) =>
  title === STATUS_PENDING ? (
    <Typography className={classes.statusBlock} align="center">
      {status}
    </Typography>
  ) : (
    <Typography align="center">{title}</Typography>
  );

/**
 * PeoplePage component
 * Displays collaborators table
 */
const PeoplePage = ({
  collaborators,
  editProfile,
  removeProfile,
  currentUserId,
  addToProject,
  revokeInvite,
  resendInvite,
  canEditUsers,
  canManipulateInvitations,
  canAddUserToProject,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const columns = useMemo(
    () => [
      {
        field: 'fullName',
        title: t('collaborators.name'),
      },
      {
        field: 'email',
        title: t('collaborators.email'),
      },
      {
        field: 'role',
        title: t('collaborators.role'),
        headerCell: (title) => (
          <RoleHeaderCell title={title} classes={classes} />
        ),
        cell: (title) => (
          <RoleCell
            title={title}
            classes={classes}
            status={t('collaborators.invitedStatus')}
          />
        ),
      },
      {
        field: 'usedCredits',
        title: t('collaborators.usedCredits'),
      },
      {
        field: 'edit',
        title: '',
        cell: (field, item) => {
          if (item.role !== STATUS_PENDING) {
            return (
              <ConfirmedUserHoverCell
                editProfile={editProfile}
                removeProfile={removeProfile}
                addToProject={addToProject}
                user={item}
                classes={classes}
                editButtonName={t('buttons.edit')}
                removeButtonName={t('buttons.remove')}
                addButtonName={t('buttons.addToProject')}
                isCurrentUser={currentUserId === item.id}
                canEditUser={canEditUsers}
                canAddUserToProject={canAddUserToProject}
              />
            );
          }
          return canManipulateInvitations ? (
            <PendingUserHoverCell
              user={item}
              classes={classes}
              resendButtonName={t('buttons.resendInvite')}
              cancelButtonName={t('buttons.cancelInvite')}
              resendInvite={resendInvite}
              revokeInvite={revokeInvite}
            />
          ) : null;
        },
      },
    ],
    [
      t,
      editProfile,
      removeProfile,
      classes,
      currentUserId,
      addToProject,
      resendInvite,
      revokeInvite,
      canEditUsers,
      canAddUserToProject,
      canManipulateInvitations,
    ]
  );

  return (
    <div className={classes.container}>
      <div className={classes.subContainer}>
        <div className={classes.titleContainer}>
          <Typography className={classes.title} variant="h5">
            {t('collaborators.title')}
          </Typography>
          &nbsp;
          <Typography className={classes.collaborators}>
            <strong>{collaborators.length}</strong> {t('collaborators.length')}
          </Typography>
        </div>

        <InviteCollaborator />
      </div>

      <BaseDataTable
        data={collaborators}
        columns={columns}
        className={classes.tableStyles}
      />
    </div>
  );
};

PeoplePage.propTypes = {
  collaborators: PropTypes.array.isRequired,
  editProfile: PropTypes.func.isRequired,
  removeProfile: PropTypes.func.isRequired,
  addToProject: PropTypes.func.isRequired,
  resendInvite: PropTypes.func.isRequired,
  revokeInvite: PropTypes.func.isRequired,
  currentUserId: PropTypes.number.isRequired,
  canEditUsers: PropTypes.bool.isRequired,
  canManipulateInvitations: PropTypes.bool.isRequired,
  canAddUserToProject: PropTypes.bool.isRequired,
};

export default memo(PeoplePage);
