import React, { useState, useEffect } from 'react';
import PropsTypes from 'prop-types';
import { Grid, Checkbox, IconButton } from '@material-ui/core';
import DirectionsBoatIcon from '@material-ui/icons/DirectionsBoat';
import DeleteIcon from '@material-ui/icons/Delete';

import BlockUpdateComponent from 'components/common/MarineContractors/BlockUpdateComponent/index';
import EditableTextField from 'components/common/MarineContractors/EditableTextField/index';

import { useStyles } from '../styles';

/**
 * Boat component - display boat main information and wrap list of operations
 * @param { object } boat
 * @param { boolean } isChecked - control the boat check button
 * @param { boolean } isSelected
 * @param { boolean } blockDeletion - hide delete button and display a circular progress
 * @param { function } handleOnCheck
 * @param { function } handleOnDelete
 * @param { function } handleSelect
 * @returns { JSX }
 */
const BoatComponent = ({
  boat,
  isChecked,
  isSelected,
  forceFocus,
  blockDeletion,
  handleOnCheck,
  handleOnDelete,
  handleOnChange,
  handleHasValid,
  handleSelect,
}) => {
  const classes = useStyles();
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    setChecked(isChecked);
  }, [isChecked]);

  return (
    <Grid
      container
      direction="row"
      alignItems="center"
      justifyContent="center"
      onClick={() => handleSelect(boat.id)}
      className={isSelected ? classes.gridItemSelected : classes.gridItem}
    >
      <Grid item xs={2}>
        <Checkbox
          onChange={handleOnCheck}
          value={boat.dispatch}
          checked={checked}
          size="small"
        />
      </Grid>
      <Grid item xs={2}>
        <DirectionsBoatIcon color="primary" fontSize="small" />
      </Grid>
      <Grid item xs={6}>
        <EditableTextField
          object={boat}
          fieldName="name"
          canEdit={isSelected}
          forceFocus={forceFocus.name}
          forceTitle
          handleHasValid={handleHasValid}
          handleChange={handleOnChange}
        />
      </Grid>
      <Grid item xs={2}>
        <BlockUpdateComponent isUpdating={blockDeletion} noGrow isCircular>
          <IconButton size="small" onClick={handleOnDelete}>
            <DeleteIcon color="error" fontSize="small" />
          </IconButton>
        </BlockUpdateComponent>
      </Grid>
    </Grid>
  );
};

BoatComponent.propTypes = {
  boat: PropsTypes.object.isRequired,
  isChecked: PropsTypes.bool,
  isSelected: PropsTypes.bool,
  blockDeletion: PropsTypes.bool,
  handleOnCheck: PropsTypes.func.isRequired,
  handleOnDelete: PropsTypes.func.isRequired,
  handleSelect: PropsTypes.func.isRequired,
};

BoatComponent.defaultProps = {
  isChecked: false,
  isSelected: false,
  blockDeletion: false,
};

export default React.memo(BoatComponent);
