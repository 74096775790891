import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  floatingButton: {
    height: 43,
    width: 220,
    borderRadius: 15,
    zIndex: theme.zIndexNavBar + 1,
    backgroundColor: theme.palette.sinay.primary,
    '&:hover, &.active': {
      backgroundColor: theme.palette.sinay.accent,
    },
    '&:focus': {
      outline: 'none',
    },
  },
  label: {
    color: 'white',
    fontWeight: 650,
    textTransform: 'none',
  },
}));
