import React, { memo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AddCircleOutline as AddCircleOutlineIcon } from '@material-ui/icons';
import { Typography, Grid, Button } from '@material-ui/core';

import { MapPopup } from 'components/leaflet';
import {
  BaseMapPopup,
  BaseMapPopupContent,
  BaseMapPopupTitle,
} from 'components/common/BaseMapPopup';
import CreateProject from 'containers/projects/CreateProject';
import { centeringMapByLatLng } from 'helpers/map';
import ZoneDataset from 'containers/zones/ZoneDataset';
import { useMapInstance } from 'hooks/leaflet';
import { SUPPORT_EMAIL } from 'common/config';
// CREATE_PROJECT_DATASET_OFFSET,
import { DEFAULT_CENTERING_MAP_DELAY } from 'constants/map';
import NativeLink from 'components/common/NativeLink';

import { useStyles } from './styles';

/**
 * Popup for select zone map. Create new project popup.
 * @param {Number} offerId
 * @param {Object} props
 * @returns {jsx}
 */
const SelectZonePopup = ({ offerId, ...props }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const mapInstance = useMapInstance();
  const [selectedFeature, setSelectedFeature] = useState();

  const closePopup = useCallback(() => {
    mapInstance.closePopup();
    setSelectedFeature(undefined);
  }, [mapInstance, setSelectedFeature]);

  const openPopupHandler = useCallback(
    ({ popup: { _source: polygon, _latlng: latlng } }) => {
      const { feature, _map: map } = polygon;
      const zone = feature.properties;

      setSelectedFeature({ latlng, zone });
      // eslint-disable-next-line
      const { zoom } = map._getBoundsCenterZoom(polygon.getBounds());
      setTimeout(centeringMapByLatLng, DEFAULT_CENTERING_MAP_DELAY, {
        map,
        latLng: latlng,
        zoom,
      });
    },
    [setSelectedFeature]
  );

  // const expandHandler = useCallback(
  //   (expanded) => {
  //     setTimeout(centeringMapByLatLng, DEFAULT_CENTERING_MAP_DELAY, {
  //       map: mapInstance,
  //       latLng: selectedFeature.latlng,
  //       offset: {
  //         y: expanded ? CREATE_PROJECT_DATASET_OFFSET : undefined,
  //       },
  //     });
  //   },
  //   [selectedFeature, mapInstance]
  // );

  return (
    <MapPopup {...props} onOpen={openPopupHandler}>
      {selectedFeature && (
        <BaseMapPopup className={classes.popupWrapper}>
          <BaseMapPopupTitle>
            <AddCircleOutlineIcon />
            {t('projects.create.title', selectedFeature.zone)}
          </BaseMapPopupTitle>
          <BaseMapPopupContent>
            {selectedFeature.zone.inOffer || selectedFeature.zone.isDemo ? (
              <CreateProject
                zoneId={selectedFeature.zone.id}
                offerInstance={offerId}
                onClose={closePopup}
              />
            ) : (
              <>
                <Typography
                  align="center"
                  variant="body2"
                  className={classes.helpText}
                >
                  {t('projects.create.zoneNotAvailable')}&nbsp;
                  {t('projects.create.please')}&nbsp;
                  <NativeLink to={`mailto:${SUPPORT_EMAIL}`}>
                    {t('projects.create.contactUs')}
                  </NativeLink>
                  &nbsp;
                  {t('projects.create.interested')}
                </Typography>
                <ZoneDataset zoneId={selectedFeature.zone.id} />
                <Grid container justifyContent="center">
                  <Button
                    onClick={closePopup}
                    variant="contained"
                    className={classes.closeButton}
                  >
                    {t('buttons.close')}
                  </Button>
                </Grid>
              </>
            )}
          </BaseMapPopupContent>
        </BaseMapPopup>
      )}
    </MapPopup>
  );
};

export default memo(SelectZonePopup);
