import React, { memo } from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

import { useStyles } from './styles';

/**
 * CheckboxIconButton component.
 * Works as a checkbox.
 * @param { boolean } isChecked
 * @param checkboxHandler -  handler for checkbox toggling
 * @param props
 */
const CheckboxIconButton = ({ isChecked, checkboxHandler, ...props }) => {
  const classes = useStyles();

  return (
    <IconButton className={classes.icon} onClick={checkboxHandler} {...props}>
      {isChecked ? (
        <CheckCircleOutlineIcon fontSize="large" color="secondary" />
      ) : (
        <AddCircleOutlineIcon fontSize="large" />
      )}
    </IconButton>
  );
};

CheckboxIconButton.propTypes = {
  isChecked: PropTypes.bool,
};

export default memo(CheckboxIconButton);
