import { takeLatest, put, call, select } from 'redux-saga/effects';

import { getContentTypes } from 'services/contentTypes';

import {
  requestContentTypes,
  requestContentTypesSuccess,
  requestContentTypesError,
} from './actions';
import { REQUEST_CONTENT_TYPES, REQUEST_CONTENT_TYPES_IF_NEED } from './types';
import { selectContentTypes } from './selectors';

function* requestContentTypesWorker() {
  try {
    const contentTypes = yield call(getContentTypes);
    yield put(requestContentTypesSuccess(contentTypes));
  } catch (error) {
    yield put(requestContentTypesError(error));
  }
}

function* requestContentTypesIfNeed() {
  const contentTypes = yield select(selectContentTypes);

  if (!contentTypes.length) {
    yield put(requestContentTypes());
  }
}

export default function* contentTypeSagaWatcher() {
  yield takeLatest(REQUEST_CONTENT_TYPES, requestContentTypesWorker);
  yield takeLatest(REQUEST_CONTENT_TYPES_IF_NEED, requestContentTypesIfNeed);
}
