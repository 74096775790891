import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { trackLogoClick } from 'ducks/trackers/actions/navigation';
import { ROUTES } from 'constants/routes';
import logo from 'assets/images/logo_sinay.svg';

import { useStyles } from './styles';

/**
 * Footer component.
 * Adds footer with mui AppBar.
 * @param footer
 * @param { Array } links
 */
const Footer = ({ hijackMode }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles({ hijackMode });
  const currentYear = new Date().getFullYear();

  // track click on logo
  const sendLogoClickTrackingData = useCallback(
    () => dispatch(trackLogoClick({})),
    [dispatch]
  );

  return (
    <footer className={classes.footer}>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item>
          <Link
            title="home"
            to={ROUTES.home}
            className={classes.logo}
            onClick={sendLogoClickTrackingData}
          >
            <img src={logo} alt={t('alts.logo')} />
          </Link>
        </Grid>

        <Grid item className={classes.text}>
          {currentYear} All right reserved
        </Grid>
      </Grid>
    </footer>
  );
};

Footer.propTypes = {
  hijackMode: PropTypes.bool,
};

export default memo(Footer);
