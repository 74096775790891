import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  title: {
    margin: '20px',
  },
  subtitle: {
    marginBottom: '30px',
  },
  checkbox: {
    marginRight: '30px',
  },
  coords: {
    marginRight: '60px',
  },
  name: {
    marginRight: '150px',
  },
  hr: {
    marginBottom: 0,
    marginTop: 0,
  },
  buttonComponent: {
    textAlign: 'right',
    margin: '20px',
  },
  button: {
    margin: '10px',
  },
  mapContainer: {
    height: '550px',
    width: '90%',
  },
  locations: {
    width: '80%',
    maxHeight: '650px',
  },
}));
