import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  comparisonAgainstModal: {
    maxWidth: 600,
    width: '100%',
  },
  comparisonText: {
    fontSize: 14,
    '&:not(:last-child)': {
      marginBottom: 10,
    },
  },
  progress: {
    position: 'absolute',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'center',
    '& > *': {
      marginRight: 15,
    },
  },
});
