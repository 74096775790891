import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Grid } from '@material-ui/core';

import BaseModal from 'components/common/BaseModal';
import { SUPPORT_EMAIL } from 'common/config';

import { useStyles } from './styles';

/**
 * StartAnalysisModal component
 * Modal for approve start analysis
 * @param {function} closeModal
 * @param {function} onConfirm
 * @param {String} title
 * @param {Number} credits
 * @param {Number} availableCredits
 * @param {String} userEmail
 * @param {String} message
 * @param {boolean} isUnlimitedOffer
 * @param {Number} nbOfferAuthPoints
 * @param {Number} nbProjectPoints
 */
const StartAnalysisModal = ({
  closeModal,
  onConfirm,
  title,
  credits,
  availableCredits,
  userEmail,
  isUnlimitedOffer,
  nbOfferAuthPoints,
  nbProjectPoints,
  informationalOfferMessage,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const hasCredits = availableCredits >= credits;
  const allowToBuy = isUnlimitedOffer || hasCredits;
  const isNbProjectPointsAuth =
    nbOfferAuthPoints !== 0 ? nbOfferAuthPoints >= nbProjectPoints : true;

  const controls = (
    <Grid container justifyContent="center">
      <Button size="medium" variant="contained" onClick={closeModal}>
        {t('buttons.cancel')}
      </Button>
      {allowToBuy && isNbProjectPointsAuth && (
        <Button
          variant="contained"
          color="secondary"
          onClick={onConfirm}
          className={classes.confirmButton}
        >
          {t('buttons.confirm')}
        </Button>
      )}
    </Grid>
  );

  return (
    <BaseModal
      title={(() => {
        if (!allowToBuy) {
          return t('points.startAnalysisModal.notEnough.title');
        }
        if (!isNbProjectPointsAuth) {
          return t('points.startAnalysisModal.authPoints.title');
        }
        return title;
      })()}
      onClose={closeModal}
      modalClass={classes.container}
      controls={controls}
    >
      <div className={classes.body}>
        {(() => {
          if (!allowToBuy) {
            return (
              <div>
                <div>
                  {t('points.startAnalysisModal.notEnough.messagePart1', {
                    credits,
                    availableCredits,
                  })}
                </div>
                <div>
                  {t('points.startAnalysisModal.notEnough.messagePart2')}
                  <a href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</a>
                </div>
              </div>
            );
          }
          if (!isNbProjectPointsAuth) {
            return (
              <div>
                <div>
                  {t('points.startAnalysisModal.authPoints.message', {
                    nbOfferAuthPoints,
                  })}
                </div>
                <div>
                  {t('points.startAnalysisModal.notEnough.messagePart2')}
                  <a href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</a>
                </div>
              </div>
            );
          }
          return (
            <div>
              <div className={classes.message}>
                {informationalOfferMessage ||
                  t('points.startAnalysisModal.message')}
              </div>
              {!isUnlimitedOffer && (
                <div>
                  {t('points.startAnalysisModal.creditUsed', { credits })}
                </div>
              )}
              <div>
                {t('points.startAnalysisModal.mailSent')}
                <a href={`mailto:${userEmail}`}>{userEmail}</a>
              </div>
              <div>{t('points.startAnalysisModal.analysisFinished')}</div>
            </div>
          );
        })()}
      </div>
    </BaseModal>
  );
};

StartAnalysisModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  availableCredits: PropTypes.number.isRequired,
  credits: PropTypes.number.isRequired,
  userEmail: PropTypes.string.isRequired,
  isUnlimitedOffer: PropTypes.bool,
};

export default React.memo(StartAnalysisModal);
