import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: 568,
    width: '100%',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.white.main,
    '& .modal_close-button': {
      color: theme.palette.white.main,
    },
  },
  body: {
    textAlign: 'center',
    padding: '10px 5px',
  },
  message: {
    paddingBottom: 10,
  },
  confirmButton: {
    marginLeft: 10,
  },
}));
