import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    margin: '0 58px',
  },
  subContainer: {
    maxWidth: 1200,
    margin: '20px auto',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    fontWeight: 'bold',
    color: theme.palette.sinay.accent,
  },
  collaborators: {
    fontSize: 20,
    marginLeft: 20,
    color: theme.palette.sinay.primary,
  },
  tableStyles: {
    '& tbody': {
      '& tr': {
        '&:hover': {
          '& $buttons': {
            visibility: 'visible',
          },
        },
      },
    },
  },

  faqLink: {
    marginLeft: 5,
    color: theme.palette.primary.light,
  },
  buttons: {
    visibility: 'hidden',
    borderBottom: 0,
    '&:hover': {
      cursor: 'pointer',
    },
    display: 'flex',
    justifyContent: 'center',
  },
  textButton: {
    color: theme.palette.sinay.accent,
    '&:focus': {
      outline: 'none',
    },
  },
  statusBlock: {
    backgroundColor: theme.palette.grey.lightBlue,
    padding: 3,
    color: 'white',
    textTransform: 'uppercase',
    borderRadius: 3,
  },
  roleHeaderCell: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    fontWeight: 'bold',
  },
}));
