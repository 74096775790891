import PropTypes from 'prop-types';
import React, { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';

import Checker from 'components/colors/Checker';
import Selector from 'components/colors/Selector';
import { GEOMETRIES_COLORS } from 'constants/color';

import { useStyles } from './styles';

/**
 * GeometriesList list with all geometries
 * @param {Array} geometries
 * @param {Function} onDelete
 */
const GeometriesList = ({
  onDelete,
  geometries,
  onUpdateGeometry,
  onUpdateLayer,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const colors = GEOMETRIES_COLORS.map(({ value }) => value);

  const deleteHandler = useCallback(
    (event) => {
      event.stopPropagation();
      onDelete(+event.currentTarget.dataset.geometryId);
    },
    [onDelete]
  );

  const updateGeometry = useCallback(
    ({ currentTarget }, color) =>
      onUpdateGeometry(+currentTarget.dataset.geometryId, { color }),
    [onUpdateGeometry]
  );

  const updateLayer = useCallback(
    ({ currentTarget }, color) => {
      onUpdateLayer(
        +currentTarget.dataset.geometryId,
        +currentTarget.dataset.layerId,
        {
          color,
          type: currentTarget.dataset.layerType,
        }
      );
    },
    [onUpdateLayer]
  );

  return (
    <Grid container className={classes.geometriesList}>
      {geometries.map(({ name, id, layers }) => (
        <Accordion
          key={id}
          classes={{
            root: classes.panel,
            expanded: classes.expanded,
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            classes={{
              root: classes.summary,
            }}
          >
            <Grid container alignItems="center" justifyContent="space-between">
              <span>{name}</span>
              <IconButton
                data-geometry-id={id}
                size="small"
                edge="end"
                onClick={deleteHandler}
              >
                <DeleteIcon />
              </IconButton>
            </Grid>
          </AccordionSummary>
          <AccordionDetails
            classes={{
              root: classes.details,
            }}
          >
            <List
              classes={{
                root: classes.options,
              }}
            >
              {layers.length > 1 ? (
                <div>
                  <ListItem
                    key={`list${id}`}
                    classes={{
                      root: classes.items,
                    }}
                  >
                    <span>{t('geometries.options.general')}</span>
                    <Selector
                      key={id}
                      classes={{
                        root: classes.checker,
                      }}
                      data-geometry-id={id}
                      colors={colors}
                      onClick={updateGeometry}
                    />
                  </ListItem>
                  <Divider />
                </div>
              ) : null}
              {layers.map((layer) => (
                <ListItem
                  key={`list${layer.id}`}
                  classes={{
                    root: classes.items,
                  }}
                >
                  <span>{layer.name}</span>
                  <Checker
                    classes={{
                      root: classes.checker,
                    }}
                    data-geometry-id={id}
                    data-layer-id={layer.id}
                    data-layer-type={layer.type}
                    colors={colors}
                    select={layer.color}
                    onClick={updateLayer}
                  />
                </ListItem>
              ))}
            </List>
          </AccordionDetails>
        </Accordion>
      ))}
    </Grid>
  );
};

GeometriesList.propTypes = {
  geometries: PropTypes.array.isRequired,
  onDelete: PropTypes.func.isRequired,
  onUpdateGeometry: PropTypes.func.isRequired,
  onUpdateLayer: PropTypes.func.isRequired,
};

export default memo(GeometriesList);
