import {
  takeLatest,
  takeEvery,
  put,
  call,
  select,
  all,
  delay,
  race,
  take,
} from 'redux-saga/effects';

import {
  getProjects,
  createProject,
  createDemoProject,
  deleteProject,
  renameProject,
  getProject,
  computeProjectStatistics,
  updateProjectCollaborators,
  getProjectStats,
  cancelComputeProjectStatistics,
  getProjectVisualizationData,
  getProjectZone,
  downloadGraphFile,
} from 'services/projects';
import { trackProjectCreation } from 'ducks/trackers/actions/projectManagment';
import { trackProjectComputation } from 'ducks/trackers/actions/workzone';
import { trackProjectCollaboratorChange } from 'ducks/trackers/actions/admin';
import {
  prepareProjectStatistics,
  getProjectOverviewStatistics,
} from 'helpers/statistics';
import { UPDATE_PROJECT_TIMEOUT } from 'common/config';
import { triggerDownloadBlob } from 'helpers/dom';
import { OCTET_STREAM_FILE_TYPE } from 'constants/common';
import { prepareParamsForGraphDownloading } from 'helpers/projects';

import {
  selectProjectOverviewStatsCollectionById,
  selectProjectById,
  selectProjectsIds,
  selectProjectVisualizationDataById,
} from './selectors';
import {
  CREATE_PROJECT,
  REQUEST_PROJECTS,
  REQUEST_PROJECT,
  DELETE_PROJECT,
  RENAME_PROJECT,
  REQUEST_PROJECT_IF_NEED,
  COMPUTE_PROJECT_STATISTICS,
  REQUEST_PROJECTS_IF_NEED,
  UPDATE_PROJECT_COLLABORATORS_REQUEST,
  REQUEST_PROJECT_STATS,
  CANCEL_COMPUTE_PROJECT_STATISTICS,
  REQUEST_PROJECT_VISUALIZATION_DATA,
  REQUEST_PROJECT_VISUALIZATION_DATA_IF_NEED,
  REQUEST_PROJECT_ZONE,
  START_UPDATE_PROJECT_JOB,
  STOP_UPDATE_PROJECT_JOB,
  DOWNLOAD_GRAPH_FILE,
  CREATE_TRIAL_PROJECT,
  CREATE_DEMO_PROJECT,
} from './types';
import {
  requestProjectsSuccess,
  requestProjectsError,
  requestProjectSuccess,
  requestProjectError,
  createProjectSuccess,
  createProjectError,
  deleteProjectSuccess,
  deleteProjectError,
  renameProjectSuccess,
  renameProjectError,
  requestProject,
  computeProjectStatisticsSuccess,
  computeProjectStatisticsError,
  requestProjects,
  updateProjectCollaboratorsRequestSuccess,
  updateProjectCollaboratorsRequestError,
  requestProjectStatsSuccess,
  requestProjectStatsError,
  cancelComputeProjectStatisticsSuccess,
  cancelComputeProjectStatisticsError,
  requestProjectVisualizationDataSuccess,
  requestProjectVisualizationDataError,
  requestProjectVisualizationData,
  requestProjectZoneSuccess,
  requestProjectZoneError,
  downloadGraphFileError,
  downloadGraphFileSuccess,
  finishUpdateProjectJob,
  createTrialProjectError,
  createTrialProjectSuccess,
  createDemoProjectError,
  createDemoProjectSuccess,
} from './actions';

/**
 * Requests all projects, normalizes received projects collection
 */
export function* requestProjectsWorker({ params }) {
  try {
    const projects = yield call(getProjects, params);
    yield put(requestProjectsSuccess(projects));
  } catch (error) {
    yield put(requestProjectsError(error));
  }
}

/**
 * Checks if projects exists, if not, requests it
 */
export function* requestProjectsIfNeedWorker({ params }) {
  const projectsIds = yield select(selectProjectsIds);

  if (!projectsIds.length) {
    yield put(requestProjects({ params }));
  }
}

/**
 * Requests single projects, normalizes received projects collection
 */
export function* requestProjectWorker({ params, projectId }) {
  try {
    const project = yield call(getProject, projectId, params);
    yield put(requestProjectSuccess(project));
  } catch (error) {
    yield put(requestProjectError(error));
  }
}

/**
 * Requests zone for project
 */
export function* requestProjectZoneWorker({ params, projectId }) {
  try {
    const zone = yield call(getProjectZone, projectId, params);
    yield put(requestProjectZoneSuccess({ projectId, zone }));
  } catch (error) {
    yield put(requestProjectZoneError(error));
  }
}

/**
 * Checks if project exists, if not, requests it
 */
function* requestProjectIfNeedWorker({ params, projectId }) {
  const project = yield select(selectProjectById, projectId);

  if (!project.id) {
    yield put(requestProject({ params, projectId }));
  }
}

/**
 * Sends request to create project
 */
export function* createProjectWorker({ project }) {
  try {
    yield put(trackProjectCreation(project));
    const response = yield call(createProject, project);
    yield put(createProjectSuccess(response));
  } catch (error) {
    // message error from backend need to be rebuild
    const err = {
      common: error.message?.nonFieldErrors,
    };
    yield put(createProjectError(err));
  }
}

/**
 * Request for delete project
 */
export function* deleteProjectWorker({ id }) {
  try {
    yield call(deleteProject, id);
    yield put(deleteProjectSuccess(id));
  } catch (error) {
    // snackbar usage would be great here
    yield put(deleteProjectError(error));
  }
}

/**
 * Request for rename project
 */
export function* renameProjectWorker({ id, projectName }) {
  try {
    yield call(renameProject, id, projectName);
    yield put(renameProjectSuccess(id, projectName));
  } catch (error) {
    // snackbar usage would be great here
    yield put(renameProjectError(error));
  }
}

/**
 * Request for compute statistics
 */
export function* computeProjectStatisticsWorker({ id }) {
  try {
    yield put(trackProjectComputation(id));
    const project = yield call(computeProjectStatistics, id);
    yield put(computeProjectStatisticsSuccess(project));
  } catch (error) {
    yield put(computeProjectStatisticsError(error));
  }
}

/**
 * Sends sequence of requests to update projects collaborators
 */
export function* updateProjectCollaboratorsRequestWorker({ data }) {
  try {
    yield put(trackProjectCollaboratorChange(data));
    const projects = yield all(
      Object.entries(data).map(([id, collaborators]) =>
        call(updateProjectCollaborators, {
          id,
          collaborators,
        })
      )
    );
    yield put(updateProjectCollaboratorsRequestSuccess({ projects }));
  } catch (error) {
    yield put(updateProjectCollaboratorsRequestError(error));
  }
}

/**
 * Requests project statistics
 * @param projectId
 */
export function* requestProjectStatsWorker({ projectId }) {
  try {
    const { statistics } = yield call(getProjectStats, projectId);
    const stats = prepareProjectStatistics(statistics);
    const overviewStats = getProjectOverviewStatistics(statistics);

    yield put(
      requestProjectStatsSuccess({ stats, overviewStats, id: projectId })
    );
  } catch (error) {
    yield put(requestProjectStatsError(error));
  }
}

/**
 * Request for cancel compute statistics
 */
export function* cancelComputeProjectStatisticsWorker({ id }) {
  try {
    const project = yield call(cancelComputeProjectStatistics, id);
    yield put(cancelComputeProjectStatisticsSuccess(project));
  } catch (error) {
    yield put(cancelComputeProjectStatisticsError(error));
  }
}

/**
 * Requests visualization data for project
 * @param projectId
 */
export function* requestProjectVisualizationDataWorker({ projectId }) {
  try {
    const overviewStatistics = yield select(
      selectProjectOverviewStatsCollectionById,
      projectId
    );
    const visualizationData = yield Promise.all(
      overviewStatistics
        .filter(({ visualization: { web } }) => web)
        .map(({ visualization: { web } }) =>
          getProjectVisualizationData(web.dataUri0)
        )
    );

    yield put(
      requestProjectVisualizationDataSuccess({ projectId, visualizationData })
    );
  } catch (error) {
    yield put(requestProjectVisualizationDataError(error));
  }
}

/**
 * Checks if projects visualization exists, if not, requests it
 */
export function* requestProjectVisualizationDataIfNeedWorker({ point }) {
  const visualizationData = yield select(
    selectProjectVisualizationDataById,
    point.project,
    point.id
  );

  if (!visualizationData.length) {
    yield put(requestProjectVisualizationData({ projectId: point.project }));
  }
}

/**
 * Background task for update project with timeout
 */
function* updateProjectTask({ projectId }) {
  while (true) {
    const project = yield call(getProject, projectId);
    yield put(requestProjectSuccess(project));
    yield delay(UPDATE_PROJECT_TIMEOUT);
  }
}

/**
 * Start / stop background task for update project
 */
export function* startUpdateProjectJobWorker({ projectId }) {
  yield race({
    project: call(updateProjectTask, { projectId }),
    cancel: take(STOP_UPDATE_PROJECT_JOB),
  });

  yield put(finishUpdateProjectJob(projectId));
}

/**
 * makes request to get graph file from server and downloads graph file
 */
export function* downloadGraphFileWorker({
  projectId,
  currentLevel,
  monthNumber,
  pdSelectRange,
  statsIds,
  fileFormat,
  vclassType,
  id,
}) {
  try {
    const params = prepareParamsForGraphDownloading({
      currentLevel,
      monthNumber,
      pdSelectRange,
      statsIds,
      fileFormat,
      vclassType,
    });
    const response = yield call(downloadGraphFile, { projectId, params });
    yield call(triggerDownloadBlob, {
      blob: response,
      fileType: OCTET_STREAM_FILE_TYPE,
      fileName: params.file_name,
      isDownload: true,
      isOpenInNewTab: false,
    });
    yield put(downloadGraphFileSuccess(id));
  } catch (error) {
    yield put(downloadGraphFileError(id, error));
  }
}

/**
 * Sends request to create trial project
 */
export function* createTrialProjectWorker({ project }) {
  try {
    const response = yield call(tryCreateTrialProjectWorker, project);
    yield put(createTrialProjectSuccess(response));
  } catch (error) {
    yield put(createTrialProjectError(error));
  }
}

/**
 * Make request to create project, if we receive error, try again with other name
 * @param {Object} project
 * @param {Number} postfix
 */
export function* tryCreateTrialProjectWorker(project, postfix = 0) {
  try {
    return yield call(createProject, {
      ...project,
      name: postfix ? `${project.name}-${postfix}` : project.name,
    });
  } catch (error) {
    return yield call(tryCreateTrialProjectWorker, project, postfix + 1);
  }
}

export function* createDemoProjectWorker({ project }) {
  try {
    const response = yield call(tryCreateDemoProjectWorker, project);
    yield put(createDemoProjectSuccess(response));
  } catch (error) {
    yield put(createDemoProjectError(error));
  }
}

/**
 * Make request to create project, if we receive error, try again with other name
 * @param {Object} project
 * @param {Number} postfix
 */
export function* tryCreateDemoProjectWorker(project, postfix = 0) {
  try {
    return yield call(createDemoProject, {
      ...project,
      name: postfix ? `${project.name}-${postfix}` : project.name,
    });
  } catch (error) {
    return yield call(tryCreateDemoProjectWorker, project, postfix + 1);
  }
}

export default function* mainSaga() {
  yield takeLatest(REQUEST_PROJECTS, requestProjectsWorker);
  yield takeLatest(REQUEST_PROJECT, requestProjectWorker);
  yield takeLatest(CREATE_PROJECT, createProjectWorker);
  yield takeLatest(CREATE_TRIAL_PROJECT, createTrialProjectWorker);
  yield takeLatest(CREATE_DEMO_PROJECT, createDemoProjectWorker);
  yield takeLatest(DELETE_PROJECT, deleteProjectWorker);
  yield takeLatest(RENAME_PROJECT, renameProjectWorker);
  yield takeLatest(REQUEST_PROJECT_IF_NEED, requestProjectIfNeedWorker);
  yield takeLatest(COMPUTE_PROJECT_STATISTICS, computeProjectStatisticsWorker);
  yield takeLatest(REQUEST_PROJECTS_IF_NEED, requestProjectsIfNeedWorker);
  yield takeLatest(
    UPDATE_PROJECT_COLLABORATORS_REQUEST,
    updateProjectCollaboratorsRequestWorker
  );
  yield takeLatest(REQUEST_PROJECT_STATS, requestProjectStatsWorker);
  yield takeLatest(
    CANCEL_COMPUTE_PROJECT_STATISTICS,
    cancelComputeProjectStatisticsWorker
  );
  yield takeLatest(
    REQUEST_PROJECT_VISUALIZATION_DATA,
    requestProjectVisualizationDataWorker
  );
  yield takeLatest(
    REQUEST_PROJECT_VISUALIZATION_DATA_IF_NEED,
    requestProjectVisualizationDataIfNeedWorker
  );
  yield takeLatest(REQUEST_PROJECT_ZONE, requestProjectZoneWorker);
  yield takeLatest(START_UPDATE_PROJECT_JOB, startUpdateProjectJobWorker);
  yield takeEvery(DOWNLOAD_GRAPH_FILE, downloadGraphFileWorker);
}
