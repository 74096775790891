import React, { useCallback, memo } from 'react';
import { Grid, Typography } from '@material-ui/core';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { trackStatsHelp } from 'ducks/trackers/actions/workzone';

import { useStyles } from './styles';

/**
 * PropertiesListItem component
 * @param {Object} item
 * @param {Boolean} selected
 * @param {Function} onItemClick
 * @param {String} id
 */
const PropertiesListItem = ({ item, selected, onItemClick, id }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleClick = useCallback(() => onItemClick && onItemClick(item), [
    onItemClick,
    item,
  ]);

  const handleLinkClick = useCallback((e) => {
    e.stopPropagation();
    dispatch(trackStatsHelp('Statistic type list', item.linkTo));
  }, []);

  return (
    <Grid
      id={id}
      onClick={handleClick}
      item
      container
      alignItems="center"
      justifyContent="space-between"
      className={classNames(classes.pointItem, {
        [classes.pointItemSelected]: selected,
      })}
    >
      <Typography className={classes.itemName}>{item.name}</Typography>
      {item.icon && (
        <Link
          to={item.linkTo}
          target="_blank"
          className={classes.link}
          onClick={handleLinkClick}
        >
          {item.icon}
        </Link>
      )}
    </Grid>
  );
};

PropertiesListItem.propTypes = {
  item: PropTypes.object.isRequired,
  selected: PropTypes.bool,
  onItemClick: PropTypes.func,
  id: PropTypes.string,
};

export default memo(PropertiesListItem);
