import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(({ palette }) => ({
  row: {
    marginBottom: 5,
    '& input': {
      padding: '10px 0',
      color: palette.primary.main,
    },
  },
  fieldName: {
    textTransform: 'uppercase',
    fontSize: 14,
    color: palette.primary.main,
  },
  fullWidth: {
    width: '100%',
  },
  modalButtonRow: {
    display: 'flex',
    justifyContent: 'center',
  },
  modalButton: {
    margin: '0 5px',
    minWidth: 100,
  },
  helperText: {
    height: 20,
    wordWrap: 'break-word',
  },
  removeButton: {
    color: palette.white.main,
    backgroundColor: palette.danger.middle,
    margin: '0 5px',
    minWidth: 100,

    '&:hover': {
      backgroundColor: palette.danger.main,
      borderColor: palette.danger.main,
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: palette.danger.main,
      borderColor: palette.danger.main,
    },
    '&:focus': {
      boxShadow: 'none',
    },
  },
}));
