import React, { useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { ROUTES } from 'constants/routes';
import ProjectDashboard from 'components/projects/ProjectDashboard';
import MCProjectContainer from 'containers/projects/ProjectMarineContractors/index';
import { Loader } from 'components/common/Loader';
import {
  selectIsRunningProjectById,
  selectProjectByIdWithPoints,
  selectProjectsIsError,
  selectProjectsShouldShowLoader,
} from 'ducks/projects/selectors';
import { requestProjectIfNeed } from 'ducks/projects/actions';
import { requestPoints } from 'ducks/points/actions';
import {
  selectCurrentUserIsManager,
  selectUserShowOverviewControl,
} from 'ducks/user/selectors';
import { requestGetMaps } from 'ducks/stat2d/actions';
import {
  selectAllowBuyMaps,
  selectAreMapsInOffer,
  selectMapsStatusIsLoading,
  selectTilesFromStats2dWithPropertiesByZoneId,
} from 'ducks/stat2d/selectors';
import { requestGeometries } from 'ducks/geometries/actions';
import { selectGeometriesByProjectId } from 'ducks/geometries/selectors';
import { selectSortedSuccessReports } from 'ducks/reports/selectors';
import { checkIsOfferExpired } from 'helpers/projects';
import {
  requestMarineContractorsJob,
  resetMarineContractors,
} from 'ducks/marineContractors/actions';
import {
  selectMarineContractorsJob,
  selectMarineContractorsJobIsLoading,
  selectMarineContractorsJobIsNotRequested,
} from 'ducks/marineContractors/selectors';

/**
 * Container for project page. Page have analyze, reports and query.
 * @return {jsx}
 */
const Project = () => {
  const { id: projectId } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const { project, geometries, isRunningProject } = useSelector((state) => ({
    project: selectProjectByIdWithPoints(state, projectId),
    isRunningProject: selectIsRunningProjectById(state, projectId),
    geometries: selectGeometriesByProjectId(state, projectId),
  }));
  const zoneId = project?.zone?.id;
  const zoneTileGeometries = useSelector((state) =>
    selectTilesFromStats2dWithPropertiesByZoneId(state, zoneId)
  );
  const allowBuyMaps = useSelector((state) =>
    selectAllowBuyMaps(state, zoneId)
  );
  const mapsInOffer = useSelector((state) =>
    selectAreMapsInOffer(state, projectId)
  );
  const isShowLoader = useSelector(selectProjectsShouldShowLoader);
  const isError = useSelector(selectProjectsIsError);
  const isLoadingMapsData = useSelector(selectMapsStatusIsLoading);
  const isOverviewShown = useSelector(selectUserShowOverviewControl);
  const reports = useSelector(selectSortedSuccessReports);
  const isManager = useSelector(selectCurrentUserIsManager);
  const isOfferExpired = useMemo(() => checkIsOfferExpired(project), [project]);

  const job = useSelector(selectMarineContractorsJob);
  const jobIsLoading = useSelector(selectMarineContractorsJobIsLoading);
  const jobIsNotRequested = useSelector(
    selectMarineContractorsJobIsNotRequested
  );

  const showLoader = project?.isMcDashboard
    ? isShowLoader || jobIsLoading || jobIsNotRequested
    : isShowLoader;

  useEffect(() => {
    dispatch(requestProjectIfNeed({ projectId }));
    dispatch(requestPoints({ project: projectId }));
    dispatch(requestGeometries({ project: projectId }));
  }, [dispatch, projectId]);

  useEffect(() => {
    if (zoneId) {
      dispatch(requestGetMaps({ zone: zoneId, project: projectId }));
    }
  }, [dispatch, zoneId, projectId]);

  useEffect(() => {
    if (isError) {
      history.replace(ROUTES.home);
    }
  }, [isError, history]);

  useEffect(() => {
    if (project?.isMcDashboard && jobIsNotRequested) {
      dispatch(requestMarineContractorsJob(project.id));
    }
  }, [dispatch, project, jobIsNotRequested]);

  // each marine contractors step load its parts
  // then when exiting marine contractors, data is cleaned
  useEffect(
    () => () => {
      dispatch(resetMarineContractors());
    },
    [dispatch]
  );

  const projectDashboard = project?.isMcDashboard ? (
    <MCProjectContainer project={project} job={job} />
  ) : (
    <ProjectDashboard
      geometries={geometries}
      reports={reports}
      zoneTileGeometries={zoneTileGeometries}
      allowTileGeometries={!allowBuyMaps}
      mapsInOffer={mapsInOffer}
      isLoadingMapsData={isLoadingMapsData}
      project={project}
      isRunningProject={isRunningProject}
      isOverviewShown={isOverviewShown}
      isManager={isManager}
      isOfferExpired={isOfferExpired}
    />
  );

  return !showLoader ? projectDashboard : <Loader center />;
};

export default React.memo(Project);
