import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
  },
  relative: {
    marginLeft: 5,
  },
  center: {
    position: 'fixed',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  valueContainer: {
    top: 5,
    width: 30,
    marginLeft: -15,
    position: 'absolute',
    left: '50%',
    display: 'block',
    color: theme.palette.statuses.running,
    textAlign: 'center',
    fontSize: 12,
  },
  barColor: {
    color: theme.palette.statuses.running,
  },
  bgCircle: {
    position: 'absolute',
    color: theme.palette.statuses.running,
    opacity: 0.3,
  },
}));
