import React, { memo } from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Box from '@material-ui/core/Box';

import { useStyles } from './styles';

/**
 * Tabs component
 * Contains project navigation links
 * @param {Array} links
 * @returns {jsx}
 */
const Tabs = ({ links }) => {
  const classes = useStyles();

  return (
    <nav className={classes.container}>
      {links.map(({ title, link, disabled }, index) => {
        const linkClassName = classNames(classes.link, title.toLowerCase(), {
          disabled,
        });
        return disabled ? (
          <Box key={link} className={linkClassName}>
            {index + 1}.&nbsp;{title}
          </Box>
        ) : (
          <NavLink key={link} exact className={linkClassName} to={link}>
            {index + 1}.&nbsp;{title}
          </NavLink>
        );
      })}
    </nav>
  );
};

Tabs.propTypes = {
  links: PropTypes.array.isRequired,
};

export default memo(Tabs);
