import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(({ palette }) => ({
  title: {
    marginTop: 20,
    paddingBottom: 30,
    borderBottom: `1px solid ${palette.lightGrey.main}`,
  },
  instructions: {
    margin: '20px 0 20px 0',
  },
  button: {
    margin: '20px 0 20px 0',
  },
  buttonIcon: {
    marginLeft: 5,
  },
  selectedText: {
    margin: '0 5px 0 5px',
  },
  link: {
    marginRight: 5,
  },
  loader: {
    margin: '20px 0',
  },
  iconButtons: {
    '& button, a': {
      color: palette.grey.middle,
    },
  },
  pointsList: {
    margin: '16px 10px 16px 0',
    paddingLeft: 24,
  },
  errorText: {
    margin: '20px 0 10px 0',
    color: 'red',
  },
  table: {
    '&tr,th': {
      padding: '12px 0',
    },
  },
  errorLogButton: {
    marginLeft: 8,
    color: palette.danger.middle,
    border: `1px solid ${palette.danger.middle}`,
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: palette.lightDanger.main,
      borderColor: palette.danger.main,
    },
  },
}));
