import React, { memo } from 'react';
import Button from '@material-ui/core/Button';
import ShareIcon from '@material-ui/icons/Share';
import { useTranslation } from 'react-i18next';

import { palette } from 'common/theme';

import { useStyles } from './styles';

/**
 * Button wti person icon for shared project
 * @param {Object} props
 * @param {String} className
 * @returns {jsx}
 */
const ShareProjectButton = ({ className, ...props }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Button
      variant="contained"
      aria-label={t('alts.inviteButton')}
      className={classes.floatingButton}
      classes={{ label: classes.label }}
      startIcon={<ShareIcon htmlColor={palette.sinay.primary} />}
      {...props}
    >
      {t('projects.share')}
    </Button>
  );
};

export default memo(ShareProjectButton);
