import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(({ palette }) => ({
  container: {
    width: 600,
    padding: '10px 20px 20px 20px',
  },
  greenTitle: {
    marginBottom: 10,
    color: palette.secondary.main,
  },
  closeButton: {
    backgroundColor: palette.danger.middle,
    '&:hover': {
      backgroundColor: palette.danger.main,
    },
    marginRight: 10,
    color: 'white',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    borderTop: `1px solid ${palette.lightGrey.main}`,
    paddingTop: 15,
  },
}));
