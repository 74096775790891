import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';

import BaseModalFullscreen from 'components/common/BaseModalFullscreen';
import OffersList from 'components/offers/OffersList';
import LoaderOverlay from 'components/common/LoaderOverlay';
import EmptyContentFallback from 'components/common/EmptyContentFallback';

import { useStyles } from './styles';

/**
 * ChooseOfferType - full screen modal with
 * @param {Array} offers
 * @param {Boolean} loading
 * @param {Function} onClose
 * @param {Function} onSelect
 * @returns {jsx}
 */
const ChooseOfferType = ({ offers, onClose, onSelect, loading }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <BaseModalFullscreen
      onClose={onClose}
      title={t('offers.chooseProjectType')}
    >
      {loading && <LoaderOverlay center />}
      {offers.length ? (
        <OffersList offers={offers} onSelect={onSelect} />
      ) : (
        <div className={classes.emptyContentContainer}>
          <EmptyContentFallback
            iconComponent={<ErrorOutlineOutlinedIcon />}
            title={t('emptyState.offer.title')}
            subtitle={t('emptyState.offer.subtitle')}
          />
        </div>
      )}
    </BaseModalFullscreen>
  );
};

ChooseOfferType.propTypes = {
  loading: PropTypes.bool,
  offers: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default React.memo(ChooseOfferType);
