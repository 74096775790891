import React from 'react';
import PropTypes from 'prop-types';
import { useModal } from 'hooks/useModal';
import ContactUsModal from 'components/modals/ContactUsModal';

/**
 * ContactUsModal container
 * @param {String} id
 */
const ContactUsModalContainer = ({ id }) => {
	const { onClose } = useModal(id);

	return (
		<ContactUsModal
			onClose={onClose}
		/>
	)
};

ContactUsModalContainer.propTypes = {
	id: PropTypes.string.isRequired,
}

export default React.memo(ContactUsModalContainer);
