import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(({ palette }) => ({
  title: {
    marginRight: '20px',
  },
  buttonComponent: {
    textAlign: 'right',
  },
  button: {
    margin: '10px',
    backgroundColor: palette.danger.middle,
    '&:hover': {
      backgroundColor: palette.danger.main,
    },
    color: 'white',
  },
  statisticsContainer: {
    width: '95%',
    backgroundColor: palette.background.paper,
  },
  tabs: {
    borderRight: `1px solid ${palette.divider}`,
  },
  pointTab: {
    minWidth: 0,
  },
  operationTitle: {
    margin: '20px',
  },
  graphTitle: {
    fontWeight: 'bold',
  },
  appBar: {
    flexDirection: 'row',
  },
  operationsDashboard: {
    overflowY: 'auto',
    height: '600px',
  },
}));
