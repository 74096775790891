import { takeLatest, put, call } from 'redux-saga/effects';

import {
  getAllComments,
  postComment as postCommentService,
} from 'services/comments';

import { REQUEST_COMMENTS, POST_COMMENT } from './types';
import {
  requestCommentsSuccess,
  requestCommentsError,
  postCommentSuccess,
  postCommentError,
} from './actions';

function* requestCommentsWorker() {
  try {
    const comments = yield call(getAllComments);
    yield put(requestCommentsSuccess(comments.reverse()));
  } catch (error) {
    yield put(requestCommentsError(error));
  }
}

function* postCommentWorker({
  payload: { message, reportId, userId, reportTypeId },
}) {
  try {
    const comment = yield call(postCommentService, {
      message,
      objectId: reportId,
      user: userId,
      contentType: reportTypeId,
    });
    yield put(postCommentSuccess(comment));
  } catch (error) {
    yield put(postCommentError(error));
  }
}

export default function* commentsSagaWatcher() {
  yield takeLatest(REQUEST_COMMENTS, requestCommentsWorker);
  yield takeLatest(POST_COMMENT, postCommentWorker);
}
