import { takeLatest, put, call, select } from 'redux-saga/effects';
import Keycloak from 'keycloak-js';

import { KEYCLOAK_CONF_URL } from 'common/config';
import {
  AUTH_TOKEN_KEY,
  REFRESH_TOKEN_KEY,
  PREVIOUS_TOKEN_KEY,
  PREVIOUS_REFRESH_TOKEN_KEY,
} from 'constants/common';

import {
  INIT_KEYCLOAK,
  LOGOUT_KEYCLOAK,
  IMPERSONATE_USER,
  STOP_IMPERSONATE,
  SET_KEYCLOAK_TOKENS,
} from './types';
import {
  authKeycloakSuccess,
  authKeycloakFailed,
  setKeycloakInstance,
} from './actions';
import { selectKeycloak } from './selectors';

/**
 * Try to authentificate with tokens
 * stored in the local store.
 *
 * # args
 * * keycloak: `Keycloak` instance
 * * accessToken: `str`
 * * refreshToken: `str`
 */
const authWithTokens = ({ keycloak, accessToken, refreshToken }) =>
  keycloak
    .init({
      token: accessToken,
      refreshToken,
      checkLoginIframe: false,
    })
    .catch(() => false);
// see: https://keycloak.discourse.group/t/automatic-login-to-javascript-client-adapter-with-inital-access-and-refresh-token/1331

/**
 * Set a timer that will check every `CHECK_PERIOD_IN_SECS`
 * that the current token is still valid for at least `MIN_LEFT_DURATION_IN_SECS`.
 * If not, it will try to refresh the token, and schedule the next check.
 */

const setupAutoRefresh = (keycloak) => {
  const MIN_LEFT_DURATION_IN_SECS = 60 * 2;
  const CHECK_PERIOD_IN_SECS = 10;

  const onRefreshSuccess = (refreshed) => {
    if (refreshed) {
      persistTokens({
        accessToken: keycloak.token,
        refreshToken: keycloak.refreshToken,
      });
    }
    setupAutoRefresh(keycloak);
  };
  const onRefreshError = (error) => console.error('failed to update', error);

  const scheduleUpdate = () => {
    keycloak
      .updateToken(MIN_LEFT_DURATION_IN_SECS)
      .then(onRefreshSuccess)
      .catch(onRefreshError);
  };

  setTimeout(scheduleUpdate, CHECK_PERIOD_IN_SECS);
};

/**
 * Try to authentificate with SSO
 * (eg, if the user is already logged on
 * another service.
 */
const authWithSSO = (keycloak) => {
  const kcinit = keycloak.init({
    onLoad: 'login-required',
    checkLoginIframe: true,
  });
  return kcinit;
};

/**
 * Instanciate keyclaok and try to authentificate
 */
function* initKeycloak() {
  const keycloak = new Keycloak(KEYCLOAK_CONF_URL);

  const urlToIdp = {
    '/parkwind': 'parkwind',
  };

  const route = window.location.pathname;

  if (Object.hasOwn(urlToIdp, route)) {
    // urlToIdp.hasOwnProperty(route)) {
    const kcLogin = keycloak.login;
    keycloak.login = (options) => {
      options.idpHint = urlToIdp[route];
      kcLogin(options);
    };
  }

  // put instance in store
  yield put(setKeycloakInstance(keycloak));
  let isAuth = yield call(authWithSSO, keycloak);

  if (!isAuth) {
    const { accessToken, refreshToken } = loadStoredTokens();
    if (accessToken && refreshToken) {
      isAuth = yield call(authWithTokens, {
        keycloak,
        accessToken,
        refreshToken,
      });
    }
  }

  if (isAuth) {
    persistTokens({
      accessToken: keycloak.token,
      refreshToken: keycloak.refreshToken,
    });
    yield call(setupAutoRefresh, keycloak);
    yield put(authKeycloakSuccess());
  } else {
    dropStoredTokens();
    yield put(authKeycloakFailed());
  }
}

/**
 * drop current tokens, and replaced them by the stashed ones.
 * Then drop the stashed ones.
 */
const popStashedTokens = () => {
  dropStoredTokens();
  const accessToken = localStorage.getItem(PREVIOUS_TOKEN_KEY);
  const refreshToken = localStorage.getItem(PREVIOUS_REFRESH_TOKEN_KEY);
  if (accessToken && refreshToken) {
    persistTokens({ accessToken, refreshToken });
    dropStashedTokens();
  }
};

const stashTokens = () => {
  const { accessToken, refreshToken } = loadStoredTokens();
  if (accessToken && refreshToken) {
    localStorage.setItem(PREVIOUS_TOKEN_KEY, accessToken);
    localStorage.setItem(PREVIOUS_REFRESH_TOKEN_KEY, refreshToken);
    dropStoredTokens();
  }
};

const persistTokens = ({ accessToken, refreshToken }) => {
  if (accessToken && refreshToken) {
    localStorage.setItem(AUTH_TOKEN_KEY, accessToken);
    localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);
  }
};

const dropStoredTokens = () => {
  localStorage.removeItem(AUTH_TOKEN_KEY);
  localStorage.removeItem(REFRESH_TOKEN_KEY);
};

const dropStashedTokens = () => {
  localStorage.removeItem(PREVIOUS_TOKEN_KEY);
  localStorage.removeItem(PREVIOUS_REFRESH_TOKEN_KEY);
};

const loadStoredTokens = () => ({
  accessToken: localStorage.getItem(AUTH_TOKEN_KEY),
  refreshToken: localStorage.getItem(REFRESH_TOKEN_KEY),
});

/**
 * Store access token and refresh tokens
 */
function* setKeycloakTokens({ accessToken, refreshToken }) {
  const keycloak = yield select(selectKeycloak);
  const isAuth = yield call(authWithTokens, {
    keycloak,
    accessToken,
    refreshToken,
  });
  if (isAuth) {
    persistTokens({
      accessToken: keycloak.token,
      refreshToken: keycloak.refreshToken,
    });
    yield call(setupAutoRefresh, keycloak);
    yield put(authKeycloakSuccess());
  } else {
    dropStoredTokens();
    yield put(authKeycloakFailed());
  }
  return isAuth;
}

/**
 * Clear token from keycloak instance _and_ from
 * local storage.
 * Then use keycloak API to logout (from all sinay's services).
 */
function* logoutKeycloak() {
  dropStoredTokens();
  const keycloak = yield select(selectKeycloak);
  keycloak.clearToken();
  keycloak.logout();
}

function* impersonateUser({ accessToken, refreshToken }) {
  stashTokens();
  // this is mandatory to avoid keycloak-js to reload the hijack mode all over again
  window.history.pushState({}, document.title, '/');
  const keycloak = new Keycloak(KEYCLOAK_CONF_URL);
  // put instance in store
  yield put(setKeycloakInstance(keycloak));
  const isAuth = yield call(() =>
    keycloak
      .init({
        token: accessToken,
        refreshToken,
        checkLoginIframe: false,
        onLoad: 'check-sso',
        redirectUri: '/plans',
      })
      .catch(() => false)
  );

  if (isAuth) {
    persistTokens({
      accessToken: keycloak.token,
      refreshToken: keycloak.refreshToken,
    });
    yield call(setupAutoRefresh, keycloak);
    yield put(authKeycloakSuccess());
  } else {
    yield put(authKeycloakFailed());
  }
}

function* stopImpersonation() {
  popStashedTokens();
  const keycloak = yield select(selectKeycloak);
  keycloak.clearToken();
  // keycloak.logout();
  const { accessToken, refreshToken } = loadStoredTokens();
  if (accessToken && refreshToken) {
    yield call(authWithTokens, {
      keycloak,
      accessToken,
      refreshToken,
    });
  }
}

export default function*() {
  yield takeLatest(INIT_KEYCLOAK, initKeycloak);
  yield takeLatest(SET_KEYCLOAK_TOKENS, setKeycloakTokens);
  yield takeLatest(LOGOUT_KEYCLOAK, logoutKeycloak);
  yield takeLatest(IMPERSONATE_USER, impersonateUser);
  yield takeLatest(STOP_IMPERSONATE, stopImpersonation);
}
