import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(({ palette }) => ({
  advancedBlock: {
    marginTop: 15,
  },
  advancedBlockWrapper: {
    padding: 15,
  },
  checkboxRow: {
    textAlign: 'center',
    '& .MuiFormControlLabel-label': {
      fontSize: 14,
      fontWeight: 400,
    },
  },
  checkboxSubtext: {
    marginTop: 5,
    fontSize: 13,
    fontStyle: 'italic',
  },
  fieldRow: {
    marginTop: 25,
    textAlign: 'center',
  },
  fieldName: {
    marginBottom: 5,
    fontSize: 14,
    fontWeight: 700,
  },
  submitButton: {
    marginTop: 10,
  },
  cancelReportGenerationButton: {
    marginTop: 10,

    color: palette.white.main,
    backgroundColor: palette.danger.middle,

    '&:hover': {
      boxShadow: 'none',
      backgroundColor: palette.danger.main,
      borderColor: palette.danger.main,
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: palette.danger.main,
      borderColor: palette.danger.main,
    },
    '&:focus': {
      boxShadow: 'none',
    },
  },
  loaderContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
}));
