import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';

import BaseModal from 'components/common/BaseModal';

import SectionsCoordinatesTitles from '../PlanningSettingsModal/SectionsCoordinatesTitles';

import { useStyles } from './styles';

/**
 * UploadPlanningSuccessModal component
 * Displays upload planning success modal
 * @param { function } onClose - close handler
 * @param { string } userEmail - current user email
 */
const UploadPlanningSuccessModal = ({ onClose, userEmail }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <BaseModal
      title={t('downtime.planningSettings.modalTitle')}
      onClose={onClose}
      modalClass={classes.container}
    >
      <Typography align="center" className={classes.greenTitle}>
        {t('plannings.computationSuccessfullyLaunched')}
      </Typography>
      <SectionsCoordinatesTitles userEmail={userEmail} />
      <div className={classes.buttonContainer}>
        <Button
          variant="contained"
          onClick={onClose}
          className={classes.closeButton}
        >
          {t('buttons.close')}
        </Button>
      </div>
    </BaseModal>
  );
};

UploadPlanningSuccessModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  userEmail: PropTypes.string.isRequired,
};

export default memo(UploadPlanningSuccessModal);
