import { IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import CreateIcon from '@material-ui/icons/Create';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import React, { useCallback, memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { isOfferType, isLightType } from 'helpers/projects';

import BaseProjectCard from '../BaseProjectCard';

import { useStyles } from './styles';

/**
 * Local component for usage in ActiveProject component
 * Displays card header for active project
 */
const ActiveProjectHeader = ({
  id,
  name,
  zoneName,
  canDeleteProject,
  canRenameProject,
  onDeleteClick,
  onRenameClick,
  classes,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isOpen, setIsOpen] = React.useState(true);
  const isMenuOpen = !!anchorEl;

  const handleProjectMenuOpen = useCallback(
    (event) => {
      setIsOpen(!isOpen);
      event.stopPropagation();
      if (isOpen) {
        setAnchorEl(event.currentTarget);
      } else {
        setAnchorEl(null);
      }
    },
    [isOpen]
  );

  const handleMenuClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  return (
    <div className={classes.header}>
      <div className={classes.container}>
        <span className={classes.title}>{name}</span>
        <span className={classes.subtitle}>{zoneName}</span>
      </div>
      {canDeleteProject && canRenameProject && (
        <IconButton
          classes={{ root: classes.icon }}
          data-project-id={id}
          onClick={handleProjectMenuOpen}
        >
          <MoreHorizIcon />
          <Menu
            anchorEl={anchorEl}
            getContentAnchorEl={null}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={isMenuOpen}
            onClose={handleMenuClose}
          >
            <MenuItem
              onClick={onRenameClick}
              className={classes.smallText}
              data-project-id={id}
            >
              <CreateIcon />
              &nbsp;Rename
            </MenuItem>
            <MenuItem
              onClick={onDeleteClick}
              className={classes.smallText}
              data-project-id={id}
            >
              <DeleteIcon />
              &nbsp;Delete
            </MenuItem>
          </Menu>
        </IconButton>
      )}
    </div>
  );
};

/**
 * ActiveProject component
 * Displays active project card
 * @param { Number } id - project id
 * @param { String } name - project name
 * @param { Number } type - project type (see constants)
 * @param { Object } zone - project zone object
 * @param { Array } points - project points
 * @param { Number } credits - project credits amount
 * @param { Number } reports - project reports amount
 * @param { Boolean } canDeleteProject - displays delete button
 * @param { function } onClick - callback for click on card
 * @param { function } deleteProject - callback to deleteProject by id
 * @param { Node } map - project map
 */
const ActiveProject = ({
  id,
  name,
  type,
  zone,
  points,
  onClick,
  canDeleteProject,
  canRenameProject,
  onDeleteClick,
  onRenameClick,
  map: Map,
}) => {
  const classes = useStyles();
  const projectLabelClass = classNames(
    classes.colorLabel,
    (isOfferType(type) && classes.offerType) ||
      (isLightType(type) && classes.lightType) ||
      (!!zone.isDemo && classes.demoType)
  );

  return (
    <BaseProjectCard
      onClick={onClick}
      id={id}
      header={
        <ActiveProjectHeader
          id={id}
          name={name}
          canDeleteProject={canDeleteProject}
          canRenameProject={canRenameProject}
          onDeleteClick={onDeleteClick}
          onRenameClick={onRenameClick}
          zoneName={zone.name}
          classes={classes}
        />
      }
    >
      <div>
        <div className={projectLabelClass} />
        <Map geometry={zone.geom} points={points} className={classes.cardMap} />
      </div>
    </BaseProjectCard>
  );
};

ActiveProject.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  points: PropTypes.array.isRequired,
  type: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  onDeleteClick: PropTypes.func.isRequired,
  onRenameClick: PropTypes.func.isRequired,
  canDeleteProject: PropTypes.bool.isRequired,
  canRenameProject: PropTypes.bool.isRequired,
  zone: PropTypes.shape({
    name: PropTypes.string,
    isDemo: PropTypes.bool,
  }).isRequired,
};

export default memo(ActiveProject);
