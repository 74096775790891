import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  mainContainer: {
    margin: '0 58px 0 58px',
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  title: {
    color: '#90bfc7',
    margin: '20px 0 10px 0',
  },
  dangerSubtitle: {
    color: theme.palette.danger.main,
  },
  successSubtitle: {
    color: '#3c763d',
  },
  boldText: {
    fontWeight: 'bold',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
  },
  textBlock: {
    margin: '0 15px 0 15px',
  },
  logo: {
    width: 140,
    height: '100%',
  },
}));
