import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    width: 500,
    backgroundColor: theme.palette.danger.main,
    color: theme.palette.white.main,
    '& .modal_close-button': {
      color: theme.palette.white.main,
    },
  },
  body: {
    wordBreak: 'break-word',
    padding: '10px 5px',
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'center',
    '& > *': {
      marginRight: 15,
    },
  },
}));
