import { takeLatest, put, call } from 'redux-saga/effects';

import { getBuoys, getBuoysStats } from 'services/buoys';

import { REQUEST_BUOYS, REQUEST_BUOY_STATS } from './types';
import {
  requestBuoysError,
  requestBuoysSuccess,
  requestBuoyStatsError,
  requestBuoyStatsSuccess,
} from './actions';

/**
 * Requests all buoys
 */
export function* requestBuoysWorker({ params }) {
  try {
    const buoys = yield call(getBuoys, params);
    yield put(requestBuoysSuccess(buoys));
  } catch (error) {
    yield put(requestBuoysError(error));
  }
}

/**
 * Requests all statistics buoys
 */
export function* requestBuoyStatsWorker({ buoyId, params }) {
  try {
    const buoy = yield call(getBuoysStats, buoyId, params);
    yield put(requestBuoyStatsSuccess({ buoy }));
  } catch (error) {
    yield put(requestBuoyStatsError(error));
  }
}

export default function* buoysSaga() {
  yield takeLatest(REQUEST_BUOYS, requestBuoysWorker);
  yield takeLatest(REQUEST_BUOY_STATS, requestBuoyStatsWorker);
}
