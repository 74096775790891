import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  container: {
    textAlign: 'center',
  },
  buttonsGroup: {
    margin: '5px 0',
  },
  cancelButton: {
    marginRight: 15,
  },
  noUploadsMessage: {
    marginLeft: 30,
    marginBottom: 15,
    fontSize: 14,
  },
  formText: {
    margin: '15px 0',
    fontSize: 12,
  },
});
