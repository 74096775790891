import React, { memo } from 'react';
import PropTypes from 'prop-types';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { useStyles } from './styles';

/**
 * Collapse component.
 * @param {Node} title
 * @param {React.ReactNode} children
 * @param {Boolean} isOpen
 * @param {Object} props
 * @returns {jsx}
 */
const Collapse = ({ title, children, isOpen, ...props }) => {
  const classes = useStyles();

  return (
    <Accordion
      square
      classes={{
        root: classes.collapseRoot,
      }}
      defaultExpanded={isOpen}
      {...props}
    >
      <AccordionSummary
        classes={{
          root: classes.collapseSummaryRoot,
          content: classes.collapseSummaryContent,
          expanded: classes.collapseSummaryExpanded,
        }}
        expandIcon={<ExpandMoreIcon classes={{ root: classes.collapseIcon }} />}
      >
        {title}
      </AccordionSummary>
      <AccordionDetails
        classes={{
          root: classes.collapsePanel,
        }}
      >
        {children}
      </AccordionDetails>
    </Accordion>
  );
};

Collapse.propTypes = {
  title: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
  isOpen: PropTypes.bool.isRequired,
  className: PropTypes.string,
};

export default memo(Collapse);
