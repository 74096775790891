import React, { memo, useCallback, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { selectProjectById } from 'ducks/projects/selectors';
import { Loader } from 'components/common/Loader';
import PropertiesList from 'components/points/PropertiesList';
import { STATS_ID_DELIMITER } from 'constants/projects';
import { containsOneOf } from 'helpers/string';
import { READY_GRAPH_TYPES } from 'constants/graphsConfig';
import { ASK_SPECIFIC_POINT_MODAL } from 'constants/modals';
import { useModal } from 'hooks/useModal';
import Spacer from 'components/common/Spacer';
import { trackAnalyzePointSelection } from 'ducks/trackers/actions/workzone';

import { useStyles } from './styles';

/**
 * Project analyze page component
 * displays project statistics
 */
const ProjectAnalyze = ({
  points,
  fields,
  statisticTypes,
  variables,
  shortVariables,
  changeField,
  changeType,
  changeVariables,
  parameters,
  onSelectMarker,
  selectedMarkerId,
  statsIsLoading,
  clearSelection,
  displayStatistics,
  variablesIdName,
  statisticsIdName,
  fieldsIdName,
  displayButtonIdName,
  canRun,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { openModal } = useModal();
  const { t } = useTranslation();
  const { selectedField, selectedVariable, selectedType } = parameters;
  const [displayFirstContent, setDisplayFirstContent] = useState(true);
  const lastSectionRef = useRef(null);

  const { id: projectId } = useParams();
  const project = useSelector((state) => selectProjectById(state, projectId));
  const isTrial = project.offerInstance.isTrial;

  const v = canRun ? shortVariables : variables;
  const selectedByNameVariable = v.find(
    (variable) => variable.name === selectedVariable.name
  );

  const replaceUnderscoreInt = (inputString, id) => {
    const pattern = /_(\d+)/g;
    const replacedString = inputString.replace(pattern, `_${id}`);
    return replacedString;
  };

  const statsIdsToRequest = selectedByNameVariable
    ? selectedByNameVariable.id.join(STATS_ID_DELIMITER)
    : '';

  const statsIdsToRequest2 = replaceUnderscoreInt(
    statsIdsToRequest,
    selectedMarkerId
  );

  const isGraphAvailable = containsOneOf(
    statsIdsToRequest2,
    READY_GRAPH_TYPES.map((type) => `-${type}_`)
  );

  const openContactUsModale = useCallback(() => {
    openModal(ASK_SPECIFIC_POINT_MODAL);
  }, [openModal]);

  const displayNextContent = useCallback(() => {
    setDisplayFirstContent(false);
  }, [setDisplayFirstContent])

  const displayPreviousContent = useCallback(() => {
    setDisplayFirstContent(true);
  }, [openModal])

  const handlePointSelection = (e) => {
    onSelectMarker(e);
    dispatch(trackAnalyzePointSelection('Point list'));
    setTimeout(() => {
      handleScrollToLast();
    }, 100);
  };

  const handleScrollToLast = () => {
    if (lastSectionRef.current) {
      lastSectionRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <>
      {isTrial && (
        <div className={classes.container}>
          <div className={classes.logo}>
            <svg width="73" height="73" viewBox="0 0 73 73" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M3.29574 22.3968C7.73091 12.1475 16.8586 4.10219 28.5981 1.51666C48.0088 -2.75848 67.2099 9.51146 71.4849 28.9222C72.0468 31.4734 72.3229 34.021 72.3345 36.5315C72.3636 42.8088 45.163 49.9948 38.8858 49.9948C38.8858 49.9948 0.397607 29.094 3.29574 22.3968Z" fill="#232E7A" />
              <path d="M72.3236 35.7495C72.3312 36.053 72.335 36.3576 72.335 36.6629C72.335 38.9853 72.115 41.2564 71.6948 43.4563C68.5181 60.0885 53.8965 68.3898 36.3388 68.3898C16.4587 68.3898 14.5342 31.3781 15.8098 31.6008C31.0865 34.2692 34.159 46.8543 43.9318 45.6618C52.9495 44.5614 54.1661 33.3783 63.6172 33.0069C66.807 32.8816 69.7802 34.0333 72.3236 35.7495Z" fill="#3342AE" />
              <path d="M6.07421 56.1687C3.8169 52.6675 2.13376 48.7121 1.18511 44.4053C-0.504783 36.7325 0.390013 29.0925 3.28871 22.3938C13.1066 10.0886 30.2582 5.27837 45.4634 11.7203C49.0805 13.2528 52.3169 15.3073 55.1218 17.7588C53.694 17.6399 52.2684 17.6075 50.852 17.659C25.3603 18.5858 25.722 56.7856 51.2273 57.1874C51.4198 57.1905 51.6126 57.1919 51.8058 57.1919C54.8755 57.1919 57.7277 55.4537 59.2881 54.3168C61.801 52.4859 55.951 64.2735 45.0857 66.6665C29.9778 69.9941 13.9922 68.4506 6.07421 56.1687Z" fill="#3493F8" />
              <path d="M42.0763 13.0368C44.74 13.0368 47.3214 13.3355 49.777 13.8953C51.699 15.0313 53.4839 16.3273 55.1218 17.7588C53.694 17.6397 52.2684 17.6074 50.8521 17.659C25.3605 18.5858 25.7222 56.7856 51.2275 57.1875C51.42 57.1906 51.6128 57.192 51.806 57.192C54.8757 57.192 57.7278 55.4539 59.2882 54.3169C60.7636 53.242 59.3563 56.8611 55.7439 60.5174C51.6548 62.3268 47.0062 63.3496 42.0761 63.3496C25.7168 63.3496 12.4551 52.0866 12.4551 38.1932C12.4551 24.2997 25.717 13.0368 42.0763 13.0368Z" fill="#76B6FA" />
              <path d="M49.4941 9.10337C48.8711 8.57307 49.5391 6.32758 50.9002 4.88505C52.5639 3.12175 55.8563 1.9301 57.9308 3.47894C59.3737 4.55641 60.0744 6.84564 59.3369 7.69726C58.5536 8.60148 56.8096 7.12154 53.7124 7.69726C51.1722 8.16948 50.0649 9.58909 49.4941 9.10337Z" fill="#1380E2" />
              <path d="M49.5537 7.04997C49.8329 6.31633 50.2989 5.52236 50.9002 4.88505C52.5639 3.12175 55.8563 1.9301 57.9308 3.47894C58.6074 3.9842 59.1209 4.75581 59.3993 5.52081C59.3798 5.5488 59.3589 5.57565 59.3369 5.60125C58.5536 6.50547 56.8096 5.02553 53.7124 5.60125C51.2577 6.05758 50.1411 7.39843 49.5537 7.04997Z" fill="#3493F8" />
              <path d="M55.5905 63.4727C55.5905 65.8359 53.8383 67.3561 51.7132 68.39C49.2074 69.609 46.3146 70.583 43.4734 71.2088C36.551 72.7335 30.2631 72.762 24.0611 70.5054C16.7275 67.837 10.3643 62.8232 6.07421 56.1688C10.7355 54.8215 15.0777 54.467 16.0566 54.387C33.7042 52.9463 39.5736 63.1906 50.3047 59.7339C50.3269 59.7268 50.349 59.7196 50.3709 59.7124C52.9439 58.8716 55.5905 60.7657 55.5905 63.4727Z" fill="#1380E2" />
              <path d="M71.6974 43.4569C69.0609 57.142 58.5561 68.6286 44.0812 71.8166C37.2451 73.3221 30.4351 72.7761 24.2936 70.5889C21.1576 69.4722 19.0798 66.4817 19.0798 63.1528C19.0798 57.9746 24.5837 59.0772 29.5881 60.408C38.1769 62.692 42.7268 62.1748 50.3048 59.7339C60.2066 56.5444 59.4179 46.4664 69.9463 43.8072C70.5303 43.6595 71.1146 43.5437 71.6974 43.4569Z" fill="#3493F8" />
              <path d="M58.9191 20.0129C59.4328 19.4095 61.6437 20.0994 63.1374 21.419C65.7132 23.6947 66.7844 28.3982 64.8827 30.0542C63.6823 31.0995 61.2302 30.9888 60.3252 29.8557C59.3706 28.6605 60.8805 27.2188 60.3252 24.2312C59.853 21.691 58.4332 20.5837 58.9191 20.0129ZM57.9928 34.4132C57.9928 35.6557 59.0001 36.6629 60.2425 36.6629C61.4851 36.6629 62.4922 35.6556 62.4922 34.4132C62.4922 33.1706 61.4849 32.1635 60.2425 32.1635C59.0001 32.1633 57.9928 33.1706 57.9928 34.4132Z" fill="#76B6FA" />
              <path d="M62.4922 34.4132C62.4922 35.5819 61.6011 36.5424 60.4613 36.6523C60.082 36.2177 59.8524 35.6493 59.8524 35.0273C59.8524 33.8992 60.6078 32.9474 61.6403 32.6503C62.1595 33.0625 62.4922 33.6989 62.4922 34.4132ZM63.1374 21.419C62.4741 20.8329 61.6691 20.3711 60.9344 20.0915C61.2854 20.8457 62.0755 22.0004 62.4165 23.8344C63.027 27.1185 61.3672 28.7034 62.4165 30.0172C62.6549 30.3156 62.9907 30.5495 63.3819 30.7169C63.9516 30.6193 64.4857 30.4 64.8827 30.0542C66.7844 28.3982 65.7132 23.6947 63.1374 21.419Z" fill="#A7D0FC" />
              <path d="M58.1483 23.9278C58.1483 28.199 54.6858 31.6613 50.4148 31.6613C47.909 31.6613 45.6816 30.4695 44.2683 28.622C43.7651 28.7698 43.2324 28.8491 42.6813 28.8491C41.3037 28.8491 40.0419 28.3538 39.0641 27.5317C38.0796 29.1638 36.2896 30.2552 34.2447 30.2552C34.2447 31.4173 33.6807 32.4477 32.8116 33.0876C33.5121 33.7303 33.9513 34.6531 33.9513 35.6785C33.9513 37.62 32.3774 39.1937 30.4361 39.1937C28.4946 39.1937 26.9209 37.6198 26.9209 35.6785C26.9209 34.5164 27.4848 33.486 28.354 32.8461C27.6535 32.2034 27.2145 31.2806 27.2145 30.2552C27.2145 28.8668 28.0194 27.6666 29.188 27.0952C28.8246 26.351 28.6206 25.5147 28.6206 24.6307C28.6206 21.5245 31.1388 19.0063 34.245 19.0063C35.6226 19.0063 36.8843 19.5016 37.8622 20.3237C38.8467 18.6916 40.6367 17.6002 42.6817 17.6002C43.5935 17.6002 44.4545 17.8173 45.2162 18.2024C46.5895 16.9546 48.4135 16.1941 50.4152 16.1941C54.6858 16.1942 58.1483 19.6567 58.1483 23.9278Z" fill="#A7D0FC" />
              <path d="M53.0822 18.8616C54.5338 18.8616 55.892 19.2615 57.0526 19.9572C57.7489 21.1189 58.149 22.4786 58.1482 23.9318C58.1459 28.0555 54.8421 31.4959 50.7215 31.6552C48.0912 31.7569 45.7389 30.5446 44.2682 28.6218C43.7649 28.7696 43.2322 28.8489 42.6811 28.8489C41.3036 28.8489 40.0417 28.3537 39.064 27.5316C38.0794 29.1636 36.2894 30.255 34.2446 30.255C34.2446 31.4172 33.6806 32.4475 32.8115 33.0875C33.561 33.7752 34.0113 34.7837 33.9445 35.8956C33.8407 37.6208 32.4577 39.0383 30.7353 39.1812C30.3673 39.2117 30.0103 39.1854 29.6713 39.1102C29.6168 38.8641 29.588 38.6083 29.588 38.3457C29.588 37.9391 29.657 37.5484 29.7841 37.185C30.1675 36.0887 30.2722 34.9111 29.9882 33.785C29.9186 33.509 29.8816 33.22 29.8816 32.9224C29.8816 32.2283 30.0828 31.5811 30.4302 31.0361C31.0588 30.0496 31.3946 28.9026 31.3046 27.7363C31.2829 27.4555 31.2819 27.1687 31.303 26.8779C31.505 24.0975 33.7704 21.8579 36.5527 21.6848C37.0835 21.6518 37.5998 21.6925 38.0922 21.7976C39.5606 22.1112 41.1019 21.9113 42.3758 21.1165C43.2384 20.5784 44.2572 20.2674 45.3488 20.2674C45.595 20.2674 45.8374 20.2833 46.0752 20.314C47.2426 20.4646 48.4382 20.2995 49.4792 19.7502C50.5551 19.1829 51.7811 18.8616 53.0822 18.8616Z" fill="#EBECF8" />
            </svg>
          </div>

          {displayFirstContent && (
            <div className={classes.content}>
              <p>You are in the <strong className={classes.bold}>demo mode</strong> of Metocean Analytics which gives access to statistics related to <strong className={classes.bold}>one selected point</strong> within the bay of Biscay.</p>

              <p><strong className={classes.bold}>To view an analysis of the location of your choice</strong>, please contact us below.</p>

              <p>To view additional guidance on viewing statistics at selected point, click on <strong className={classes.bold}>Continue</strong></p>
            </div>
          )}

          {!displayFirstContent && (
            <div className={classes.content}>
              <p>To <strong className={classes.bold}>visualize statistics</strong> at the predefined location, click on the <strong className={classes.bold}>green marker</strong> on the map to select the <strong className={classes.bold}>location</strong>.</p>

              <p>Then <strong className={classes.bold}>scroll down</strong> to <strong className={classes.bold}>select</strong> which <strong className={classes.bold}>field</strong> you are interested in, the <strong className={classes.bold}>type of statistic</strong>, and the <strong className={classes.bold}>variables</strong> you would like to analyze, then click on <strong className={classes.bold}>Display Statistics</strong> to visualize the results.</p>

              <p><strong className={classes.bold}>To view an analysis at the location of your choice</strong>, please contact us below.</p>
            </div>
          )}

          <div className={classes.btnContainer}>
            <Button
              variant="contained"
              className={classes.fillButton}
              classes={{ label: classes.fillLabel }}
              onClick={openContactUsModale}
            >
              Contact us
            </Button>

            {displayFirstContent && (
              <Button
                variant="contained"
                className={classes.floatingButton}
                classes={{ label: classes.label }}
                onClick={displayNextContent}
              >
                Continue
              </Button>
            )}

            {!displayFirstContent && (
              <Button
                variant="contained"
                className={classes.floatingButton}
                classes={{ label: classes.label }}
                onClick={displayPreviousContent}
              >
                Back
              </Button>
            )}
          </div>
        </div>
      )}
      {statsIsLoading && (
        <div className={classes.loader}>
          <Typography>{t('projects.loading')}</Typography>
          <Loader size={30} />
        </div>
      )}
      <div id="lastSection" ref={lastSectionRef}>
        <PropertiesList
          items={points}
          title={t('points.pointSelection')}
          onItemClick={handlePointSelection}
          selectedId={selectedMarkerId}
          sort
        />
        <PropertiesList
          items={fields}
          title={t('points.fieldTitle')}
          onItemClick={changeField}
          selectedId={selectedField.id}
          idName={fieldsIdName}
        />
        <PropertiesList
          items={statisticTypes}
          title={t('points.statisticTitle')}
          onItemClick={changeType}
          selectedId={selectedType.id}
          idName={statisticsIdName}
        />
        <PropertiesList
          items={canRun ? shortVariables : variables}
          title={t('points.variablesTitle')}
          onItemClick={changeVariables}
          selectedId={selectedVariable.name}
          idName={variablesIdName}
        />
        <Button
          color="secondary"
          variant="contained"
          disabled={
            selectedVariable.name !== undefined && canRun
              ? !canRun
              : !isGraphAvailable
          }
          data-stats-ids={statsIdsToRequest2}
          data-stat-type={selectedType.id || ''}
          selected-field-str={selectedField.name}
          selected-variable-str={selectedVariable.name}
          selected-type-str={selectedType.name}
          onClick={displayStatistics}
          fullWidth
          id={displayButtonIdName}
        >
          {!canRun
            ? t('points.displayStatisticButton')
            : t('points.displayExportButton')}
        </Button>
        <Button
          variant="contained"
          onClick={clearSelection}
          className={classes.redButton}
          fullWidth
        >
          {t('points.clearSelectionButton')}
        </Button>
        <Spacer height={15} />
      </div>
    </>
  );
};

ProjectAnalyze.propTypes = {
  points: PropTypes.array.isRequired,
  selectedMarkerId: PropTypes.number,
  onSelectMarker: PropTypes.func.isRequired,
  fields: PropTypes.array.isRequired,
  statisticTypes: PropTypes.array.isRequired,
  variables: PropTypes.array.isRequired,
  changeField: PropTypes.func.isRequired,
  changeType: PropTypes.func.isRequired,
  changeVariables: PropTypes.func.isRequired,
  parameters: PropTypes.object.isRequired,
  statsIsLoading: PropTypes.bool.isRequired,
  clearSelection: PropTypes.func.isRequired,
  displayStatistics: PropTypes.func.isRequired,
  variablesIdName: PropTypes.string.isRequired,
  statisticsIdName: PropTypes.string.isRequired,
  fieldsIdName: PropTypes.string.isRequired,
  displayButtonIdName: PropTypes.string.isRequired,
};

export default memo(ProjectAnalyze);
