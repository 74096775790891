import { generatePath } from 'react-router';

import request from 'helpers/request';
import {
  PROJECTS_URL,
  PROJECT_URL,
  UPDATE_PROJECT_NAME_URL,
  CREATE_DEMO_PROJECT_URL,
  UPDATE_PROJECT_COLLABORATORS_URL,
  PROJECT_URL_COMPUTE,
  PROJECT_STATS_URL,
  PROJECT_URL_CANCEL_COMPUTE,
  PROJECT_GRAPH_STATS_URL,
  PROJECT_ZONE_URL,
  PROJECT_GRAPH_EXPORT_URL,
} from 'constants/api';
import { makeXhrRequest } from 'helpers/requestXhr';

export const getProjects = (params = {}) =>
  request.get(PROJECTS_URL, { params });

export const getProject = (id, params = {}) =>
  request.get(generatePath(PROJECT_URL, { id }), { params });

export const createProject = (data) => request.post(PROJECTS_URL, data);
export const createDemoProject = (data) => request.post(CREATE_DEMO_PROJECT_URL, data);

export const deleteProject = (id) => request.delete(`${PROJECTS_URL}${id}/`);

export const renameProject = (id, projectName) => {
  request.post(generatePath(UPDATE_PROJECT_NAME_URL, { id }), {
    name: projectName,
  });
};

export const computeProjectStatistics = (id) =>
  request.put(generatePath(PROJECT_URL_COMPUTE, { id }));

export const updateProjectCollaborators = ({ id, collaborators }) =>
  request.post(generatePath(UPDATE_PROJECT_COLLABORATORS_URL, { id }), {
    collaborators,
  });

export const getProjectStats = (id) =>
  request.get(generatePath(PROJECT_STATS_URL, { id }));

export const cancelComputeProjectStatistics = (id) =>
  request.put(generatePath(PROJECT_URL_CANCEL_COMPUTE, { id }));

export const getProjectVisualizationData = (dataUri) => request.get(dataUri);

export const getProjectGraphDataByStatsId = ({ projectId, statsId }) =>
  request.get(generatePath(PROJECT_GRAPH_STATS_URL, { id: projectId }), {
    params: {
      stats_id: statsId,
    },
  });

export const getProjectZone = (id, params) =>
  request.get(generatePath(PROJECT_ZONE_URL, { id }), { params });

export const downloadGraphFile = ({ projectId, params }) =>
  makeXhrRequest({
    method: 'GET',
    url: generatePath(PROJECT_GRAPH_EXPORT_URL, { id: projectId }),
    responseType: 'blob',
    params,
  });
