import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  appBar: {
    width: '100%',
    padding: 0,
    zIndex: 0,
    position: 'relative',
    '&.MuiAppBar-colorPrimary': {
      backgroundColor: 'white',
    },
  },
  hijackAppBar: {
    width: '100%',
    padding: 0,
    position: 'relative',
    '&.MuiAppBar-colorPrimary': {
      backgroundColor: 'red',
    },
  },
  toolbar: {
    width: '100%',
    padding: 0,
    display: 'flex',
    flexDirection: 'column',
  },
  smallToolbar: {
    width: '100%',
    padding: 0,
  },
  navebarContainer: {
    width: '100%',
    height: 80,
    padding: '8px 24px',
    display: 'flex',
    alignItems: 'start',
    justifyContent: 'space-between',
  },
  navbarItem: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: 10,
  },
  logo: {
    '& img': {
      height: '40px',
      maxWidth: '100%',
    },
  },
  menuContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'start',
    justifyContent: 'space-between',
  },
  menuItems: {
    display: 'flex',
    alignItems: 'center',
    listStyleType: 'none',
    '&.MuiMenuItem-root': {
      padding: '0 20px',
      fontSize: 14,
    },
  },
  login: {
    display: 'flex',
    alignItems: 'center',
    listStyleType: 'none',
    '&.MuiMenuItem-root': {
      padding: '0 20px',
      fontSize: 14,
    },
  },
  projectMenuContainer: {
    width: '100%',
    padding: '0',
  },
  projectMenuItem: {
    width: '100%',
    padding: '0',
  },
  linkContainer: {
    '&:hover': {
      backgroundColor: '#FFFFFF',
    },
  },
  link: {
    color: '#5e6366',
    fontWeight: 600,
    '&:hover, &.active': {
      color: theme.palette.sinay.accent,
      textDecoration: 'none',
    },
  },
  offset: {
    '&.MuiToolbar-regular': {
      minHeight: ({ hasMenu }) =>
        hasMenu
          ? theme.navbarHeight + theme.projectMenuHeight
          : theme.navbarHeight,
      display: ({ hasMenu }) => (hasMenu ? 'none' : 'none'),
    },
  },
}));
