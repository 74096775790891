import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(({ palette }) => ({
  container: {
    width: 650,
    paddingBottom: 20,
  },
  title: {
    marginBottom: 10,
  },
  link: {
    marginRight: 5,
  },
  formRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& input': {
      padding: 10,
      width: 400,
    },
  },
  modalSelect: {
    '& div': {
      padding: 10,
      minWidth: 380,
      overflow: 'hidden',
    },
    marginBottom: 10,
  },
  modalButton: {
    display: 'flex',
    justifyContent: 'center',
  },
  helperText: {
    height: 20,
    wordWrap: 'break-word',
  },
  fieldName: {
    marginRight: 30,
    fontWeight: 700,
    width: 120,
    textAlign: 'right',
  },
  radioGroup: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  inputFieldName: {
    marginBottom: 20,
  },
  locationLabel: {
    display: 'flex',
    '& p': {
      paddingLeft: 27,
    },
  },
  closeButton: {
    backgroundColor: palette.danger.middle,
    '&:hover': {
      backgroundColor: palette.danger.main,
    },
    marginRight: 10,
    color: 'white',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    borderTop: `1px solid ${palette.lightGrey.main}`,
    paddingTop: 15,
  },
  formGroup: {
    margin: '15px 0 15px 0',
  },
  emailLink: {
    margin: '0 5px 0 5px',
  },
}));
