import React, { memo, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
import { useTranslation } from 'react-i18next';

import { Loader } from 'components/common/Loader';
import {
  requestStaticPageIfNeed,
  resetStaticPagesError,
} from 'ducks/staticPages/actions';
import {
  selectPageContentByUrl,
  selectStaticPageError,
} from 'ducks/staticPages/selectors';
import FaqContainer from 'components/common/FaqPage';
import StatisticsContainer from 'components/common/StatisticsPage';
import DetailsPage from 'components/common/DetailsPage';
import Empty from 'components/common/EmptyContentFallback';
import { parseStaticPageIntoContentBlocks } from 'helpers/parseStaticPage';

import { useStyles } from './styles';

const HTTP_CLIENT_ERROR_STATUS_CODE = 400;

/**
 * HelpPage - container for help page
 */
const HelpPageContainer = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { url } = useParams();
  const content = useSelector((state) => selectPageContentByUrl(state, url));
  const { status } = useSelector(selectStaticPageError);
  const contentBlocks = useMemo(
    () => parseStaticPageIntoContentBlocks(content),
    [content]
  );

  const isFaqPage = url === 'faq';
  const isStatisticsPage = url === 'statistics';

  useEffect(() => {
    dispatch(requestStaticPageIfNeed(url));
    return () => dispatch(resetStaticPagesError());
  }, [dispatch, url]);

  if (status > HTTP_CLIENT_ERROR_STATUS_CODE) {
    return (
      <div className={classes.emptyStateContainer}>
        <Empty
          iconComponent={<AssignmentOutlinedIcon />}
          title={t('emptyState.helpPage.title')}
          subtitle={t('emptyState.helpPage.subtitle')}
        />
      </div>
    );
  }

  if (!content) {
    return <Loader center />;
  }

  if (isFaqPage) {
    return <FaqContainer />;
  }

  if (isStatisticsPage) {
    return <StatisticsContainer />;
  }
  return <DetailsPage contentBlocks={contentBlocks} />;
};

export default memo(HelpPageContainer);
