import React, { memo } from 'react';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import GetAppIcon from '@material-ui/icons/GetApp';
import { CircularProgress } from '@material-ui/core';
import { useDispatch } from 'react-redux';

import { STATUS_LOADING } from 'constants/common';
import { trackStatsDownload } from 'ducks/trackers/actions/workzone';

import { useStyles } from './styles';

/**
 * Contains buttons for downloading graph data files
 * @param {function} selectButton - handler for buttons
 * @param {Array} loadingFormats - array of download file formats
 * @param {Object} downloadStatuses
 */
const DownloadGraphDataFileButtonGroup = ({
  selectButton,
  loadingFormats,
  downloadStatuses,
  statsIds,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleButtonClick = (format) => () => {
    const strStatsIds = statsIds[0].split('_').shift();
    dispatch(trackStatsDownload(format, strStatsIds));
  };

  return (
    <div className={classes.buttonsContainer}>
      <ToggleButtonGroup onChange={selectButton} className={classes.buttons}>
        {loadingFormats.map((format) => {
          const isLoading = downloadStatuses[format] === STATUS_LOADING;

          return (
            <ToggleButton
              value={format}
              key={format}
              disabled={isLoading}
              onClick={handleButtonClick(format)}
            >
              <GetAppIcon fontSize="small" />
              {format}
              {isLoading && (
                <CircularProgress
                  size={25}
                  thickness={3}
                  className={classes.loader}
                />
              )}
            </ToggleButton>
          );
        })}
      </ToggleButtonGroup>
    </div>
  );
};

export default memo(DownloadGraphDataFileButtonGroup);
