import React, { memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import CheckIcon from '@material-ui/icons/Check';
import GetAppIcon from '@material-ui/icons/GetApp';
import DeleteIcon from '@material-ui/icons/Delete';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { useDispatch } from 'react-redux';

import { formatDate } from 'helpers/date';
import uploadIcon from 'assets/images/project/upload-icon.png';
import {
  FINISHED_PLANNING_STATUS,
  RUNNING_PLANNING_STATUS,
  FAILED_PLANNING_STATUS,
  PLANNING_TEMPLATE_URL,
  PENDING_PLANNING_STATUS,
} from 'constants/projects';
import UploadButton from 'components/common/buttons/UploadButton';
import { ROUTES } from 'constants/routes';
import BaseDataTable from 'components/common/BaseDataTable';
import { Loader } from 'components/common/Loader';
import NativeLink from 'components/common/NativeLink';
import {
  trackAWDHelplink,
  trackDownloadTemplate,
  trackUploadClick,
  trackUploadedFile,
  trackDeleteClick,
  trackDownload,
} from 'ducks/trackers/actions/AWD';

import { useStyles } from './styles';

/**
 * Local component for use in Instruction component
 * Creates instruction list item
 */
const InstructionItem = ({ children }) => (
  <li>
    <Typography>{children}</Typography>
  </li>
);

/**
 * Local component
 * Creates list of instructions
 */
const Instruction = ({ children }) => <ol>{children}</ol>;

/**
 * Local component
 * Displays Downtime page instructions
 */
const DowntimeInstructions = ({ classes }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleDownloadTemplate = () => {
    dispatch(trackDownloadTemplate());
  };

  const handleHelpLink = (helplink) => () => {
    dispatch(trackAWDHelplink(helplink));
  };

  return (
    <Instruction>
      <InstructionItem>
        <NativeLink
          to={encodeURI(PLANNING_TEMPLATE_URL)}
          className={classes.link}
          onClick={handleDownloadTemplate}
        >
          {t('downtime.download')}
        </NativeLink>
        {t('downtime.downloadInstructionPartOne')}
        <Link
          to={ROUTES.helpDowntime}
          className={classes.selectedText}
          onClick={handleHelpLink(ROUTES.helpDowntime)}
        >
          {t('downtime.instructionsPage')}
        </Link>
        {t('downtime.downloadInstructionPartTwo')}
      </InstructionItem>
      <InstructionItem>{t('downtime.pointInstructionPart')}</InstructionItem>
      <InstructionItem>
        {t('downtime.emailInstructionPart')}
        <strong className={classes.selectedText}>
          {t('downtime.plannings')}
        </strong>
        {t('downtime.belowMap')}
      </InstructionItem>
    </Instruction>
  );
};

/**
 * Local component
 * Displays points list in table
 */
const PointsList = ({ points, classes }) => (
  <ul className={classes.pointsList}>
    {points.map(({ name, lng, lat }) => (
      <li key={name}>{`${name} (${lng}, ${lat})`}</li>
    ))}
  </ul>
);

/**
 * Local component
 * Displays icon buttons in planning table
 */
const IconButtons = ({
  planning,
  deletePlanning,
  downloadPlanning,
  classes,
  allowDeletePlanning,
}) => {
  const { status: planningStatus, id: planningId } = planning;

  const handleClickDeletePlanning = useCallback(
    () => deletePlanning(planning),
    [deletePlanning, planning]
  );

  const handleClickDownloadPlanning = useCallback(() => {
    downloadPlanning({ planningId });
  }, [planningId, downloadPlanning]);

  const isPlanningRunning = [
    RUNNING_PLANNING_STATUS,
    PENDING_PLANNING_STATUS,
  ].includes(planningStatus);

  return (
    <div className={classes.iconButtons}>
      {planningStatus === FINISHED_PLANNING_STATUS && (
        <IconButton onClick={handleClickDownloadPlanning}>
          <GetAppIcon />
        </IconButton>
      )}
      {allowDeletePlanning && !isPlanningRunning && (
        <IconButton onClick={handleClickDeletePlanning}>
          <DeleteIcon />
        </IconButton>
      )}
    </div>
  );
};

/**
 * Local component
 * Displays button right to status icon if planning has error_message
 * On click opens modal with an error message text
 * @param { string } errorMessage - error message to be displayed in modal
 * @param { function } showErrorLog - callback on button click
 * @param { object } classes
 */
const ShowErrorLogButton = ({ errorMessage, showErrorLog, classes }) => {
  const { t } = useTranslation();

  const handleClick = useCallback(() => {
    showErrorLog(errorMessage);
  }, [showErrorLog, errorMessage]);

  return errorMessage ? (
    <Button
      variant="outlined"
      onClick={handleClick}
      className={classes.errorLogButton}
    >
      {t('downtime.planningTable.showErrorLog')}
    </Button>
  ) : null;
};

/**
 * Local component for use in Downtime page
 * Displays planning table
 */
const PlanningsTable = ({
  plannings,
  classes,
  deletePlanning,
  downloadPlanning,
  showErrorLog,
  allowDeletePlanning,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const statusArray = useMemo(
    () => ({
      FINISHED: {
        title: FINISHED_PLANNING_STATUS,
        icon: <CheckIcon />,
      },
      RUNNING: {
        title: RUNNING_PLANNING_STATUS,
        icon: <Loader size={18} className={classes.loader} />,
      },
      FAILED: {
        title: FAILED_PLANNING_STATUS,
        icon: <CloseIcon />,
      },
      PENDING: {
        title: PENDING_PLANNING_STATUS,
        icon: <NotInterestedIcon />,
      },
    }),
    [classes]
  );

  const handleClickDeletePlanning = (e) => {
    deletePlanning(e);
    dispatch(trackDeleteClick());
  };

  const handleClickDownloadPlanning = (e) => {
    downloadPlanning(e);
    dispatch(trackDownload());
  };

  const columns = useMemo(
    () => [
      {
        field: 'name',
        title: t('downtime.planningTable.name'),
      },
      {
        field: 'startedAt',
        title: t('downtime.planningTable.date'),
        cell: (date) => date && formatDate(date),
      },
      {
        field: 'points',
        title: t('downtime.planningTable.points'),
        cell: (points) => <PointsList points={points} classes={classes} />,
      },
      {
        field: 'status',
        title: t('downtime.planningTable.status'),
        cell: (status, { errorMessage }) => (
          <>
            {statusArray[status]?.icon}
            <ShowErrorLogButton
              errorMessage={errorMessage}
              showErrorLog={showErrorLog}
              classes={classes}
            />
          </>
        ),
      },
      {
        field: 'buttons',
        title: '',
        cell: (field, planning) => (
          <IconButtons
            planning={planning}
            deletePlanning={handleClickDeletePlanning}
            classes={classes}
            downloadPlanning={handleClickDownloadPlanning}
            allowDeletePlanning={allowDeletePlanning}
          />
        ),
      },
    ],
    [
      t,
      classes,
      statusArray,
      deletePlanning,
      allowDeletePlanning,
      downloadPlanning,
      showErrorLog,
    ]
  );

  return (
    <BaseDataTable
      data={plannings}
      columns={columns}
      itemKey="id"
      padding="none"
      className={classes.table}
    />
  );
};

/**
 * Displays Advanced Weather Downtime page
 * @param { array } planningsList - plannings array
 * @param { function } deletePlanning - delete planning button handler
 * @param { function } uploadPlanning - upload planning button handler
 * @param { function } downloadPlanning
 * @param { function } showErrorLog - show modal with the planning error text
 * @param { boolean } allowUploadPlanning
 * @param { boolean } allowDeletePlanning
 */
const Downtime = ({
  planningsList,
  uploadPlanning,
  deletePlanning,
  downloadPlanning,
  showErrorLog,
  allowUploadPlanning,
  allowDeletePlanning,
  isOfferExpired,
  offerExpiredCallback,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();

  const handleUploadButtonClick = (e) => {
    offerExpiredCallback(e);
    dispatch(trackUploadClick());
  };

  const handleOnUpload = (e) => {
    uploadPlanning(e);
    dispatch(trackUploadedFile());
  };

  return (
    <Grid container justifyContent="center">
      <Grid item lg={6} md={6} sm={12}>
        <Typography variant="h5" className={classes.title}>
          {t('downtime.title')}
        </Typography>
        <div className={classes.instructions}>
          <Typography variant="h6">{t('downtime.instructions')}</Typography>
          <DowntimeInstructions classes={classes} />
        </div>
        <div>
          <Typography variant="h6">{t('downtime.plannings')}</Typography>
          <PlanningsTable
            plannings={planningsList}
            classes={classes}
            showErrorLog={showErrorLog}
            deletePlanning={deletePlanning}
            downloadPlanning={downloadPlanning}
            allowDeletePlanning={allowDeletePlanning}
          />
          <UploadButton
            variant="contained"
            color="secondary"
            className={classes.button}
            onUpload={handleOnUpload}
            disabled={!allowUploadPlanning}
            onClick={handleUploadButtonClick}
            inputType={isOfferExpired ? 'button' : 'file'}
          >
            {t('downtime.uploadPlanningButton')}
            <img
              src={uploadIcon}
              alt={t('alts.upload')}
              className={classes.buttonIcon}
            />
          </UploadButton>
        </div>
      </Grid>
    </Grid>
  );
};

Downtime.propTypes = {
  uploadPlanning: PropTypes.func.isRequired,
  planningsList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      user: PropTypes.number,
      project: PropTypes.number,
      name: PropTypes.string,
      status: PropTypes.string,
      taskId: PropTypes.string,
      document: PropTypes.string,
      computedAt: PropTypes.string,
      startedAt: PropTypes.string,
      points: PropTypes.array,
    })
  ).isRequired,
  deletePlanning: PropTypes.func.isRequired,
  showErrorLog: PropTypes.func.isRequired,
  downloadPlanning: PropTypes.func.isRequired,
  allowUploadPlanning: PropTypes.bool.isRequired,
  allowDeletePlanning: PropTypes.bool.isRequired,
  isOfferExpired: PropTypes.bool,
  offerExpiredCallback: PropTypes.func.isRequired,
};

export default memo(Downtime);
