import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(({ palette }) => ({
  modalWrapper: {
    minWidth: 460,
  },
  modalTitle: {
    fontSize: 20,
    borderBottom: `1px solid ${palette.lightGrey.main}`,
    padding: 10,
    color: palette.blue.main,
  },
  modalTitleIcon: {
    margin: 10,
    fill: palette.blue.main,
  },
  modalContent: {
    padding: 20,
  },
}));
