import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    margin: 20,
    position: 'relative',
  },
  img: {
    width: '100%',
  },
  parent: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: 0,
  },
  card: {
    width: 325,
    height: 300,
    padding: 20,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    zIndex: 500,
    background: '#FFF',
    borderRadius: 15,
    boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
  },
  title: {
    width: '100%',
    padding: '0 10px 10px',
    color: theme.palette.sinay.primary,
    fontSize: 28,
    fontWeight: 700,
    borderBottom: '1px solid #BBB',
    textAlign: 'center',
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: 8,
    fontSize: 16,
  },
  floatingButton: {
    borderRadius: 10,
    backgroundColor: theme.palette.sinay.primary,
    '&:hover, &.active': {
      backgroundColor: theme.palette.sinay.accent,
    },
    '&:focus': {
      outline: 'none',
    },
  },
  label: {
    color: 'white',
    fontWeight: 650,
    textTransform: 'none',
  },
}));
