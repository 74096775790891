import {
  CREATE_TRIAL_PROJECT,
  CREATE_TRIAL_PROJECT_ERROR,
  CREATE_TRIAL_PROJECT_SUCCESS,
  CREATE_DEMO_PROJECT,
  CREATE_DEMO_PROJECT_ERROR,
  CREATE_DEMO_PROJECT_SUCCESS,
} from 'ducks/projects/types';
import {
  REQUEST_PROJECTS,
  REQUEST_PROJECTS_SUCCESS,
  REQUEST_PROJECTS_ERROR,
  REQUEST_PROJECT,
  REQUEST_PROJECT_IF_NEED,
  REQUEST_PROJECT_SUCCESS,
  REQUEST_PROJECT_ERROR,
  CREATE_PROJECT,
  CREATE_PROJECT_ERROR,
  CREATE_PROJECT_SUCCESS,
  DELETE_PROJECT,
  DELETE_PROJECT_ERROR,
  DELETE_PROJECT_SUCCESS,
  RENAME_PROJECT,
  RENAME_PROJECT_ERROR,
  RENAME_PROJECT_SUCCESS,
  CLEAR_PROJECTS_ERROR,
  COMPUTE_PROJECT_STATISTICS,
  COMPUTE_PROJECT_STATISTICS_ERROR,
  COMPUTE_PROJECT_STATISTICS_SUCCESS,
  REQUEST_PROJECTS_IF_NEED,
  UPDATE_PROJECT_COLLABORATORS_REQUEST,
  UPDATE_PROJECT_COLLABORATORS_REQUEST_SUCCESS,
  UPDATE_PROJECT_COLLABORATORS_REQUEST_ERROR,
  REQUEST_PROJECT_STATS,
  REQUEST_PROJECT_STATS_SUCCESS,
  REQUEST_PROJECT_STATS_ERROR,
  REQUEST_PROJECT_VISUALIZATION_DATA,
  REQUEST_PROJECT_VISUALIZATION_DATA_SUCCESS,
  REQUEST_PROJECT_VISUALIZATION_DATA_ERROR,
  REQUEST_PROJECT_VISUALIZATION_DATA_IF_NEED,
  CANCEL_COMPUTE_PROJECT_STATISTICS,
  CANCEL_COMPUTE_PROJECT_STATISTICS_SUCCESS,
  CANCEL_COMPUTE_PROJECT_STATISTICS_ERROR,
  REQUEST_PROJECT_ZONE,
  REQUEST_PROJECT_ZONE_SUCCESS,
  REQUEST_PROJECT_ZONE_ERROR,
  START_UPDATE_PROJECT_JOB,
  STOP_UPDATE_PROJECT_JOB,
  FINISH_UPDATE_PROJECT_JOB,
  DOWNLOAD_GRAPH_FILE,
  DOWNLOAD_GRAPH_FILE_SUCCESS,
  DOWNLOAD_GRAPH_FILE_ERROR,
  DOWNLOAD_GRAPH_RESET,
} from './types';

export const requestProjects = ({ params }) => ({
  type: REQUEST_PROJECTS,
  params,
});

export const requestProjectsIfNeed = (params = {}) => ({
  type: REQUEST_PROJECTS_IF_NEED,
  params,
});

export const requestProjectsSuccess = (data) => ({
  type: REQUEST_PROJECTS_SUCCESS,
  data,
});

export const requestProjectsError = (error) => ({
  type: REQUEST_PROJECTS_ERROR,
  error,
});

export const requestProject = ({ params, projectId }) => ({
  type: REQUEST_PROJECT,
  params,
  projectId,
});

export const requestProjectIfNeed = ({ params, projectId }) => ({
  type: REQUEST_PROJECT_IF_NEED,
  params,
  projectId,
});

export const requestProjectSuccess = (project) => ({
  type: REQUEST_PROJECT_SUCCESS,
  project,
});

export const requestProjectError = (error) => ({
  type: REQUEST_PROJECT_ERROR,
  error,
});

export const createProject = (project) => ({
  type: CREATE_PROJECT,
  project,
});

export const createProjectSuccess = (project) => ({
  type: CREATE_PROJECT_SUCCESS,
  project,
});

export const createProjectError = (error) => ({
  type: CREATE_PROJECT_ERROR,
  error,
});

export const createTrialProject = (project) => ({
  type: CREATE_TRIAL_PROJECT,
  project,
});

export const createTrialProjectSuccess = (project) => ({
  type: CREATE_TRIAL_PROJECT_SUCCESS,
  project,
});

export const createTrialProjectError = (error) => ({
  type: CREATE_TRIAL_PROJECT_ERROR,
  error,
});

export const createDemoProject = (project) => ({
  type: CREATE_DEMO_PROJECT,
  project,
});

export const createDemoProjectSuccess = (project) => ({
  type: CREATE_DEMO_PROJECT_SUCCESS,
  project,
});

export const createDemoProjectError = (error) => ({
  type: CREATE_DEMO_PROJECT_ERROR,
  error,
});

export const deleteProject = (id) => ({
  type: DELETE_PROJECT,
  id,
});

export const deleteProjectSuccess = (id) => ({
  type: DELETE_PROJECT_SUCCESS,
  id,
});

export const deleteProjectError = (error) => ({
  type: DELETE_PROJECT_ERROR,
  error,
});

export const renameProject = (id, projectName) => ({
  type: RENAME_PROJECT,
  id,
  projectName,
});

export const renameProjectSuccess = (id, projectName) => ({
  type: RENAME_PROJECT_SUCCESS,
  id,
  projectName,
});

export const renameProjectError = (error) => ({
  type: RENAME_PROJECT_ERROR,
  error,
});

export const computeProjectStatistics = (id) => ({
  type: COMPUTE_PROJECT_STATISTICS,
  id,
});

export const computeProjectStatisticsSuccess = (project) => ({
  type: COMPUTE_PROJECT_STATISTICS_SUCCESS,
  project,
});

export const computeProjectStatisticsError = (error) => ({
  type: COMPUTE_PROJECT_STATISTICS_ERROR,
  error,
});

export const clearProjectsError = () => ({
  type: CLEAR_PROJECTS_ERROR,
});

export const updateProjectCollaboratorsRequest = ({ data, user }) => ({
  type: UPDATE_PROJECT_COLLABORATORS_REQUEST,
  data,
  user,
});

export const updateProjectCollaboratorsRequestSuccess = ({ projects }) => ({
  type: UPDATE_PROJECT_COLLABORATORS_REQUEST_SUCCESS,
  projects,
});

export const updateProjectCollaboratorsRequestError = (error) => ({
  type: UPDATE_PROJECT_COLLABORATORS_REQUEST_ERROR,
  error,
});

export const requestProjectStats = (projectId) => ({
  type: REQUEST_PROJECT_STATS,
  projectId,
});

export const requestProjectStatsSuccess = ({ stats, overviewStats, id }) => ({
  type: REQUEST_PROJECT_STATS_SUCCESS,
  stats,
  overviewStats,
  id,
});

export const requestProjectStatsError = (error) => ({
  type: REQUEST_PROJECT_STATS_ERROR,
  error,
});

export const cancelComputeProjectStatistics = (id) => ({
  type: CANCEL_COMPUTE_PROJECT_STATISTICS,
  id,
});

export const cancelComputeProjectStatisticsSuccess = (project) => ({
  type: CANCEL_COMPUTE_PROJECT_STATISTICS_SUCCESS,
  project,
});

export const cancelComputeProjectStatisticsError = (error) => ({
  type: CANCEL_COMPUTE_PROJECT_STATISTICS_ERROR,
  error,
});

export const requestProjectVisualizationData = ({ projectId }) => ({
  type: REQUEST_PROJECT_VISUALIZATION_DATA,
  projectId,
});

export const requestProjectVisualizationDataSuccess = ({
  visualizationData,
  projectId,
  id,
}) => ({
  type: REQUEST_PROJECT_VISUALIZATION_DATA_SUCCESS,
  visualizationData,
  projectId,
  id,
});

export const requestProjectVisualizationDataError = (error) => ({
  type: REQUEST_PROJECT_VISUALIZATION_DATA_ERROR,
  error,
});

export const requestProjectVisualizationDataIfNeed = ({ point }) => ({
  type: REQUEST_PROJECT_VISUALIZATION_DATA_IF_NEED,
  point,
});

export const requestProjectZone = ({ projectId, params }) => ({
  type: REQUEST_PROJECT_ZONE,
  projectId,
  params,
});

export const requestProjectZoneSuccess = ({ projectId, zone }) => ({
  type: REQUEST_PROJECT_ZONE_SUCCESS,
  zone,
  projectId,
});

export const requestProjectZoneError = (error) => ({
  type: REQUEST_PROJECT_ZONE_ERROR,
  error,
});

export const startUpdateProjectJob = (projectId) => ({
  type: START_UPDATE_PROJECT_JOB,
  projectId,
});

export const stopUpdateProjectJob = () => ({
  type: STOP_UPDATE_PROJECT_JOB,
});

export const finishUpdateProjectJob = (projectId) => ({
  type: FINISH_UPDATE_PROJECT_JOB,
  projectId,
});

export const downloadGraphFile = ({
  projectId,
  currentLevel,
  monthNumber,
  pdSelectRange,
  statsIds,
  fileFormat,
  vclassType,
  id,
}) => ({
  type: DOWNLOAD_GRAPH_FILE,
  projectId,
  currentLevel,
  monthNumber,
  pdSelectRange,
  statsIds,
  fileFormat,
  vclassType,
  id,
});

export const downloadGraphFileError = (id, error) => ({
  type: DOWNLOAD_GRAPH_FILE_ERROR,
  id,
  error,
});

export const downloadGraphFileSuccess = (id) => ({
  type: DOWNLOAD_GRAPH_FILE_SUCCESS,
  id,
});

export const resetDownloadGraph = () => ({
  type: DOWNLOAD_GRAPH_RESET,
});
