import { takeLatest, put, call, select, all } from 'redux-saga/effects';

import { buyMaps, getMaps, getTileProperties } from 'services/stat2d';
import { prepareTileProperties } from 'helpers/stats2d';
import { selectNeedMapsLoadingById } from 'ducks/stat2d/selectors';

import {
  REQUEST_BUY_MAPS,
  REQUEST_BUY_MAPS_SUCCESS,
  REQUEST_GET_MAPS,
  REQUEST_GET_MAPS_IF_NEED,
  REQUEST_GET_MAPS_SUCCESS,
} from './types';
import {
  requestBuyMapsError,
  requestBuyMapsSuccess,
  requestGetMaps,
  requestGetMapsError,
  requestGetMapsSuccess,
  requestGetTilePropertiesError,
  requestGetTilePropertiesSuccess,
} from './actions';
import { selectTilesFromStats2d } from './selectors';

/**
 * Requests buying of maps
 */
function* requestBuyMapsWorker({ payload: { zoneId } }) {
  try {
    const map = yield call(buyMaps, { zone: zoneId });
    yield put(requestBuyMapsSuccess(map));
  } catch (error) {
    yield put(requestBuyMapsError(error));
  }
}

/**
 * Requests get of maps by zone id
 */
function* requestGetMapsWorker({ params }) {
  try {
    const maps = yield call(getMaps, params);
    yield put(requestGetMapsSuccess(maps));
  } catch (error) {
    yield put(requestGetMapsError(error));
  }
}

/**
 * Requests get of maps by zone id
 */
function* requestGetMapsIfNeedWorker(params) {
  const needRequest = yield select(selectNeedMapsLoadingById, params.zoneId);
  if (needRequest) {
    yield put(requestGetMaps(params));
  }
}

/**
 * Requests get of maps by zone id
 */
function* requestGetTilesPropertiesWorker() {
  try {
    const tiles = yield select(selectTilesFromStats2d);
    const properties = yield all(tiles.map(getTileProperties));
    const preparedProperties = prepareTileProperties(tiles, properties);

    yield put(requestGetTilePropertiesSuccess(preparedProperties));
  } catch (error) {
    yield put(requestGetTilePropertiesError(error));
  }
}

export default function* mainSaga() {
  yield takeLatest(REQUEST_BUY_MAPS, requestBuyMapsWorker);
  yield takeLatest(REQUEST_GET_MAPS_IF_NEED, requestGetMapsIfNeedWorker);
  yield takeLatest(REQUEST_GET_MAPS, requestGetMapsWorker);
  yield takeLatest(
    [REQUEST_GET_MAPS_SUCCESS, REQUEST_BUY_MAPS_SUCCESS],
    requestGetTilesPropertiesWorker
  );
}
