import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  controlButton: {
    '&:hover, &:focus': {
      textDecoration: 'none',
      textTransform: 'uppercase',
    },

    '&:not(:first-child)': {
      marginLeft: 10,
    },
    '&[disabled]': {
      cursor: 'default',
      pointerEvents: 'none',
    },
  },
});
