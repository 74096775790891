import {
  call,
  put,
  select,
  takeLatest,
  race,
  take,
  delay,
} from 'redux-saga/effects';

import {
  createNewDowntimeComputation,
  deletePlanning,
  downloadPlanning,
  getPlanning,
  getPlanningsList,
  startDowntimeComputation,
  uploadPlanning,
} from 'services/plannings';
import {
  trackPlanningCreationError,
  trackPlanningComputationError,
  trackDeleteConfirm,
} from 'ducks/trackers/actions/AWD';
import { jsonToFormData } from 'helpers/data';
import { preparePlanningDataForRequest } from 'helpers/plannings';
import {
  FAILED_PLANNING_STATUS,
  FINISHED_PLANNING_STATUS,
  PENDING_PLANNING_STATUS,
  PLANNINGS_ORDERING_PARAMETER,
  RUNNING_PLANNING_STATUS,
} from 'constants/projects';
import { UPDATE_PLANNING_DELAY } from 'common/config';
import { triggerDownloadBlob } from 'helpers/dom';
import { ZIP_FILE_TYPE } from 'constants/common';
import { modalsActions } from 'ducks/modals';
import { PLANNING_SETTINGS_ERRORS_MODAL } from 'constants/modals';

import {
  DELETE_PLANNING,
  REQUEST_PLANNINGS_LIST,
  REQUEST_PLANNINGS_LIST_IF_NEED,
  UPLOAD_PLANNING,
  CREATE_PLANNING,
  STOP_UPDATE_PLANNING,
  START_UPDATE_PLANNING,
  DOWNLOAD_PLANNING,
  COMPUTE_PLANNING,
} from './types';
import {
  createPlanningError,
  createPlanningSuccess,
  deletePlanningError,
  deletePlanningSuccess,
  getPlanningError,
  requestPlanningsList,
  requestPlanningsListError,
  requestPlanningsListSuccess,
  uploadPlanningError,
  uploadPlanningSuccess,
  getPlanningSuccess,
  stopUpdatePlanning,
  startUpdatePlanning,
  downloadPlanningError,
  computePlanning,
} from './actions';
import {
  selectPlanningFileNameById,
  selectPlanningsListByProjectId,
} from './selectors';

/**
 * Checks if plannings list for current project exists, if not, requests it
 */
export function* requestProjectPlanningsListIfNeedWorker({ projectId }) {
  const planningsList = yield select((state) =>
    selectPlanningsListByProjectId(state, projectId)
  );

  if (!planningsList.length) {
    yield put(requestPlanningsList(projectId));
  }
}

/**
 * Requests project plannings list
 * If status of last planning is not FINISHED, calls update planning status
 */
export function* requestProjectPlanningsListWorker({ projectId }) {
  try {
    const planningsList = yield call(getPlanningsList, {
      project: projectId,
      ordering: PLANNINGS_ORDERING_PARAMETER,
    });
    yield put(requestPlanningsListSuccess(planningsList));
    const { status, id: lastPlanningId } = planningsList[0] || {};
    if ([RUNNING_PLANNING_STATUS, PENDING_PLANNING_STATUS].includes(status)) {
      yield put(startUpdatePlanning({ planningId: lastPlanningId, projectId }));
    }
  } catch (error) {
    yield put(requestPlanningsListError(error));
  }
}

/**
 * Sends request to delete planning
 */
export function* deletePlanningWorker({ planningId, projectId }) {
  try {
    yield call(deletePlanning, planningId);
    yield put(deletePlanningSuccess({ projectId, planningId }));
    yield put(trackDeleteConfirm());
  } catch (error) {
    yield put(deletePlanningError(error));
  }
}

/**
 * Sends request to upload planning computation template
 */
export function* uploadPlanningWorker({ projectId, file }) {
  try {
    const fileData = jsonToFormData({ file });
    const panningComputationTemplateData = yield call(uploadPlanning, {
      projectId,
      data: fileData,
    });
    yield put(uploadPlanningSuccess({ data: panningComputationTemplateData }));
  } catch (error) {
    yield put(uploadPlanningError(error));
  }
}

/**
 * Makes request to get planning data every UPDATE_PLANNING_DELAY milliseconds
 * Stops making request, if status of planning is PENDING, FINISHED or FAILED.
 * @param planningId
 * @param projectId
 */
function* updatePlanningStatus({ planningId, projectId }) {
  while (true) {
    const planningData = yield call(getPlanning, planningId);
    if (
      [FINISHED_PLANNING_STATUS, FAILED_PLANNING_STATUS].includes(
        planningData.status
      )
    ) {
      yield put(stopUpdatePlanning({ projectId, planningData }));
    }
    yield delay(UPDATE_PLANNING_DELAY);
    yield put(getPlanningSuccess({ projectId, planningData }));
  }
}

/**
 * Start / Stop background task for updating planning status
 */
function* startUpdatePlanningStatusWorker({ planningId, projectId }) {
  try {
    yield race({
      report: call(updatePlanningStatus, { planningId, projectId }),
      cancel: take(STOP_UPDATE_PLANNING),
    });
  } catch (error) {
    yield put(getPlanningError(error));
  }
}

/**
 * Sends requests to create new planning
 */
export function* createPlanningWorker({ projectId, file, planningData }) {
  try {
    // build downtime form
    const preparedPlanningData = yield call(preparePlanningDataForRequest, {
      projectId,
      file,
      planningData,
    });

    // request downtime creation
    const { id: newPlanningId, errors, warnings } = yield call(
      createNewDowntimeComputation,
      preparedPlanningData
    );

    // show errors (if any)
    if (errors || warnings) {
      yield put(modalsActions.closeAllModal());
      yield put(
        modalsActions.showModal({
          modalType: PLANNING_SETTINGS_ERRORS_MODAL,
          options: {
            errors,
            warnings,
            projectId,
            newPlanningId,
            preparedPlanningData,
          },
        })
      );
      return;
    }

    yield put(
      computePlanning({ projectId, newPlanningId, preparedPlanningData })
    );
  } catch (error) {
    yield put(trackPlanningCreationError({ projectId, file }));
    yield put(createPlanningError(error));
  }
}

/**
 * Start the planning computation
 */
export function* computePlanningWorker({
  projectId,
  planningId,
  planningData,
}) {
  try {
    const newPlanningData = yield call(startDowntimeComputation, {
      planningId,
      data: planningData,
    });
    yield put(
      createPlanningSuccess({ projectId, planningData: newPlanningData })
    );
    yield put(startUpdatePlanning({ planningId, projectId }));
  } catch (error) {
    yield put(createPlanningError(error));
    yield put(trackPlanningComputationError(error));
  }
}

/**
 * makes request to get planning archive from server and downloads it
 */
export function* downloadPlanningWorker({ projectId, planningId }) {
  try {
    const planningName = yield select((state) =>
      selectPlanningFileNameById(state, projectId, planningId)
    );
    const response = yield call(downloadPlanning, {
      planningId,
    });
    yield call(triggerDownloadBlob, {
      blob: response,
      fileName: `${planningName}.zip`,
      fileType: ZIP_FILE_TYPE,
      isArrayBuffer: true,
      isDownload: true,
      isOpenInNewTab: false,
    });
  } catch (error) {
    yield put(downloadPlanningError(error));
  }
}

export default function* mainSaga() {
  yield takeLatest(
    REQUEST_PLANNINGS_LIST_IF_NEED,
    requestProjectPlanningsListIfNeedWorker
  );
  yield takeLatest(REQUEST_PLANNINGS_LIST, requestProjectPlanningsListWorker);
  yield takeLatest(DELETE_PLANNING, deletePlanningWorker);
  yield takeLatest(UPLOAD_PLANNING, uploadPlanningWorker);
  yield takeLatest(CREATE_PLANNING, createPlanningWorker);
  yield takeLatest(START_UPDATE_PLANNING, startUpdatePlanningStatusWorker);
  yield takeLatest(DOWNLOAD_PLANNING, downloadPlanningWorker);
  yield takeLatest(COMPUTE_PLANNING, computePlanningWorker);
}
