import React from 'react';
import { useTranslation } from 'react-i18next';
import PropsTypes from 'prop-types';
import { Grid, Button, Typography } from '@material-ui/core';

import BaseModal from 'components/common/BaseModal';
import { NonFieldErrorsComponent } from 'components/modals/MarineContractorsModal/common';
import { getErrorMessages } from 'helpers/marineContractors';

import { useStyles } from '../styles';

/**
 * Delete modal component
 * @returns { JSX }
 */
const DeleteModalComponent = ({ onClose, error, handleOnValid }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { status, nonFieldErrors } = getErrorMessages(error);

  return (
    <BaseModal
      onClose={onClose}
      title="Confirm the deletion ?"
      modalClass={classes.container}
    >
      <Grid container direction="column" alignItems="center">
        <Grid item>
          {status === 500 ? (
            <Typography className={classes.errorMessage}>
              {t('marineContractors.modals.common.error')}
            </Typography>
          ) : (
            <NonFieldErrorsComponent errors={nonFieldErrors} status={status} />
          )}
        </Grid>
        <Grid
          container
          item
          className={classes.buttonsContainer}
          direction="row"
          alignItems="center"
          justifyContent="space-evenly"
        >
          <Grid item>
            <Button
              onClick={handleOnValid}
              variant="contained"
              className={classes.buttonDanger}
            >
              {t('marineContractors.modals.actions.delete')}
            </Button>
          </Grid>
          <Grid item>
            <Button onClick={onClose} variant="contained" color="primary">
              {t('marineContractors.buttons.back')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </BaseModal>
  );
};

DeleteModalComponent.propTypes = {
  onClose: PropsTypes.func.isRequired,
  error: PropsTypes.object.isRequired,
  handleOnValid: PropsTypes.func.isRequired,
};

export default React.memo(DeleteModalComponent);
