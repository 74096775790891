import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { Map } from 'components/leaflet/index';
import { GEOMETRY_POSITION, MAP_LAYERS, POINT_POSITION } from 'constants/map';

/**
 * Static map component. Displays static map instance neither draggable nor zoomable.
 * @param geometry geo json string with some geometry. Map will manage zoom and coordinates to fit this geometry
 * @param position
 * @param children
 * @param className
 * @param layer
 */
const StaticMap = ({
  position,
  positionType,
  className,
  children,
  layer = MAP_LAYERS.worldImagery,
}) => (
  <Map
    defaultLayer={layer}
    layers={[layer]}
    isStatic
    minZoom={0}
    position={position}
    positionType={positionType}
    wrapperClass={className}
    withControls={false}
  >
    {children}
  </Map>
);

StaticMap.propTypes = {
  position: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
    .isRequired,
  positionType: PropTypes.oneOf([GEOMETRY_POSITION, POINT_POSITION]),
  className: PropTypes.string.isRequired,
  children: PropTypes.node,
  layer: PropTypes.oneOf(Object.values(MAP_LAYERS)),
};

export default memo(StaticMap);
