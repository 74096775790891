import React, { memo } from 'react';
import PropTypes from 'prop-types';
import intersection from 'lodash/intersection';

import Collapse from 'components/common/Collapse';
import { PROJECT_REPORTS_ALL_VALUE } from 'constants/reports';
import PropertiesListItem from 'components/points/PropertiesListItem';

/**
 * PropertiesListMultiple - component for displaying point properties
 */
const PropertiesListMultiple = ({
  items,
  title,
  selectedIds,
  onItemClick,
  onAllClick,
  idName,
}) => (
  <Collapse title={title} isOpen id={idName}>
    <PropertiesListItem
      item={{ id: PROJECT_REPORTS_ALL_VALUE, name: PROJECT_REPORTS_ALL_VALUE }}
      selected={selectedIds.length === 0}
      onItemClick={onAllClick}
    />
    {items.map((item) => (
      <PropertiesListItem
        item={item}
        selected={intersection([item.id, item.name], selectedIds).length !== 0}
        onItemClick={onItemClick}
        key={item.id}
      />
    ))}
  </Collapse>
);

PropertiesListMultiple.propTypes = {
  items: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  selectedIds: PropTypes.array.isRequired,
  onItemClick: PropTypes.func,
  idName: PropTypes.string,
};

export default memo(PropertiesListMultiple);
