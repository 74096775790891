import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

import { MapPopup } from 'components/leaflet';
import CreatePoint from 'containers/points/CreatePoint';
import {
  BaseMapPopup,
  BaseMapPopupContent,
  BaseMapPopupTitle,
} from 'components/common/BaseMapPopup';

import { useStyles } from './styles';

/**
 * Popup for map with create point form
 * @param {Function} onClose
 * @param {Number} lng
 * @param {Number} lat
 * @param {Object} project
 * @param {Object} props
 * @param {Boolean} canCreatePoint
 * @returns {jsx}
 */
const CreatePointPopup = ({
  onClose,
  lat,
  lng,
  project,
  canCreatePoint,
  ...props
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <MapPopup {...props} isOpen onClose={onClose}>
      <BaseMapPopup className={classes.createPointPopup}>
        {canCreatePoint && (
          <BaseMapPopupTitle>
            <AddCircleOutlineIcon className={classes.addIcon} />
            {t('points.create.title')}
          </BaseMapPopupTitle>
        )}
        <BaseMapPopupContent>
          <CreatePoint
            lat={lat}
            lng={lng}
            onClose={onClose}
            project={project}
            canCreatePoint={canCreatePoint}
          />
        </BaseMapPopupContent>
      </BaseMapPopup>
    </MapPopup>
  );
};

CreatePointPopup.propTypes = {
  onClose: PropTypes.func.isRequired,
  lat: PropTypes.number.isRequired,
  lng: PropTypes.number.isRequired,
  project: PropTypes.object.isRequired,
  canCreatePoint: PropTypes.bool.isRequired,
};

export default memo(CreatePointPopup);
