import { makeStyles } from '@material-ui/core/styles';
import { alpha } from '@material-ui/core/styles/colorManipulator';

export const useStyles = makeStyles(({ palette }) => ({
  collapseIcon: {
    // fill: palette.grey.main,
    // fill: palette.white.main,
    fill: palette.sinay.primary,
  },
  collapseRoot: {
    marginBottom: 10,
    border: 'none',
    boxShadow: `0 1px 1px ${alpha(palette.sinay.accent, 0.05)}`,
  },
  collapseSummaryRoot: {
    padding: '0 15px',
    height: 48,
    minHeight: 48,
    // color: palette.white.main,
    // background: palette.primary.main,

    // color: palette.white.main,
    // background: palette.sinay.primary,

    background: '#EFF0F1',
    border: `1px solid #D1D5DB`,

    borderRadius: '3px 3px 0 0',
    textTransform: 'uppercase',
    fontSize: 14,
    '&$collapseSummaryExpanded': {
      minHeight: 48,
    },
  },
  collapseSummaryContent: {
    '&$collapseSummaryExpanded': {
      margin: '12px 0',
    },
  },
  collapsePanel: {
    padding: 0,
    margin: 0,
    border: `1px solid #D1D5DB`,
    borderTop: 0,
    borderRadius: '0 0 3px 3px',
    display: 'block',
  },
  collapseSummaryExpanded: {},
}));
