import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  button: {
    background: 'white',
    borderRadius: 15,
    border: '1px solid',
    borderColor: theme.palette.sinay.accent,
    '&:hover': {
      background: 'white',
      transition: '300ms transform ease',
      boxShadow: '0 0 5px rgba(0, 0, 0, 0.4)',
    },
  },
  smallText: {
    fontSize: 14,
    color: 'black',
  },
  avatarIcon: {
    background: 'white',
    color: theme.palette.sinay.accent,
    borderRadius: '50%',
  },
}));
