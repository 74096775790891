import React, { memo, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Bar,
  XAxis,
  YAxis,
  BarChart,
  CartesianGrid,
  Customized,
  Tooltip,
} from 'recharts';
import get from 'lodash/get';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';

import {
  WEATHER_GRAPH_COMMON_PARAMS,
  DEFAULT_GRAPH_VALUE,
  VALUES_KEY,
  DEFAULT_GRAPH_HEIGHT,
  DEFAULT_GRAPH_WIDTH,
  DEFAULT_AXIS_INDEX,
  MIN_WEATHER_GRAPH_TICKS_AMOUNT,
  DEFAULT_LEVELS_BAR_WIDTH,
  CHART_SVG_ID,
  CHART_TITLE_ID,
} from 'constants/graphs';
import { palette } from 'common/theme';
import { useGraphControls } from 'hooks/useGraphControls';
import GraphParamSelect from 'components/common/graphs/GraphParamSelect';
import { MONTH_NAMES } from 'constants/common';
import { withCustomTooltipProps } from 'hocs/graphs/withCustomTooltipProps';
import KeyValueTooltip from 'components/common/graphs/KeyValueTooltip';
import { STATS_UNITS_REGEXP } from 'constants/regexp';
import { getGridTicksByMinMax } from 'helpers/graphs/common';
import CustomLevelsBar from 'components/common/graphs/CustomLevelsBar';
import DownloadGraphDataFileButtonGroup from 'containers/buttons/DownloadGraphDataFileButtonGroup';
import { useUniqueId } from 'hooks/useUniqueId';

import { useStyles } from './styles';

const withUnits = (value, units = '') => `${value.toFixed(2)} ${units}`;

// when font is bigger, ticks labels are missed on some monitors
const X_TICK_FONT_SIZE = 12;
const CANVAS_OFFSET = 30;
const LABEL_EDGE_OFFSET = 20;
const LABEL_EDGE_OFFSET_DIVISOR = 5;
const AXIS_WIDTH = 30;
const LEVELS_BAR_OFFSET = 10;
const LEVELS_FULL_LENGTH = DEFAULT_LEVELS_BAR_WIDTH + LEVELS_BAR_OFFSET;

const GRAPH_MARGIN = {
  left: CANVAS_OFFSET,
  right: CANVAS_OFFSET,
  top: CANVAS_OFFSET,
  bottom: CANVAS_OFFSET,
};

/**
 * Custom weather graph Tooltip
 */
const CustomTooltip = withCustomTooltipProps(KeyValueTooltip, {
  propsToDisplay: ['month', 'value', 'min', 'max'],
  transform: {
    value: withUnits,
    min: withUnits,
    max: withUnits,
  },
});

/**
 * Custom MC weather graph Tooltip
 */
const MCCustomTooltip = withCustomTooltipProps(KeyValueTooltip, {
  propsToDisplay: ['month', 'percentileValue', 'min', 'max'],
  transform: {
    percentileValue: withUnits,
    min: withUnits,
    max: withUnits,
  },
});

/**
 * getTicksLength - get the largest tick string
 * @param { Array } ticks
 * @return { number }
 */
const getTicksLength = (ticks) => {
  let length = 0;
  ticks.forEach((value) => {
    const valueLength = value?.toString()?.length || 0;
    if (value.toString().length > length) {
      length = valueLength;
    }
  });
  return length;
};

/**
 * computeMargin - get graph margin (largest tick string taken into account)
 * @param {Array} ticks
 * @returns { number }
 */
const computeMargin = (ticks) => {
  const maxLength = getTicksLength(ticks);
  return (
    LABEL_EDGE_OFFSET +
    (maxLength * LABEL_EDGE_OFFSET) / LABEL_EDGE_OFFSET_DIVISOR
  );
};

/**
 * Component renders min-max lines for weather graph based on BarChart ticks and graph data
 * @param { [{ niceTicks, height, padding }] } yAxisMap - y axis props
 * @param { { coordinate, ... }[] } orderedTooltipTicks - tooltip ticks for each value
 * @param { {min, max, ...}[] } data - graph data payload
 * @param { top, ... } margin - graph margins
 * @param { number } boundSize - size of limiting bound lines
 */
const MinMaxIntervals = ({
  yAxisMap,
  orderedTooltipTicks,
  data,
  margin = {},
  boundSize = 8,
}) => {
  const {
    height: yAxisHeight,
    niceTicks: yTickValues,
    padding = {},
  } = yAxisMap[DEFAULT_AXIS_INDEX];
  const fullHeight = (margin.top || 0) + yAxisHeight;
  const maxTickHeight = yAxisHeight - (padding.top || 0);
  const maxTickValue = yTickValues[yTickValues.length - 1];
  const scaleDivision = maxTickHeight / maxTickValue;
  const grey = palette.grey.middle;
  const boundHalf = Math.round(boundSize / 2);

  return (
    <g>
      {orderedTooltipTicks.map(({ coordinate: x }, index) => {
        const { min, max } = data[index];
        const y1 = fullHeight - min * scaleDivision;
        const y2 = fullHeight - max * scaleDivision;
        const x1 = x - boundHalf;
        const x2 = x + boundHalf;

        return (
          <g key={x}>
            <line stroke={grey} x1={x1} y1={y1} x2={x2} y2={y1} />
            <line stroke={grey} x1={x1} y1={y2} x2={x2} y2={y2} />
            <line stroke={grey} x1={x} y1={y1} x2={x} y2={y2} />
          </g>
        );
      })}
    </g>
  );
};

/**
 * MCWeatherGraph - copy of WeatherGraph to deal with marine contractors inputs
 * @param { Array } data - must be of length equal to the number of month. Ex:[{50: {percentileValue: 2.32, min: 1.52, max: 6.32}, 60 {...}}, ...]
 * @param { Array } percentiles - percentiles requested by the customer. Ex: [50, 60, 72, 99]
 * @param { Object } graphProps - props for graph component (width, height, className, etc)
 * @param { string } format - one of { 'DURATION', 'UPTIME', 'DOWNTIME' }
 */
export const MCWeatherGraph = ({ data, percentiles, graphProps, format }) => {
  const { t } = useTranslation();
  // the output returns float number, so we need to convert percentiles with non trailing zero
  percentiles = percentiles
    .map((p) =>
      !p.includes('.')
        ? parseInt(parseFloat(p).toFixed(1), 10)
        : parseInt(p, 10)
    )
    .sort();

  const [percentile, setPercentile] = useState(percentiles[0]);

  const salt = useUniqueId();
  const classes = useStyles();

  const title = '';
  const field = t(`marineContractors.outputs.labels.format.${format}`);
  const graphUnits = t(`marineContractors.outputs.labels.units.${format}`);
  const yLabel = `${field} [${graphUnits}]`;

  // convert data into a readable input for the BarGraph API
  // dataFormatted: [{month: 'Jan', percentileValue: 1.56, min: 0.67, max: 3.76}, ...]
  const { maxValue, dataFormatted } = useMemo(() => {
    let maxPercentile = 0;
    const newData = [];
    data.forEach((percentilesMonth, index) => {
      const percentileMonth = percentilesMonth[percentile];
      if (percentileMonth.max !== null && percentileMonth.min !== null) {
        maxPercentile =
          percentileMonth.max > maxPercentile
            ? percentileMonth.max
            : maxPercentile;
        newData.push({
          month: MONTH_NAMES[index],
          percentileValue: percentileMonth.percentileValue,
          min: percentileMonth.min,
          max: percentileMonth.max,
        });
      }
    });
    return { maxValue: maxPercentile, dataFormatted: newData };
  }, [data, percentile]);

  const ticks = maxValue
    ? getGridTicksByMinMax({
        max: maxValue,
        minTicks: MIN_WEATHER_GRAPH_TICKS_AMOUNT,
      })
    : [];
  const domain = maxValue ? [0, maxValue] : undefined;

  const tooltipTitle = yLabel.replace(STATS_UNITS_REGEXP, '');

  const chartMargin = GRAPH_MARGIN;
  const chartWidth = graphProps.width + chartMargin.left + chartMargin.right;
  const chartHeight =
    graphProps.height + GRAPH_MARGIN.top + GRAPH_MARGIN.bottom;

  // In order to avoid this error : 'React Hooks must be called in the exact same order in every component render'
  // and return nothing to notify the user that something wrong happened
  if (!percentiles?.length) {
    console.error(
      "can't init MCWeatherGraph with 'percentiles' array parameter empty"
    );
    return <></>;
  }

  const handleChange = ({ target }) => {
    const value = percentiles[target.value];
    if (value === undefined) {
      throw Error("the percentile requested can't be recovered (bad index)");
    }
    setPercentile(percentiles[target.value]);
  };

  const quantileSelect = (
    <GraphParamSelect
      name={WEATHER_GRAPH_COMMON_PARAMS.quantiles.name}
      label={WEATHER_GRAPH_COMMON_PARAMS.quantiles.label}
      value={percentiles.indexOf(percentile)}
      values={percentiles}
      unitsRule={parseFloat}
      onChange={handleChange}
      units="%"
    />
  );

  let chart;
  if (dataFormatted.length > 0) {
    chart = (
      <BarChart
        id={CHART_SVG_ID + salt}
        width={chartWidth}
        height={chartHeight}
        data={dataFormatted}
        margin={chartMargin}
        className={classes.chartContainer}
      >
        <CartesianGrid key={1} strokeDasharray="3 3" />
        <XAxis key={2} dataKey="month" tick={{ fontSize: X_TICK_FONT_SIZE }} />
        <YAxis
          ticks={ticks}
          domain={domain}
          interval={0}
          dataKey="max"
          key={3}
          width={AXIS_WIDTH}
          label={{
            value: yLabel,
            angle: -90,
            position: 'center',
            dx: -computeMargin(ticks),
          }}
        />
        <Tooltip
          key={4}
          content={
            <MCCustomTooltip
              title={tooltipTitle}
              boldValues
              units={graphUnits}
            />
          }
          payloadUniqBy="month"
        />
        <Bar
          key={5}
          dataKey="percentileValue"
          barSize={20}
          fill={palette.blue.graph}
          unit={graphUnits}
        />
        <Customized key={6} component={MinMaxIntervals} />
      </BarChart>
    );
  } else {
    chart = (
      <Typography variant="h6">
        {' '}
        The operation constraints are to restrictive.{' '}
      </Typography>
    );
  }

  return (
    <Grid justifyContent="center" container>
      <Grid item>
        <Grid align="center" container spacing={1} direction="column">
          <Grid item>
            <Typography
              id={CHART_TITLE_ID + salt}
              className={classes.title}
              gutterBottom
              variant="h6"
            >
              {title}
            </Typography>
          </Grid>
          <Grid item container justifyContent="center" spacing={2}>
            <Grid item>{quantileSelect}</Grid>
          </Grid>
          <Grid item container justifyContent="center">
            <Grid> {chart} </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

MCWeatherGraph.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      [PropTypes.number]: PropTypes.shape({
        percentileValue: PropTypes.number,
        min: PropTypes.number,
        max: PropTypes.number,
      }),
    })
  ),
  percentiles: PropTypes.arrayOf(PropTypes.string).isRequired,
  graphProps: PropTypes.shape({
    width: PropTypes.number,
    height: PropTypes.number,
    className: PropTypes.string,
  }),
  format: PropTypes.string.isRequired,
};

MCWeatherGraph.defaultProps = {
  graphProps: {
    width: DEFAULT_GRAPH_WIDTH,
    height: DEFAULT_GRAPH_HEIGHT,
    className: '',
  },
};

/**
 * Weather graph component.
 * Displays min max and average probability of selected params occurrence in each month
 * @param { object } monthlyData - graph values, controls data, descriptions
 * @param { object } graphProps - props for graph component (width, height, className, etc)
 * @param { number } projectId - current project id
 * @param { Array } statsIds - array of current graphs stats ids
 * @param { string } pngFileName - file name for saving as png
 * @return {jsx}
 * @see http://recharts.org/en-US/api/ComposedChart
 */
const WeatherGraph = ({
  monthlyData,
  graphProps,
  statsIds,
  projectId,
  pngFileName,
}) => {
  const salt = useUniqueId();
  const classes = useStyles();
  const {
    title,
    yLabel,
    levels,
    duration,
    quantiles,
    probability,
    probabilityMin,
    probabilityMax,
    limitsParams,
    limitsValues,
    dimensions,
  } = monthlyData;

  const hasLevels = !!Object.keys(levels).length;

  const { handleChange, setParam, params, dataPath } = useGraphControls(
    dimensions
  );
  const selectLevel = useCallback((level) => setParam('level', level), [
    setParam,
  ]);

  const chartMargin = hasLevels
    ? { ...GRAPH_MARGIN, left: CANVAS_OFFSET + LEVELS_FULL_LENGTH }
    : GRAPH_MARGIN;
  const chartWidth = graphProps.width + chartMargin.left + chartMargin.right;
  const chartHeight =
    graphProps.height + GRAPH_MARGIN.top + GRAPH_MARGIN.bottom;

  const { data, maxValue } = MONTH_NAMES.reduce(
    (acc, month, monthIndex) => {
      const path = `${VALUES_KEY}.${monthIndex}.${dataPath}`;
      const max = get(probabilityMax, path, DEFAULT_GRAPH_VALUE);
      acc.maxValue = max > acc.maxValue ? max : acc.maxValue;
      acc.data.push({
        month,
        value: get(probability, path, DEFAULT_GRAPH_VALUE),
        min: get(probabilityMin, path, DEFAULT_GRAPH_VALUE),
        max,
      });

      return acc;
    },
    { data: [], maxValue: 0 }
  );

  const { units: graphUnits } = probability.attributes;
  const tooltipTitle = yLabel.replace(STATS_UNITS_REGEXP, '');
  const domain = maxValue ? [0, maxValue] : undefined;
  const ticks = maxValue
    ? getGridTicksByMinMax({
        max: maxValue,
        minTicks: MIN_WEATHER_GRAPH_TICKS_AMOUNT,
      })
    : [];

  const quantileOnOwnLine = limitsParams.length > 1;
  const quantileSelect = (
    <GraphParamSelect
      name={WEATHER_GRAPH_COMMON_PARAMS.quantiles.name}
      label={WEATHER_GRAPH_COMMON_PARAMS.quantiles.label}
      value={params[WEATHER_GRAPH_COMMON_PARAMS.quantiles.name]}
      values={quantiles[VALUES_KEY]}
      unitsRule={parseFloat}
      onChange={handleChange}
      units="%"
    />
  );

  return (
    <Grid justify="center" container>
      <Grid item>
        <Grid align="center" container spacing={1} direction="column">
          <Grid item>
            <Typography
              id={CHART_TITLE_ID + salt}
              className={classes.title}
              gutterBottom
              variant="h6"
            >
              {title}
            </Typography>
          </Grid>
          <Grid item container justify="center" spacing={2}>
            {hasLevels && <Grid item xs={2} />}
            <Grid item>
              <GraphParamSelect
                name={WEATHER_GRAPH_COMMON_PARAMS.duration.name}
                label={WEATHER_GRAPH_COMMON_PARAMS.duration.label}
                value={params[WEATHER_GRAPH_COMMON_PARAMS.duration.name]}
                values={duration[VALUES_KEY]}
                units={duration.attributes.units}
                onChange={handleChange}
              />
            </Grid>
            {limitsParams.map(({ name, label, units }) => (
              <Grid key={name} item>
                <GraphParamSelect
                  name={name}
                  label={label}
                  value={params[name]}
                  values={limitsValues[name]}
                  units={units}
                  onChange={handleChange}
                />
              </Grid>
            ))}
            {!quantileOnOwnLine && <Grid item>{quantileSelect}</Grid>}
          </Grid>
          {quantileOnOwnLine && (
            <Grid item container align="center" justify="center">
              {quantileSelect}
            </Grid>
          )}
          <Grid item container justify="center">
            <Grid>
              <BarChart
                id={CHART_SVG_ID + salt}
                width={chartWidth}
                height={chartHeight}
                data={data}
                margin={chartMargin}
                className={classes.chartContainer}
              >
                <CartesianGrid key={1} strokeDasharray="3 3" />
                <XAxis
                  key={2}
                  dataKey="month"
                  tick={{ fontSize: X_TICK_FONT_SIZE }}
                />
                <YAxis
                  ticks={ticks}
                  domain={domain}
                  interval={0}
                  dataKey="max"
                  key={3}
                  width={AXIS_WIDTH}
                  label={{
                    value: yLabel,
                    angle: -90,
                    position: 'center',
                    dx: -computeMargin(ticks),
                  }}
                />
                <Tooltip
                  key={4}
                  content={
                    <CustomTooltip
                      title={tooltipTitle}
                      boldValues
                      units={graphUnits}
                    />
                  }
                  payloadUniqBy="month"
                />
                <Bar
                  key={5}
                  dataKey="value"
                  barSize={20}
                  fill={palette.blue.graph}
                  unit={graphUnits}
                />
                <Customized key={6} component={MinMaxIntervals} />
                {hasLevels && (
                  <Customized
                    key={7}
                    levels={levels.values}
                    type={levels.type}
                    barWidth={DEFAULT_LEVELS_BAR_WIDTH}
                    selectedLevel={+params.level}
                    onSelect={selectLevel}
                    xOffset={LEVELS_BAR_OFFSET}
                    component={CustomLevelsBar}
                  />
                )}
              </BarChart>
            </Grid>
          </Grid>
          <DownloadGraphDataFileButtonGroup
            salt={salt}
            projectId={projectId}
            statsIds={statsIds}
            currentLevel={hasLevels ? +params.level : null}
            pngFileName={pngFileName}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

WeatherGraph.propTypes = {
  monthlyData: PropTypes.shape({
    title: PropTypes.string.isRequired,
    yLabel: PropTypes.string.isRequired,
    duration: PropTypes.shape({ [VALUES_KEY]: PropTypes.array.isRequired }),
    probability: PropTypes.shape({ [VALUES_KEY]: PropTypes.array.isRequired }),
    probabilityMin: PropTypes.shape({
      [VALUES_KEY]: PropTypes.array.isRequired,
    }),
    probabilityMax: PropTypes.shape({
      [VALUES_KEY]: PropTypes.array.isRequired,
    }),
    quantiles: PropTypes.shape({ [VALUES_KEY]: PropTypes.array.isRequired }),
    limitsParams: PropTypes.arrayOf(PropTypes.object).isRequired,
    limitsValues: PropTypes.objectOf(PropTypes.array).isRequired,
    dimensions: PropTypes.arrayOf(PropTypes.string).isRequired,
  }),
  graphProps: PropTypes.shape({
    width: PropTypes.number,
    height: PropTypes.number,
    className: PropTypes.string,
  }),
  projectId: PropTypes.number,
  statsIds: PropTypes.array,
  pngFileName: PropTypes.string,
};

WeatherGraph.defaultProps = {
  graphProps: {
    width: DEFAULT_GRAPH_WIDTH,
    height: DEFAULT_GRAPH_HEIGHT,
    className: '',
  },
};

export default memo(WeatherGraph);
