import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  label: {
    '&.inline-label': {
      display: 'inline',
    },
  },
  selectMenu: {
    fontSize: 15,
  },
  uppercase: {
    textTransform: 'uppercase',
  },
});
