import { takeLatest, put, call, select } from 'redux-saga/effects';

import { getStaticPage } from 'services/staticPages';

import { REQUEST_STATIC_PAGE, REQUEST_STATIC_PAGE_IF_NEED } from './types';
import {
  requestStaticPage,
  requestStaticPageError,
  requestStaticPageSuccess,
} from './actions';
import { selectPageContentByUrl } from './selectors';

export function* requestStaticPageIfNeedWorker({ url }) {
  const staticPageContent = yield select((state) =>
    selectPageContentByUrl(state, url)
  );

  if (!staticPageContent.length) {
    yield put(requestStaticPage(url));
  }
}

export function* requestStaticPageWorker({ url }) {
  try {
    const data = yield call(getStaticPage, url);
    yield put(requestStaticPageSuccess(data));
  } catch (error) {
    yield put(requestStaticPageError(error));
  }
}

export default function* offersSaga() {
  yield takeLatest(REQUEST_STATIC_PAGE, requestStaticPageWorker);
  yield takeLatest(REQUEST_STATIC_PAGE_IF_NEED, requestStaticPageIfNeedWorker);
}
