import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(({ palette }) => ({
  overviewTable: {
    marginBottom: 20,

    '& .MuiTableCell-root': {
      border: '1px solid',
      borderColor: palette.white.main,
      fontSize: 12,
      textAlign: 'center',
      padding: '2px 0',
    },
    '& .MuiTableCell-root.MuiTableCell-head': {
      background: palette.primary.light,
    },
    '& .MuiTableRow-root': {
      background: palette.lightGrey.main,

      '&:nth-child(odd)': {
        background: palette.lightGrey.light,
      },
    },
  },
}));
