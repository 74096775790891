import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  title: {
    margin: '10px',
  },
  buttonComponent: {
    textAlign: 'right',
    margin: '50px',
  },
  button: {
    margin: '10px',
  },
  percentile: {
    textAlign: 'center',
  },
  containerTitle: {
    marginTop: '20px',
  },
  selectionContainer: {
    width: '700px',
  },
  addButton: {
    color: '#439c6d',
    transform: 'scale(1)',
  },
  autocomplete: {
    width: '100%',
  },
}));
