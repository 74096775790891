import React from 'react';
import PropsTypes from 'prop-types';
import { Grid, Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import PointLocations from 'containers/points/PointLocations';
import ProjectMap from 'components/maps/ProjectMap/index';
import ProjectPolygon from 'components/maps/ProjectPolygon/index';
import EditPointFeatureGroup from 'components/maps/EditPointFeatureGroup/index';
import NewPointFeatureGroup from 'components/maps/NewPointFeatureGroup/index';
import { EMPTY_ARRAY } from 'constants/common';

import { useStyles } from './styles';

/**
 * Marine contractors locations component
 * Display checkable project points list and a map to create/select point
 * The component behaves like the `Query` tab in original projects
 * @param { object } project
 * @param { Array } selectedPoints
 * @param { Array } geometries
 * @param { Array } zoneTileGeometries
 * @param { function } handleNext
 * @param { function } handleBack
 * @param { function } handleMultipleSelection
 * @param { Array } selectedMarkers
 * @param { function } selectSingleMarkerHandler
 * @param { function } selectSingleMarkerOnMapHandler
 * @param { function } handleSelectAll
 * @param { function } handleOnDelete
 * @returns
 */
const MCLocationsComponent = ({
  project,
  selectedPoints,
  geometries,
  zoneTileGeometries,
  handleNext,
  handleBack,
  handleMultipleSelection,
  selectedMarkers,
  selectSingleMarkerHandler,
  selectSingleMarkerOnMapHandler,
  handleSelectAll,
  handleOnDelete,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
    >
      <h2 className={classes.title}>
        {t('marineContractors.locations.title')}
      </h2>
      <Grid container direction="row">
        <Grid
          container
          item
          direction="column"
          alignItems="center"
          justifyContent="flex-start"
          xs={3}
        >
          <PointLocations
            points={project.points || EMPTY_ARRAY}
            projectId={project.id}
            onSelectMarker={selectSingleMarkerHandler}
            selectedMarkers={selectedMarkers}
            allowEditAndCompute
            canCompute={false}
            handleMultipleSelection={handleMultipleSelection}
            className={classes.locations}
            selectedPoints={selectedPoints}
            handleSelectAll={handleSelectAll}
            handleOnDelete={handleOnDelete}
          />
        </Grid>
        <Grid
          container
          item
          direction="column"
          alignItems="center"
          justifyContent="center"
          xs={9}
        >
          <ProjectMap
            project={project}
            zoneTileGeometries={zoneTileGeometries}
            geometries={geometries}
            allowGeometriesControl={false}
            showTilesGeometries={false}
            showPointsLegend={false}
            wrapperClass={classes.mapContainer}
          >
            <ProjectPolygon project={project} />
            <NewPointFeatureGroup project={project} canCreatePoint />
            <EditPointFeatureGroup
              project={project}
              selectedMarkers={selectedMarkers}
              onSelectMarker={selectSingleMarkerOnMapHandler}
              selectedPoints={selectedPoints}
              noPointsCompute
            />
          </ProjectMap>
          <Grid item className={classes.buttonComponent}>
            <Button
              className={classes.button}
              disabled={!handleBack}
              onClick={handleBack}
            >
              {t('marineContractors.buttons.back')}
            </Button>
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              disabled={selectedPoints.length ? !handleNext : true}
              onClick={() => handleNext()}
            >
              {t('marineContractors.buttons.next')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

MCLocationsComponent.propTypes = {
  project: PropsTypes.object.isRequired,
  selectedPoints: PropsTypes.array.isRequired,
  geometries: PropsTypes.array.isRequired,
  zoneTileGeometries: PropsTypes.array.isRequired,
  handleNext: PropsTypes.func.isRequired,
  handleBack: PropsTypes.func.isRequired,
  handleMultipleSelection: PropsTypes.func.isRequired,
  selectedMarkers: PropsTypes.array.isRequired,
  selectSingleMarkerHandler: PropsTypes.func.isRequired,
  selectSingleMarkerOnMapHandler: PropsTypes.func.isRequired,
  handleSelectAll: PropsTypes.func,
  handleOnDelete: PropsTypes.func,
};

MCLocationsComponent.defaultProps = {
  handleSelectAll: null,
  handleOnDelete: null,
};

export default React.memo(MCLocationsComponent);
