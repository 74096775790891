import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(({ palette }) => ({
  row: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'relative',
    '& input': {
      padding: 10,
    },
  },
  modalSelect: {
    '& div': {
      padding: 10,
      maxWidth: 208,
      minWidth: 208,
      overflow: 'hidden',
    },
  },
  modalButton: {
    marginTop: 20,
    display: 'flex',
    justifyContent: 'center',
  },
  closeButton: {
    backgroundColor: palette.danger.middle,
    '&:hover': {
      backgroundColor: palette.danger.main,
    },
    marginLeft: 10,
    color: 'white',
  },
  helperText: {
    position: 'absolute',
    bottom: -20,
    wordWrap: 'break-word',
  },
  fieldName: {
    fontWeight: 700,
  },
  field: {
    marginLeft: 30,
    width: '100%',
  },
  commonErrors: {
    display: 'flex',
    justifyContent: 'center',
    '& p': {
      fontSize: 14,
      paddingBottom: 10,
    },
  },
  dataValidationDescription: {
    padding: '18px 0',
    fontSize: 12,
  },
}));
