import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  title: {
    margin: '0 30px',
  },
  chartContainer: {
    // fixes phantom recharts bug with scrollbar
    overflow: 'hidden',
  },
});
