import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Grid, Typography, Button } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';

import { MCWeatherGraph } from 'components/common/graphs/WeatherGraph';

import { useStyles } from './styles';

// aggressively copied from : https://v4.mui.com/components/tabs/
const TabPanel = ({ children, ...other }) => (
  <div role="tabpanel" {...other}>
    <Box p={3}>{children}</Box>
  </div>
);

TabPanel.propTypes = {
  children: PropTypes.node.isRequired,
};

// aggressively copied from : https://v4.mui.com/components/tabs/
const a11yProps = (index) => ({
  id: `scrollable-auto-tab-${index}`,
  'aria-controls': `scrollable-auto-tabpanel-${index}`,
});

// aggressively copied from : https://v4.mui.com/components/tabs/
const a11yPropsVert = (index) => ({
  id: `vertical-tab-${index}`,
  'aria-controls': `vertical-tabpanel-${index}`,
});

/**
 *
 * @param { Array } boats - boats object array (name and id attr used here)
 * @param { Array } points - points object array (name and id attr used here)
 * @param { Array } operations - filtered results output by boatId and pointId
 * @param { Object } boat - selected boat
 * @param { Object } point - selected point
 * @param { Array } percentiles
 * @param { Function } handleChangeBoats
 * @param { Function } handleChangePoints
 * @returns { JSX }
 */
const MCStatisticsComponent = ({
  boats,
  points,
  operations,
  boat,
  point,
  percentiles,
  handleReset,
  handleChangeBoats,
  handleChangePoints,
  handleDownload,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
    >
      <Grid item className={classes.statisticsContainer}>
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Grid item>
            <h1 className={classes.title}>
              {t('marineContractors.statistics.title')}
            </h1>
          </Grid>
          <Grid item>
            <AppBar
              position="static"
              color="default"
              className={classes.appBar}
            >
              <Tabs
                value={boat.id}
                onChange={handleChangeBoats}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto"
              >
                {boats.map(({ name, id }, index) => (
                  <Tab
                    key={`boat-${id}`}
                    label={name}
                    value={id}
                    {...a11yProps(index)}
                  />
                ))}
              </Tabs>
            </AppBar>
          </Grid>
          <Grid item className={classes.buttonComponent}>
            <Button
              variant="contained"
              className={classes.button}
              onClick={handleReset}
            >
              {t('marineContractors.buttons.relaunch')}
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleDownload}
            >
              {t('marineContractors.buttons.exportAll')}
            </Button>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item md={2} lg={1}>
            <TabPanel>
              <Tabs
                orientation="vertical"
                variant="scrollable"
                value={point.id}
                onChange={handleChangePoints}
                className={classes.tabs}
              >
                {points.map(({ name, id }, pIndex) => (
                  <Tab
                    key={`point-${id}`}
                    label={name}
                    value={id}
                    className={classes.pointTab}
                    {...a11yPropsVert(pIndex)}
                  />
                ))}
              </Tabs>
            </TabPanel>
          </Grid>
          <Grid item md={10} lg={11}>
            <Grid
              container
              justifyContent="center"
              className={classes.operationsDashboard}
            >
              {operations.map(({ data, name, id, format }) => (
                <Grid key={`operation-${id}`} item>
                  <Typography variant="h4" className={classes.operationTitle}>
                    {name}{' '}
                    <Typography>{`${boat.name} -> ${point.name}`}</Typography>
                  </Typography>
                  <MCWeatherGraph
                    data={data}
                    percentiles={percentiles}
                    format={format}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

MCStatisticsComponent.propTypes = {
  boats: PropTypes.array.isRequired,
  points: PropTypes.array.isRequired,
  operations: PropTypes.array.isRequired,
  boat: PropTypes.object.isRequired,
  point: PropTypes.object.isRequired,
  percentiles: PropTypes.arrayOf(PropTypes.string).isRequired,
  handleReset: PropTypes.func.isRequired,
  handleChangeBoats: PropTypes.func.isRequired,
  handleChangePoints: PropTypes.func.isRequired,
  handleDownload: PropTypes.func.isRequired,
};

export default React.memo(MCStatisticsComponent);
