import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(({ palette }) => ({
  children: {
    width: '100%',
  },
  addButtonIcon: {
    color: palette.secondary.main,
    transform: 'scale(1.1)',
    marginLeft: '10px',
    '&:disabled': {
      background: palette.grey.middle,
    },
  },
  addButtonText: {
    color: palette.secondary.main,
  },
  validStep: {
    marginTop: '30px',
  },
  gridCenter: {
    textAlign: 'center',
  },
  mainContainer: {
    padding: 20,
  },
  titleBox: {
    backgroundColor: palette.primary.main,
    color: palette.white.main,
    textAlign: 'center',
  },
  contentBox: {
    backgroundColor: palette.white.main,
    textAlign: 'center',
    border: '2px solid black',
    height: 500,
    overflowY: 'auto',
  },
  gridItem: {
    '&:hover': {
      backgroundColor: palette.lightGrey.light,
    },
    cursor: 'pointer',
  },
  gridItemSelected: {
    backgroundColor: palette.lightGrey.light,
    // trick to avoid little sparkling on operation selected when selecting a new boat
    transition: 'background-color 0.5s',
  },
  noContent: {
    fontStyle: 'italic',
    overflowWrap: 'anywhere',
  },
}));
