import { createSelector } from 'reselect';
import get from 'lodash/get';
import findIndex from 'lodash/findIndex';

import {
  EMPTY_ARRAY,
  EMPTY_VALUE,
  STATUS_LOADING,
  STATUS_ERROR,
  STATUS_SUCCESS,
  STATUS_NOT_REQUESTED,
} from 'constants/common';
import {
  CREDIT_NEEDED_LIGHT_TYPE,
  CREDIT_NEEDED_OTHER_TYPE,
  FINISHED_STATUS,
  RUNNING_STATUS,
  TYPE_LIGHT,
  TYPE_TRADITIONAL,
} from 'constants/projects';
import { selectPointsData } from 'ducks/points/selectors';
import { mergePointsWithProject } from 'helpers/entities';
import { prepareVisualizationData } from 'helpers/statistics';
import { HIDDEN_GRAPH_TYPES } from 'constants/graphsConfig';
import { selectZonesData } from 'ducks/zones/selectors';

export const selectProjectsError = ({ projects }) =>
  projects.error || EMPTY_VALUE;

export const selectProjectsCommonErrors = createSelector(
  selectProjectsError,
  (errors) => errors.common || EMPTY_ARRAY
);

export const selectProjectsIds = ({ projects }) => projects.ids;

export const selectProjectsData = ({ projects }) => projects.data;

export const selectProjectById = createSelector(
  [(_, projectId) => projectId, selectProjectsData],
  (projectId, projectsData) => projectsData[projectId] || EMPTY_VALUE
);

export const selectProjectNameById = createSelector(
  selectProjectById,
  ({ name }) => name
);

export const selectProjectStatusById = createSelector(
  selectProjectById,
  ({ status }) => status && status.toLowerCase()
);

export const selectIsTraditionalProjectById = createSelector(
  selectProjectById,
  ({ type }) => type === TYPE_TRADITIONAL
);

export const selectIsRunningProjectById = createSelector(
  selectProjectStatusById,
  (status) => status === RUNNING_STATUS
);

export const selectIsFinishedProjectById = createSelector(
  selectProjectStatusById,
  (status) => status === FINISHED_STATUS
);

export const selectProjectCreditPerPointById = createSelector(
  selectProjectById,
  ({ type }) =>
    type === TYPE_LIGHT ? CREDIT_NEEDED_LIGHT_TYPE : CREDIT_NEEDED_OTHER_TYPE
);

export const selectProjectByIdWithPoints = createSelector(
  selectProjectsData,
  selectPointsData,
  (_, projectId) => projectId,
  (projectsData, pointsData, projectId) =>
    projectsData[projectId]
      ? mergePointsWithProject(projectsData[projectId], pointsData[projectId])
      : EMPTY_VALUE
);

/**
 * Credits count selected. For each computed point get add credits by project type.
 * @return {Number}
 */
export const selectProjectAvailableCreditsPointById = createSelector(
  selectProjectByIdWithPoints,
  selectProjectCreditPerPointById,
  ({ points }, creditsPerPoint) =>
    points.reduce(
      (result, point) => result + (!point.isComputed ? creditsPerPoint : 0),
      0
    )
);

export const selectProjectsIsLoading = ({ projects }) =>
  projects.status === STATUS_LOADING;

export const selectProjectsIsStatusNotRequested = ({ projects }) =>
  projects.status === STATUS_NOT_REQUESTED;

export const selectProjectsIsError = ({ projects }) =>
  projects.status === STATUS_ERROR;

export const selectProjectsIsSuccess = ({ projects }) =>
  projects.status === STATUS_SUCCESS;

export const selectProjectsShouldShowLoader = ({ projects }) =>
  [STATUS_NOT_REQUESTED, STATUS_LOADING, STATUS_ERROR].includes(
    projects.status
  );

export const selectProjectsCollection = createSelector(
  [selectProjectsIds, selectProjectsData],
  (projectsIds, projectsData) => projectsIds.map((id) => projectsData[id])
);

export const selectProjectsCollectionWithPoints = createSelector(
  selectProjectsIds,
  selectProjectsData,
  selectPointsData,
  (projectsIds, projectsData, pointsData) =>
    projectsIds.map((id) =>
      mergePointsWithProject(projectsData[id], pointsData[id])
    )
);

export const selectProjectStatsById = createSelector(
  selectProjectsData,
  (_, projectId) => projectId,
  (projectsData, projectId) =>
    !projectsData[projectId] || !projectsData[projectId].stats
      ? EMPTY_VALUE
      : projectsData[projectId].stats
);

export const selectProjectOverviewStatsIdsById = createSelector(
  selectProjectById,
  (project) => get(project, ['overviewStats', 'ids'], EMPTY_ARRAY)
);

export const selectProjectOverviewStatsDataById = createSelector(
  selectProjectById,
  (project) => get(project, ['overviewStats', 'data'], EMPTY_ARRAY)
);

export const selectProjectOverviewStatsCollectionById = createSelector(
  selectProjectOverviewStatsIdsById,
  selectProjectOverviewStatsDataById,
  (ids, data) => ids.map((id) => data[id])
);

export const selectProjectVisualizationDataById = createSelector(
  selectProjectById,
  selectProjectOverviewStatsDataById,
  (_, __, pointId) => pointId,
  (project, overviewStatsData, pointId) =>
    (project.visualizationData || EMPTY_ARRAY)
      .filter(({ point }) => +point.id === pointId)
      .map((visualization) => {
        const {
          variables: { names },
        } = overviewStatsData[visualization.id];

        return prepareVisualizationData(visualization, names[0]);
      })
);

export const selectProjectStatsFields = (state, projectId, pointId) => {
  const projectStats = selectProjectStatsById(state, projectId);
  const fieldsCollection = get(projectStats, [pointId], {});
  return Object.keys(fieldsCollection).map((layer) => ({
    id: layer,
    name: layer,
  }));
};

/**
 * TODO remove HIDDEN types filter, when all types are ready, or rm them on server if don't need them anymore
 */
export const selectProjectStatsTypes = (
  state,
  projectId,
  pointId,
  selectedField
) => {
  const projectStats = selectProjectStatsById(state, projectId);
  const typesCollection = get(projectStats, [pointId, selectedField], {});
  return Object.values(typesCollection).filter(
    ({ id }) => !HIDDEN_GRAPH_TYPES.includes(id)
  );
};

/**
 * Selects variables by names
 * from [{ name: "wind speed", id: "WIN-magw-EPD_2147"},
 *       { name: "wind speed", id: "WIN-magw-EPD-month_2147"},
 *       { name: "wind direction", id: "WIN-magw-EPD_2147"}]
 * to [{ name: "wind speed", id: "WIN-magw-EPD_2147, WIN-magw-EPD-month_2147"},
 *     { name: "wind direction", id: "WIN-magw-EPD_2147"}]
 */
export const selectProjectStatsVariablesByNames = (
  state,
  projectId,
  pointId,
  selectedField,
  selectedType
) => {
  const projectStats = selectProjectStatsById(state, projectId);
  return get(
    projectStats,
    [pointId, selectedField, selectedType, 'variables'],
    []
  ).reduce((acc, { name, id }) => {
    const duplicateIndex = findIndex(acc, ['name', name]);
    if (duplicateIndex !== -1) {
      acc[duplicateIndex] = { name, id: [id, ...acc[duplicateIndex].id] };
    } else {
      acc = [...acc, { name, id: [id] }];
    }
    return acc;
  }, []);
};

export const selectProjectPointStatsVariablesShortNames = (
  state,
  projectId,
  pointId,
  selectedField,
  selectedType
) => {
  const projectStats = selectProjectStatsById(state, projectId);
  return get(
    projectStats,
    [pointId, selectedField, selectedType, 'variables'],
    []
  ).reduce((acc, { shortNames, id }) => {
    const duplicateIndex = findIndex(acc, ['shortNames', shortNames]);
    if (duplicateIndex !== -1) {
      acc[duplicateIndex] = {
        name: shortNames,
        id: [id, ...acc[duplicateIndex].id],
      };
    } else {
      acc = [...acc, { name: shortNames, id: [id] }];
    }
    return acc;
  }, []);
};

export const selectProjectStatsIsLoading = (state, projectId) => {
  const { status } = selectProjectStatsById(state, projectId);
  return status === STATUS_LOADING;
};

export const selectProjectsIdsByCollaboratorId = createSelector(
  selectProjectsCollection,
  (_, collaboratorId) => collaboratorId,
  (projectsCollection, collaboratorId) =>
    projectsCollection.reduce((acc, project) => {
      if (project.collaborators.some(({ id }) => id === collaboratorId)) {
        acc = [...acc, project.id];
      }
      return acc;
    }, [])
);

export const selectLastComputationByProjectId = createSelector(
  selectProjectById,
  ({ computations }) => computations[0]
);

/**
 * selects zone info from project data (it contains basic short info)
 */
export const selectProjectZoneByProjectId = createSelector(
  selectProjectById,
  ({ zone }) => zone || EMPTY_VALUE
);

/**
 * selects full zone info from zones data (full info about zone)
 */
export const selectFullZoneInfoByProjectId = createSelector(
  [selectProjectById, selectZonesData],
  ({ zone }, zones) => (zone ? zones[zone.id] ?? EMPTY_VALUE : EMPTY_VALUE)
);

export const selectProjectNames = createSelector(
  selectProjectsCollection,
  (projects) => projects.map(({ name }) => name)
);

export const selectProjectOfferInstanceByProjectId = createSelector(
  selectProjectById,
  ({ offerInstance }) => offerInstance || EMPTY_VALUE
);

export const selectGraphDownloadStatuses = ({ projects }) =>
  projects.downloadStatuses;
