import Modal from '@material-ui/core/Modal';
import Fade from '@material-ui/core/Fade';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { EMPTY_FUNCTION, TRANSITION_TIMEOUT } from 'constants/common';

import { useStyles } from './styles';

/**
 * Header for base modal. If there's no title, renders just close Icon, without header block
 * @param title
 * @param onClose
 * @param classes
 * @returns { JSX }
 */
const BaseModalHeader = ({ title, onClose, classes }) =>
  title ? (
    <div className={classes.modalHeader}>
      <div className={classes.title}>{title}</div>
      <IconButton onClick={onClose} className="modal_close-button">
        <CloseIcon />
      </IconButton>
    </div>
  ) : (
    <IconButton
      onClick={onClose}
      className={classNames('modal_close-button', classes.noHeaderCloseIcon)}
    >
      <CloseIcon />
    </IconButton>
  );

/**
 * Base modal component to compose custom modals
 * takes controls component - buttons, etc..
 * takes custom modal class to override default styles
 * contains some static class names to allow overriding from given modalClass
 * @param {String} title
 * @param {React.ReactNode} children
 * @param {React.ReactNode} controls
 * @param {Function} onClose
 * @param {String} modalClass
 * @returns {jsx}
 */
const BaseModal = ({ title, children, controls, onClose, modalClass }) => {
  const classes = useStyles();

  return (
    <Modal
      open
      onClose={onClose}
      BackdropProps={{ timeout: TRANSITION_TIMEOUT }}
      className={classes.modalStyle}
    >
      <Fade timeout={TRANSITION_TIMEOUT} in>
        <div className={classNames(classes.modalContent, modalClass)}>
          <BaseModalHeader title={title} onClose={onClose} classes={classes} />
          <div className={classes.modalBody}>{children}</div>
          {controls && <div className={classes.controls}>{controls}</div>}
        </div>
      </Fade>
    </Modal>
  );
};

BaseModal.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node.isRequired,
  controls: PropTypes.node,
  onClose: PropTypes.func,
  modalClass: PropTypes.string,
};

BaseModal.defaultProps = {
  title: '',
  controls: null,
  onClose: EMPTY_FUNCTION,
};

export default BaseModal;
