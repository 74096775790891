import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(({ palette }) => ({
  container: {
    width: 500,
  },
  buttonsContainer: {
    marginTop: '30px',
  },
  buttonSafe: {
    backgroundColor: palette.secondary.main,
    color: palette.white.main,
    '&:hover': {
      backgroundColor: palette.secondary.main,
    },
  },
}));
