import AccountCircle from '@material-ui/icons/AccountCircle';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ExitToAppRoundedIcon from '@material-ui/icons/ExitToAppRounded';
import AssessmentIcon from '@material-ui/icons/Assessment';
import PersonIcon from '@material-ui/icons/Person';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import React, { useCallback, memo } from 'react';
import FilterNoneIcon from '@material-ui/icons/FilterNone';

import { ROUTES } from 'constants/routes';

import { useStyles } from './styles';

const menuId = 'user-bar_menu';

/**
 * User bar component. Represents menu with profile and logout links
 * @param {String}wrapperClass
 * @param {function} logout
 */
const UserBarComponent = ({ user, wrapperClass, logout }) => {
  const classes = useStyles();
  const history = useHistory();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const isMenuOpen = !!anchorEl;

  const handleProfileMenuOpen = useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleMenuClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const logoutOnClick = useCallback(() => {
    handleMenuClose();
    logout();
  }, [handleMenuClose, logout]);

  const profileOnClick = useCallback(() => {
    handleMenuClose();
    history.push(ROUTES.profile);
  }, [history, handleMenuClose]);

  const plansOnClick = useCallback(() => {
    handleMenuClose();
    history.push(ROUTES.plans);
  }, [history, handleMenuClose]);

  const usageOnClick = useCallback(() => {
    handleMenuClose();
    history.push(ROUTES.usage);
  }, [history, handleMenuClose]);

  return (
    <>
      <div className={wrapperClass || ''}>
        <IconButton
          size="small"
          edge="end"
          aria-label="account of current user"
          aria-controls={menuId}
          aria-haspopup="true"
          onClick={handleProfileMenuOpen}
          color="inherit"
          className={classes.button}
        >
          <AccountCircle fontSize="large" className={classes.avatarIcon} />
          &ensp;
          <span className={classes.smallText}>{user.username}</span>
          &ensp;
          <ArrowDropDownIcon className={classes.avatarIcon} />
        </IconButton>
      </div>
      <Menu
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        id={menuId}
        open={isMenuOpen}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={plansOnClick} className={classes.smallText}>
          <FilterNoneIcon fontSize="small" />
          &nbsp;My plans
        </MenuItem>
        <MenuItem onClick={profileOnClick} className={classes.smallText}>
          <PersonIcon fontSize="small" />
          &nbsp;My profile
        </MenuItem>
        {user.isSuperuser && (
          <MenuItem onClick={usageOnClick} className={classes.smallText}>
            <AssessmentIcon fontSize="small" />
            &nbsp;Usage analyze
          </MenuItem>
        )}
        <MenuItem onClick={logoutOnClick} className={classes.smallText}>
          <ExitToAppRoundedIcon fontSize="small" />
          &nbsp;Log out
        </MenuItem>
      </Menu>
    </>
  );
};

export const UserBar = memo(UserBarComponent);

UserBarComponent.propTypes = {
  user: PropTypes.object.isRequired,
  wrapperClass: PropTypes.string,
  logout: PropTypes.func.isRequired,
};

UserBarComponent.defaultProps = {
  wrapperClass: '',
};
