import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';

import ConfirmationModal from 'components/common/ConfirmationModal';

import { useStyles } from './styles';

/**
 * Displays modal for editing other profile
 * @param { Object } user - user information
 * @param { function } closeModal - handler for close button modal
 * @param { function } removeProfile - handler for remove profile
 */
const RemoveOtherProfileModal = ({ user, closeModal, removeProfile }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { username } = user;

  return (
    <ConfirmationModal
      title={t('collaborators.removeTeamMember')}
      onClose={closeModal}
      onCancel={closeModal}
      onConfirm={removeProfile}
    >
      <div className={classes.body}>
        <Typography gutterBottom variant="body1">
          <strong>{`${username}`}</strong>&nbsp;
        </Typography>
        <Typography gutterBottom variant="body1">
          {t('collaborators.removeText')}
        </Typography>
      </div>
    </ConfirmationModal>
  );
};

RemoveOtherProfileModal.propTypes = {
  user: PropTypes.object.isRequired,
  closeModal: PropTypes.func.isRequired,
  removeProfile: PropTypes.func.isRequired,
};

export default memo(RemoveOtherProfileModal);
