import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  modalContent: {
    width: 400,
    minHeight: 150,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 6,
    '&:focus': {
      outline: 'none',
    },
    margin: '5% auto',
    position: 'relative',
    maxHeight: '90vh',
  },
  modalHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    '& button': {
      padding: 0,
    },
    padding: 15,
    borderBottom: `1px solid ${theme.palette.lightGrey.main}`,
    alignItems: 'center',
  },
  noHeaderCloseIcon: {
    position: 'absolute',
    top: 2,
    right: 2,
    zIndex: 1,
  },
  modalBody: {
    padding: 15,
    overflowY: 'auto',
  },
  controls: {
    padding: 15,
    borderTop: `1px solid ${theme.palette.lightGrey.main}`,
  },
  title: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexGrow: 2,
    fontWeight: 'bold',
    fontSize: 20,
  },
  modalStyle: {
    overflowY: 'hidden',
  },
}));
