import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  header: {
    padding: 15,
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: theme.palette.primary.main,
    color: 'white',
  },
  container: {
    display: 'grid',
  },
  title: {
    fontSize: 16,
    fontWeight: 'bold',
  },
  subtitle: {
    padding: 5,
    color: theme.palette.lightGrey.main,
    fontSize: 12,
  },
  icon: {
    color: theme.palette.sinay.primary,
    padding: 4,
    background: '#D9D9D9',
    borderRadius: '50%',
    '&:hover': {
      background: '#D9D9D9',
    },
  },
  colorLabel: {
    height: 6,
    backgroundColor: theme.palette.blue.light,
    '&$offerType': {
      backgroundColor: theme.palette.blue.main,
    },
    '&$lightType': {
      backgroundColor: theme.palette.blue.middle,
    },
    '&$demoType': {
      backgroundColor: theme.palette.lightGreen.main,
    },
  },
  cardMap: {
    pointerEvents: 'none',
    width: '100%',
    outline: 'none',
    height: 220,
  },
  offerType: {},
  lightType: {},
  demoType: {},
}));
