import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';

/**
 * UploadButton - button for upload files
 * @param {React.ReactNode} children
 * @param {Function} onUpload
 * @param {Boolean} multiple
 * @param {String} accept
 * @param {boolean} disabled
 * @param {Object} props
 * @returns {jsx}
 */
const UploadButton = ({
  children,
  onUpload,
  multiple = false,
  accept = '*',
  disabled = false,
  inputType = 'file',
  ...props
}) => {
  const uploadFileHandler = useCallback(
    ({ target }) => {
      if (target.files.length) {
        onUpload(multiple ? target.files : target.files[0]);
      }

      target.value = '';
    },
    [onUpload, multiple]
  );

  return (
    <Button
      variant="outlined"
      color="secondary"
      component="label"
      disabled={disabled}
      {...props}
    >
      {children}
      <input
        type={inputType}
        onChange={uploadFileHandler}
        style={{ display: 'none' }}
        multiple={multiple}
        accept={accept}
      />
    </Button>
  );
};

UploadButton.propTypes = {
  children: PropTypes.node.isRequired,
  onUpload: PropTypes.func.isRequired,
  multiple: PropTypes.bool,
  accept: PropTypes.string,
  disabled: PropTypes.bool,
  inputType: PropTypes.string,
};

export default memo(UploadButton);
