import React, { memo, useState, useCallback } from 'react';
import ArrowBackIos from '@material-ui/icons/ArrowBackIos';

import { EMPTY_VALUE } from 'constants/common';
import { SUPPORT_EMAIL } from 'common/config';
import NativeLink from 'components/common/NativeLink';

import { useStyles } from './styles';
import data from './metoc_faq.json';

/**
 * FaqContainer handle switch between faqPage & faqDetails
 */
const FaqContainer = () => {
  const [isFaqHomePage, setIsFaqHomePage] = useState(true);
  const [content, setContent] = useState(EMPTY_VALUE);
  const handleQuestionClick = useCallback(
    (section, question) => {
      setContent({
        section,
        question: question.name,
        content: question.content,
      });
      setIsFaqHomePage(false);
    },
    [setContent, setIsFaqHomePage]
  );
  const handleBackFaqClick = useCallback(() => {
    setContent(EMPTY_VALUE);
    setIsFaqHomePage(true);
  }, [setContent, setIsFaqHomePage]);

  if (isFaqHomePage) {
    return <FaqPage handleClick={handleQuestionClick} />;
  }
  return (
    <>
      <FaqPage handleClick={handleQuestionClick} />;
      <FaqDetailsPage content={content} handleClick={handleBackFaqClick} />;
    </>
  );
};

/**
 * FaqPage component
 * @param handleClick - click user event
 */
const FaqPage = ({ handleClick }) => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.header}>
        <h1 className={classes.title}>Frequently Asked Questions</h1>
      </div>

      <div className={classes.container}>
        {data.map(
          (section) =>
            section.display && (
              <div key={section.name} className={classes.subContainer}>
                <div className={classes.item}>
                  <h3 className={classes.subTitle}>{section.name}</h3>

                  <div className={classes.list}>
                    {section.questions.map(
                      (question) =>
                        question.display && (
                          <div
                            role="button"
                            tabIndex={0}
                            key={question.name}
                            className={classes.link}
                            onClick={() => handleClick(section.name, question)}
                          >
                            {question.name}
                          </div>
                        )
                    )}
                  </div>
                </div>
              </div>
            )
        )}

        <div className={classes.footer}>
          <h3 className={classes.subTitle}>Additional questions?</h3>

          <p>
            Please contact us at{' '}
            <NativeLink to={`mailto:${SUPPORT_EMAIL}`}>
              {SUPPORT_EMAIL}
            </NativeLink>
          </p>
        </div>
      </div>
    </>
  );
};

/**
 * FaqDetailPage component
 * @param content - faq content
 * @param handleClick - click user event
 */
const FaqDetailsPage = ({ content, handleClick }) => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.popupContainer}>
        <div className={classes.detailsContainer}>
          <button
            type="button"
            className={classes.button}
            onClick={handleClick}
          >
            <ArrowBackIos /> BACK TO FAQ
          </button>

          <h3 className={classes.section}>{content.section}</h3>

          <h1 className={classes.title}>{content.question}</h1>

          <div
            className={classes.content}
            dangerouslySetInnerHTML={{ __html: content.content }}
          />
        </div>
      </div>
      <div
        role="button"
        tabIndex={0}
        className={classes.overlay}
        onClick={handleClick}
      ></div>
    </>
  );
};

export default memo(FaqContainer);
