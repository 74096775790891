import { makeStyles } from '@material-ui/core/styles';
const buttonsBlockHeight = 178;
const tabsHeight = 75;
const collapseHeaderHeight = 48;
const sideBarPadding = 90;

export const useStyles = makeStyles(
  ({ navbarHeight, projectMenuHeight, palette, defaultTransition }) => ({
    container: {
      maxHeight: `calc(100vh - ${buttonsBlockHeight +
        tabsHeight +
        navbarHeight +
        projectMenuHeight +
        collapseHeaderHeight +
        sideBarPadding}px)`,
      overflowY: 'auto',
    },
    pointItem: {
      cursor: 'pointer',
      transition: defaultTransition,
      padding: 16,
      '&$pointItemSelected': {
        background: palette.blue.light,
      },

      '&:hover': {
        background: palette.grey.main,
      },
    },
    itemName: {
      fontSize: 16,
    },
    itemCoordinate: {
      color: palette.grey.middle,
      fontSize: 14,
    },
    pointIcon: {
      height: 38,
    },
    iconImage: {
      paddingRight: 10,
      height: '100%',
    },
    pointItemSelected: {},
  })
);
