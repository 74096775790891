import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import {
  AREA_GROUP_NAME,
  DEFAULT_CENTERING_MAP_DELAY,
  EDIT_POINT_Y_OFFSET,
} from 'constants/map';
import { MapFeatureGroup, MapPolygon } from 'components/leaflet';
import ProjectMarker from 'components/maps/ProjectMarker';
import CreatePointPopup from 'components/popups/CreatePointPopup';
import { centeringMapByLatLng } from 'helpers/map';

import { useStyles } from './styles';

/**
 * NewPointFeatureGroup - Feature group with zone and new marker logic
 * @param {Object} project
 * @param {Boolean} canCreatePoint
 * @returns {jsx}
 */
const NewPointFeatureGroup = ({ project, canCreatePoint }) => {
  const classes = useStyles();
  const [newPoint, setNewPoint] = useState(null);

  const setNewPointCoordinate = useCallback(
    ({ latlng, layer: { _map: map } }) => {
      setTimeout(centeringMapByLatLng, DEFAULT_CENTERING_MAP_DELAY, {
        map,
        latLng: latlng,
        offset: { y: EDIT_POINT_Y_OFFSET },
      });

      setNewPoint(latlng);
    },
    [setNewPoint]
  );

  const clearNewPointCoordinate = useCallback(() => setNewPoint(null), [
    setNewPoint,
  ]);

  return (
    <MapFeatureGroup
      onClick={setNewPointCoordinate}
      name={AREA_GROUP_NAME}
      withControls
    >
      {newPoint && (
        <ProjectMarker lat={newPoint.lat} lng={newPoint.lng}>
          <CreatePointPopup
            lat={newPoint.lat}
            lng={newPoint.lng}
            onClose={clearNewPointCoordinate}
            project={project}
            canCreatePoint={canCreatePoint}
          />
        </ProjectMarker>
      )}
      <MapPolygon
        geometry={project.zone.geom}
        color="blue"
        fillOpacity={0}
        className={classes.addPointCursor}
      />
    </MapFeatureGroup>
  );
};

NewPointFeatureGroup.propTypes = {
  project: PropTypes.shape({
    zone: PropTypes.object.isRequired,
  }).isRequired,
  canCreatePoint: PropTypes.bool.isRequired,
};

export default React.memo(NewPointFeatureGroup);
