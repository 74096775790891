import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Typography,
} from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';

import { getItemNameByAttributes } from 'helpers/marineContractors';
import { CONSTRAINTS_ATTRIBUTES } from 'constants/marineContractors';

import { useStyles } from './styles';

/**
 * Simple component to display non field errors
 * @param { string } name
 * @param { array } errors
 * @returns { JSX }
 */
export const NonFieldErrorsComponent = ({ errors }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return errors && !!errors.length ? (
    <List>
      {errors.map((errorMessage) => (
        <ListItem>
          <ListItemIcon>
            <ErrorIcon color="error" />
          </ListItemIcon>
          <ListItemText className={classes.errorMessage}>
            {t([
              `marineContractors.modals.common.${errorMessage}`,
              'marineContractors.modals.common.error',
            ])}
          </ListItemText>
        </ListItem>
      ))}
    </List>
  ) : (
    <></>
  );
};

/**
 * Simple component to warn about children deletion
 * @param { Array } items
 * @param { string } objectGroup - boats, operations or constraints
 * @returns { JSX }
 */
export const DeletionComponent = ({ items, objectGroup }) => {
  const { t } = useTranslation();
  return items && items.length ? (
    <>
      <Typography>
        {t(`marineContractors.fields.${objectGroup}.deleteUnsafe`)}
      </Typography>
      <List>
        {items.map((item) => (
          <ListItem key={item.id}>
            <ListItemIcon>
              <PlayArrowIcon />
            </ListItemIcon>
            <ListItemText>
              {item.name
                ? item.name
                : getItemNameByAttributes(item, CONSTRAINTS_ATTRIBUTES)}
            </ListItemText>
          </ListItem>
        ))}
      </List>
    </>
  ) : (
    <Typography>
      {t(`marineContractors.fields.${objectGroup}.deleteSafe`)}
    </Typography>
  );
};
