import { makeStyles } from '@material-ui/core/styles';
import { alpha } from '@material-ui/core/styles/colorManipulator';

import messageIcon from 'assets/images/project/point_cursor.png';

export const useStyles = makeStyles(({ palette }) => ({
  pointsButtonGroup: {
    padding: 15,
  },
  pointsButton: {
    marginBottom: 10,

    '&:last-child': {
      marginBottom: 0,
    },
  },
  cancelComputationButton: {
    color: palette.white.main,
    backgroundColor: palette.danger.middle,

    '&:hover': {
      backgroundColor: palette.danger.main,
      borderColor: palette.danger.main,
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: palette.danger.main,
      borderColor: palette.danger.main,
    },
    '&:focus': {
      boxShadow: 'none',
    },
  },
  messageNoPoints: {
    marginBottom: 2,
    paddingTop: 85,
    textTransform: 'uppercase',
    textAlign: 'center',
    fontSize: 13,
    lineHeight: 1.4,
    color: alpha(palette.primary.main, 0.5),
    background: `url(${messageIcon}) 50% 20px no-repeat`,
    backgroundSize: '65px 50px',
  },
  messageNoPointsBig: {
    fontWeight: 600,
  },
  checkbox: {
    justifyContent: 'center',
  },
  content: {
    padding: '16px 4px 0px 4px',
    textAlign: 'center'
  }
}));
