import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(({ palette, defaultTransition }) => ({
  pointItem: {
    cursor: 'pointer',
    border: `1px solid ${palette.lightGrey.main}`,
    borderTop: 0,
    transition: defaultTransition,
    padding: 10,
    '&$pointItemSelected': {
      // background: palette.green.light,
      background: 'rgba(16,65,108,0.4)',
    },
    '&:hover': {
      background: palette.grey.main,
    },
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  itemName: {
    fontSize: 16,
    maxWidth: 200,
  },
  pointItemSelected: {},
  link: {
    color: palette.grey.light,
    '&:hover': {
      color: palette.white.main,
    },
  },
}));
