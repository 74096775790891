import {
  takeLatest,
  put,
  select,
  call,
  takeEvery,
  takeLeading,
  race,
  delay,
  take,
} from 'redux-saga/effects';
import i18next from 'i18next';

import {
  createMarineContractorsBoatRequest,
  createMarineContractorsOperationRequest,
  deleteMarineContractorsBoatRequest,
  deleteMarineContractorsOperationsRequest,
  getMarineContractors,
  getMarineContractorsJob,
  getMarineContractorsLatestJob,
  updateMarineContractorsBoatRequest,
  updateMarineContractorsJobOperations,
  updateMarineContractorsOperationsRequest,
  createMarineConstractorsConstraintsRequest,
  updateMarineConstractorsConstraintsRequest,
  deleteMarineContractorsConstraintsRequest,
  getMarineContractorsVariables,
  getMarineContractorsCustomer,
  updateMarineConstractorsCustomerRequest,
  getMarineContractorsResults,
  downloadResults,
} from 'services/marineContractors';
import { closeModal, showModal } from 'ducks/modals/actions';
import { STEPS } from 'constants/marineContractors';
import { NOTIFICATION_MODAL } from 'constants/modals';
import { XLSX_FILE_TYPE } from 'constants/common';
import {
  SUPPORT_EMAIL,
  UPDATE_MARINE_CONTRACTORS_TIMEOUT,
} from 'common/config';
import { triggerDownloadBlob } from 'helpers/dom';
import { selectProjectNameById } from 'ducks/projects/selectors';

import {
  CREATE_MARINE_CONTRACTORS_BOAT,
  CREATE_MARINE_CONTRACTORS_OPERATION,
  CREATE_MARINE_CONTRACTORS_CONSTRAINT,
  DELETE_MARINE_CONTRACTORS_BOAT,
  DELETE_MARINE_CONTRACTORS_OPERATION,
  REQUEST_MARINE_CONTRACTORS,
  REQUEST_MARINE_CONTRACTORS_JOB,
  REQUEST_MARINE_CONTRACTORS_CUSTOMER,
  SAVE_MARINE_CONTRACTORS_JOB_OPERATIONS,
  SAVE_MARINE_CONTRACTORS_JOB_POINTS,
  UPDATE_MARINE_CONTRACTORS_BOAT,
  UPDATE_MARINE_CONTRACTORS_OPERATION,
  UPDATE_MARINE_CONTRACTORS_STEP,
  UPDATE_MARINE_CONTRACTORS_CUSTOMER,
  UPDATE_MARINE_CONTRACTORS_CONSTRAINT,
  DELETE_MARINE_CONTRACTORS_CONSTRAINT,
  REQUEST_MARINE_CONTRACTORS_VARIABLES,
  UPDATE_MARINE_CONTRACTORS_CUSTOMER_PERCENTILES,
  SAVE_MARINE_CONTRACTORS_JOB_OUTPUTS,
  STOP_UPDATE_MARINE_CONTRACTORS_JOB,
  START_UPDATE_MARINE_CONTRACTORS_JOB,
  REQUEST_MARINE_CONTRACTORS_RESULTS,
  DOWNLOAD_RESULTS,
} from './types';
import {
  requestMarineContractorsSuccess,
  requestMarineContractorsError,
  requestMarineContractorsJobSuccess,
  requestMarineContractorsJobError,
  createMarineContractorsBoatSuccess,
  createMarineContractorsBoatError,
  updateMarineContractorsBoatSuccess,
  updateMarineContractorsBoatError,
  deleteMarineContractorsBoatSuccess,
  deleteMarineContractorsBoatError,
  createMarineContractorsOperationSuccess,
  createMarineContractorsOperationError,
  updateMarineContractorsOperationSuccess,
  updateMarineContractorsOperationError,
  deleteMarineContractorsOperationSuccess,
  deleteMarineContractorsOperationError,
  createMarineContractorsConstraintSuccess,
  createMarineContractorsConstraintError,
  updateMarineContractorsConstraintSuccess,
  updateMarineContractorsConstraintError,
  deleteMarineContractorsConstraintSuccess,
  deleteMarineContractorsConstraintError,
  requestMarineContractorsVariablesSuccess,
  requestMarineContractorsVariablesError,
  requestMarineContractorsCustomerSuccess,
  requestMarineContractorsCustomerError,
  updateMarineContractorsCustomerPercentilesError,
  finishUpdateMarineContractorsJob,
  requestMarineContractorsResultsSuccess,
  requestMarineContractorsResultsError,
  downloadResultsError,
} from './actions';

export function* requestMarineContractorsWorker() {
  try {
    const boats = yield call(getMarineContractors);
    yield put(requestMarineContractorsSuccess(boats));
  } catch (error) {
    yield put(requestMarineContractorsError(error));
  }
}
export function* requestMarineContractorsVariablesWorker() {
  try {
    const variables = yield call(getMarineContractorsVariables);
    yield put(requestMarineContractorsVariablesSuccess(variables));
  } catch (error) {
    yield put(requestMarineContractorsVariablesError(error));
  }
}
export function* requestMarineContractorsJobWorker({ projectId }) {
  try {
    const job = yield call(getMarineContractorsLatestJob, projectId);
    yield put(requestMarineContractorsJobSuccess(job));
  } catch (error) {
    yield put(requestMarineContractorsJobError(error));
  }
}
export function* requestMarineContractorsCustomerWorker({ customerId }) {
  try {
    const customer = yield call(getMarineContractorsCustomer, customerId);
    yield put(requestMarineContractorsCustomerSuccess(customer));
  } catch (error) {
    yield put(requestMarineContractorsCustomerError(error));
  }
}
export function* requestMarineContractorsResultsWorker({ job }) {
  try {
    const results = yield call(getMarineContractorsResults, job);
    yield put(requestMarineContractorsResultsSuccess(results));
  } catch (error) {
    yield put(requestMarineContractorsResultsError(error));
  }
}

export function* saveMarineContractorsJobOperations({
  job,
  operations,
  projectId,
  step = STEPS.LOCATIONS,
}) {
  try {
    const parameters = {
      ...job.parameters,
      operations,
    };
    const jobUpdated = yield call(
      updateMarineContractorsJobOperations,
      job.id,
      { project: projectId, parameters, step }
    );
    yield put(requestMarineContractorsJobSuccess(jobUpdated));
  } catch (error) {
    const errMessage = error.message?.message;
    yield put(
      showModal({
        modalType: NOTIFICATION_MODAL,
        options: {
          message:
            errMessage ||
            i18next.t('marineContractors.operations.errors.next', {
              email: SUPPORT_EMAIL,
            }),
          type: 'error',
        },
      })
    );
    yield put(requestMarineContractorsJobError(error));
  }
}
export function* saveMarineContractorsJobPoints({
  job,
  points,
  projectId,
  step = STEPS.OUTPUTS,
}) {
  try {
    const parameters = {
      ...job.parameters,
      points,
    };
    const jobUpdated = yield call(
      updateMarineContractorsJobOperations,
      job.id,
      { project: projectId, parameters, step }
    );
    yield put(requestMarineContractorsJobSuccess(jobUpdated));
  } catch (error) {
    const errMessage = error.message?.message;
    yield put(
      showModal({
        modalType: NOTIFICATION_MODAL,
        options: {
          message:
            errMessage ||
            i18next.t('marineContractors.locations.errors.next', {
              email: SUPPORT_EMAIL,
            }),
          type: 'error',
        },
      })
    );
    yield put(requestMarineContractorsJobError(error));
  }
}
export function* saveMarineContractorsJobOutputs({
  job,
  percentiles,
  outputFormat,
  projectId,
  step = STEPS.STATISTICS,
}) {
  try {
    const parameters = {
      ...job.parameters,
      percentiles,
      outputFormat,
    };
    const jobUpdated = yield call(
      updateMarineContractorsJobOperations,
      job.id,
      { project: projectId, parameters, step }
    );
    yield put(requestMarineContractorsJobSuccess(jobUpdated));
  } catch (error) {
    const errMessage = error.message?.message;
    yield put(
      showModal({
        modalType: NOTIFICATION_MODAL,
        options: {
          message:
            errMessage ||
            i18next.t('marineContractors.outputs.errors.launch', {
              email: SUPPORT_EMAIL,
            }),
          type: 'error',
        },
      })
    );
    yield put(requestMarineContractorsJobError(error));
  }
}

export function* updateMarineContractorsStep({ job, projectId, step }) {
  try {
    const jobUpdated = yield call(
      updateMarineContractorsJobOperations,
      job.id,
      { project: projectId, parameters: job.parameters, step }
    );
    yield put(requestMarineContractorsJobSuccess(jobUpdated));
  } catch (error) {
    const errMessage = error.message?.message;
    yield put(
      showModal({
        modalType: NOTIFICATION_MODAL,
        options: {
          message:
            errMessage ||
            i18next.t('marineContractors.modals.common.error', {
              email: SUPPORT_EMAIL,
            }),
          type: 'error',
        },
      })
    );
    yield put(requestMarineContractorsJobError(error));
  }
}

export function* updateMarineContractorsCustomer({ customer }) {
  try {
    const customerUpdated = yield call(
      updateMarineConstractorsCustomerRequest,
      customer
    );
    yield put(requestMarineContractorsCustomerSuccess(customerUpdated));
  } catch (error) {
    yield put(requestMarineContractorsCustomerError(error));
  }
}
export function* updateMarineContractorsCustomerPercentiles({ customer }) {
  try {
    const customerUpdated = yield call(
      updateMarineConstractorsCustomerRequest,
      customer
    );
    yield put(requestMarineContractorsCustomerSuccess(customerUpdated));
  } catch (error) {
    yield put(updateMarineContractorsCustomerPercentilesError(error));
  }
}

export function* createMarineContractorBoat({ boat }) {
  try {
    const { operations, ...b } = yield call(
      createMarineContractorsBoatRequest,
      boat
    );
    yield put(createMarineContractorsBoatSuccess(b));
  } catch (error) {
    yield put(createMarineContractorsBoatError(boat, error));
  }
}
export function* updateMarineContractorBoat({ boat }) {
  try {
    const { operations, ...b } = yield call(
      updateMarineContractorsBoatRequest,
      boat
    );
    yield put(updateMarineContractorsBoatSuccess(b));
  } catch (error) {
    yield put(updateMarineContractorsBoatError(boat, error));
  }
}
export function* removeMarineContractorBoat({ boat, modalId }) {
  try {
    yield call(deleteMarineContractorsBoatRequest, boat);
    yield put(deleteMarineContractorsBoatSuccess(boat));
    yield put(closeModal({ id: modalId }));
  } catch (error) {
    yield put(deleteMarineContractorsBoatError(error));
  }
}

export function* createMarineContractorOperation({ operation }) {
  try {
    const { constraints, ...o } = yield call(
      createMarineContractorsOperationRequest,
      operation
    );
    yield put(createMarineContractorsOperationSuccess(o));
  } catch (error) {
    yield put(createMarineContractorsOperationError(operation, error));
  }
}
export function* updateMarineContractorOperation({ operation }) {
  try {
    const { constraints, ...o } = yield call(
      updateMarineContractorsOperationsRequest,
      operation
    );
    yield put(updateMarineContractorsOperationSuccess(o));
  } catch (error) {
    yield put(updateMarineContractorsOperationError(operation, error));
  }
}

export function* removeMarineContractorOperation({ operation, modalId }) {
  try {
    yield call(deleteMarineContractorsOperationsRequest, operation);
    yield put(deleteMarineContractorsOperationSuccess(operation));
    yield put(closeModal({ id: modalId }));
  } catch (error) {
    yield put(deleteMarineContractorsOperationError(error));
  }
}

export function* createMarineContractorConstraint({ constraint }) {
  try {
    const newConstraint = yield call(
      createMarineConstractorsConstraintsRequest,
      constraint
    );
    yield put(createMarineContractorsConstraintSuccess(newConstraint));
  } catch (error) {
    yield put(createMarineContractorsConstraintError(constraint, error));
  }
}
export function* updateMarineContractorConstraint({ constraint }) {
  try {
    const updatedConstraint = yield call(
      updateMarineConstractorsConstraintsRequest,
      constraint
    );
    yield put(updateMarineContractorsConstraintSuccess(updatedConstraint));
  } catch (error) {
    yield put(updateMarineContractorsConstraintError(constraint, error));
  }
}
export function* removeMarineContractorConstraint({ constraint }) {
  try {
    yield call(deleteMarineContractorsConstraintsRequest, constraint);
    yield put(deleteMarineContractorsConstraintSuccess(constraint));
  } catch (error) {
    yield put(deleteMarineContractorsConstraintError(error));
  }
}

function* updateMarineContractorsComputationWorker({ job }) {
  while (true) {
    const jobUpdated = yield call(getMarineContractorsJob, job);
    yield put(requestMarineContractorsJobSuccess(jobUpdated));
    yield delay(UPDATE_MARINE_CONTRACTORS_TIMEOUT);
  }
}

export function* startMarineContractorsComputationWorker({ job }) {
  yield race({
    point: call(updateMarineContractorsComputationWorker, { job }),
    cancel: take(STOP_UPDATE_MARINE_CONTRACTORS_JOB),
  });
  yield put(finishUpdateMarineContractorsJob(job));
}

/**
 * http get the result xlsx, dl it in a named blob
 */
export function* downloadResultsWorker({ jobId, projectId }) {
  try {
    const fileName = yield select((state) =>
      selectProjectNameById(state, projectId)
    );
    const response = yield call(downloadResults, { jobId });
    yield call(triggerDownloadBlob, {
      blob: response,
      fileName: `${fileName}.xlsx`,
      fileType: XLSX_FILE_TYPE,
      isArrayBuffer: true,
      isDownload: true,
      isOpenInNewTab: false,
    });
  } catch (error) {
    yield put(downloadResultsError(error));
  }
}
export default function* mainSaga() {
  yield takeLatest(REQUEST_MARINE_CONTRACTORS, requestMarineContractorsWorker);
  yield takeLatest(
    REQUEST_MARINE_CONTRACTORS_VARIABLES,
    requestMarineContractorsVariablesWorker
  );
  yield takeLatest(
    REQUEST_MARINE_CONTRACTORS_JOB,
    requestMarineContractorsJobWorker
  );
  yield takeLatest(
    REQUEST_MARINE_CONTRACTORS_CUSTOMER,
    requestMarineContractorsCustomerWorker
  );
  yield takeLatest(
    REQUEST_MARINE_CONTRACTORS_RESULTS,
    requestMarineContractorsResultsWorker
  );
  yield takeLatest(
    SAVE_MARINE_CONTRACTORS_JOB_OPERATIONS,
    saveMarineContractorsJobOperations
  );
  yield takeLatest(
    SAVE_MARINE_CONTRACTORS_JOB_POINTS,
    saveMarineContractorsJobPoints
  );
  yield takeLeading(
    SAVE_MARINE_CONTRACTORS_JOB_OUTPUTS,
    saveMarineContractorsJobOutputs
  );
  yield takeLatest(UPDATE_MARINE_CONTRACTORS_STEP, updateMarineContractorsStep);
  yield takeLatest(
    UPDATE_MARINE_CONTRACTORS_CUSTOMER,
    updateMarineContractorsCustomer
  );
  yield takeLatest(
    UPDATE_MARINE_CONTRACTORS_CUSTOMER_PERCENTILES,
    updateMarineContractorsCustomerPercentiles
  );
  yield takeLatest(CREATE_MARINE_CONTRACTORS_BOAT, createMarineContractorBoat);
  yield takeEvery(UPDATE_MARINE_CONTRACTORS_BOAT, updateMarineContractorBoat);
  yield takeLatest(DELETE_MARINE_CONTRACTORS_BOAT, removeMarineContractorBoat);
  yield takeEvery(
    CREATE_MARINE_CONTRACTORS_OPERATION,
    createMarineContractorOperation
  );
  yield takeEvery(
    UPDATE_MARINE_CONTRACTORS_OPERATION,
    updateMarineContractorOperation
  );
  yield takeLatest(
    DELETE_MARINE_CONTRACTORS_OPERATION,
    removeMarineContractorOperation
  );
  yield takeEvery(
    CREATE_MARINE_CONTRACTORS_CONSTRAINT,
    createMarineContractorConstraint
  );
  yield takeEvery(
    UPDATE_MARINE_CONTRACTORS_CONSTRAINT,
    updateMarineContractorConstraint
  );
  yield takeLatest(
    DELETE_MARINE_CONTRACTORS_CONSTRAINT,
    removeMarineContractorConstraint
  );
  yield takeLatest(
    START_UPDATE_MARINE_CONTRACTORS_JOB,
    startMarineContractorsComputationWorker
  );
  yield takeLatest(DOWNLOAD_RESULTS, downloadResultsWorker);
}
