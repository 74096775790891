import React, { memo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  IconButton,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from '@material-ui/core';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import { Delete as DeleteIcon } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { formatDate } from 'helpers/date';
import { DATE_FORMATS } from 'constants/common';
import { selectReportIsDownloadingById } from 'ducks/reports/selectors';
import { downloadReport } from 'ducks/reports/actions';
import { Loader } from 'components/common/Loader';

/**
 * Renders report name, date of creation, and user who created.
 * @param report
 * @param collaborators
 * @param onReportDelete
 * @param classes
 * @param allowDeleteReport
 */
const ReportListItem = memo(
  ({ report, collaborators, onReportDelete, classes, allowDeleteReport }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const reportsIsLoading = useSelector((state) =>
      selectReportIsDownloadingById(state, report.id)
    );
    const { firstName, lastName } = collaborators[report.user];
    const date = formatDate(report.generatedAt, DATE_FORMATS.longFormat);

    const handleDownloadReport = useCallback(() => {
      dispatch(downloadReport({ reportId: report.id }));
    }, [dispatch, report.id]);

    return (
      <ListItem className={classes.reportItem}>
        <ListItemText
          disableTypography
          primary={
            <div className={classes.itemContainer}>
              <Typography
                variant="subtitle1"
                className={classes.listItemTitle}
                onClick={handleDownloadReport}
              >
                {report.name}
              </Typography>
              {reportsIsLoading && (
                <ListItemIcon>
                  <Loader size={20} className={classes.reportLoader} />
                </ListItemIcon>
              )}
            </div>
          }
          secondary={
            <Typography variant="body2" color="textSecondary">
              {t('report.generated', { firstName, lastName, date })}
            </Typography>
          }
        />
        {allowDeleteReport && (
          <ListItemSecondaryAction>
            <IconButton edge="end" onClick={onReportDelete(report)}>
              <DeleteIcon className={classes.deleteIcon} />
            </IconButton>
          </ListItemSecondaryAction>
        )}
      </ListItem>
    );
  }
);

ReportListItem.propTypes = {
  report: PropTypes.shape({
    id: PropTypes.number,
    user: PropTypes.number,
    name: PropTypes.string,
    computation: PropTypes.number,
    status: PropTypes.string,
    taskId: PropTypes.string,
    document: PropTypes.any,
    generatedAt: PropTypes.string,
    startedAt: PropTypes.string,
    project: PropTypes.number,
  }).isRequired,
  collaborators: PropTypes.object,
  onReportDelete: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  allowDeleteReport: PropTypes.bool.isRequired,
};

export default ReportListItem;
