import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(({ palette }) => ({
  errorMessage: {
    color: palette.danger.middle,
    padding: '20px',
  },
  buttonDanger: {
    backgroundColor: palette.danger.middle,
    color: palette.white.main,
    '&:hover': {
      backgroundColor: palette.danger.middle,
    },
  },
}));
