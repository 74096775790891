import { makeStyles } from '@material-ui/core/styles';
import { alpha } from '@material-ui/core/styles/colorManipulator';

import queryIcon from 'assets/images/project/ic_query_grey.png';
import queryIconActive from 'assets/images/project/ic_query_blue.png';
import analyzeIcon from 'assets/images/project/ic_analyze_grey.png';
import analyzeIconActive from 'assets/images/project/ic_analyze_blue.png';
import reportIcon from 'assets/images/project/ic_report_grey.png';
import reportIconActive from 'assets/images/project/ic_report_blue.png';

export const useStyles = makeStyles(({ palette }) => ({
  container: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    display: 'flex',
    alignItems: 'center',
  },
  link: {
    flexGrow: 1,
    flexBasis: 0,
    padding: '40px 0 15px',
    fontSize: 14,
    fontWeight: 500,
    textAlign: 'center',
    textTransform: 'uppercase',
    color: alpha(palette.primary.main, 0.3),
    borderRight: `1px solid transparent`,
    backgroundColor: palette.lightGrey.light,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center 12px',
    '&:not(:last-child)': {
      borderRightColor: palette.lightGrey.main,
    },
    '&:not(.disabled)': {
      '&.active, &:hover': {
        color: palette.primary.middle,
        backgroundColor: palette.white.main,
        textDecoration: 'none',
      },
    },
    '&.query': {
      backgroundImage: `url(${queryIcon})`,
      '&:not(.disabled)': {
        '&.active, &:hover': {
          backgroundImage: `url(${queryIconActive})`,
        },
      },
    },
    '&.analyze': {
      backgroundImage: `url(${reportIcon})`,
      '&:not(.disabled)': {
        '&.active, &:hover': {
          backgroundImage: `url(${reportIconActive})`,
        },
      },
    },
    '&.report': {
      backgroundImage: `url(${analyzeIcon})`,
      '&:not(.disabled)': {
        '&.active, &:hover': {
          backgroundImage: `url(${analyzeIconActive})`,
        },
      },
    },
  },
}));
