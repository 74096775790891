import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { NAVIGATION_LINKS } from '../constants/routes';

/**
 * Hook for mapping navbar links and add translate
 * @param {String} pathname
 * @param {Array} navigationLinks
 * @returns {Array}
 */
export function useNavbarLinks(pathname, navigationLinks = NAVIGATION_LINKS) {
  const { t } = useTranslation();

  return useMemo(
    () =>
      navigationLinks.slice().map((link) => {
        link.active = link.url === pathname;
        link.text = t(link.text);
        return link;
      }),
    [pathname, navigationLinks, t]
  );
}
