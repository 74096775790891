import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Grid } from '@material-ui/core';
import PropTypes from 'prop-types';

import NativeLink from 'components/common/NativeLink';
import { ROUTES } from 'constants/routes';

import { useStyles } from './styles';

/**
 * Controls for comparison modal
 * @param {Function} onClose
 * @param {String} downloadLink
 * @param {Boolean} disableDownload
 * @param {Object} classes
 * @return {jsx}
 */
const ComparisonModalControls = ({ onClose, onClick, disableDownload }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Grid container justifyContent="center">
      <NativeLink to={ROUTES.helpValidation} className={classes.controlButton}>
        <Button color="primary" variant="outlined">
          {t('buttons.help')}
        </Button>
      </NativeLink>
      {!disableDownload && onClick && (
        <Button
          color="secondary"
          variant="contained"
          onClick={onClick}
          className={classes.controlButton}
        >
          {t('buttons.download')}
        </Button>
      )}
      <Button
        onClick={onClose}
        color="primary"
        variant="outlined"
        className={classes.controlButton}
      >
        {t('buttons.close')}
      </Button>
    </Grid>
  );
};

ComparisonModalControls.propTypes = {
  onClose: PropTypes.func.isRequired,
  onClick: PropTypes.func,
  disableDownload: PropTypes.bool,
};

export default memo(ComparisonModalControls);
