import React from 'react';
import PropTypes from 'prop-types';

import { AREA_GROUP_NAME } from 'constants/map';
import { MapPolygon, MapFeatureGroup } from 'components/leaflet';

/**
 * ProjectPolygon - render project polygon in feature group.
 * @param {Object} project
 * @param {String} polygonColor
 * @param {Object} props
 * @returns {jsx}
 */
const ProjectPolygon = ({ project, polygonColor, ...props }) => (
  <MapFeatureGroup name={AREA_GROUP_NAME} withControls {...props}>
    <MapPolygon
      geometry={project.zone.geom}
      color={polygonColor}
      fillOpacity={0}
    />
  </MapFeatureGroup>
);

ProjectPolygon.propTypes = {
  project: PropTypes.shape({
    zone: PropTypes.object.isRequired,
  }).isRequired,
  polygonColor: PropTypes.string,
};

ProjectPolygon.defaultProps = {
  polygonColor: 'blue',
};

export default React.memo(ProjectPolygon);
