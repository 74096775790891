import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import Button from '@material-ui/core/Button';
import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextField from '@material-ui/core/TextField';
import { useTranslation } from 'react-i18next';

import { Loader } from 'components/common/Loader';
import Collapse from 'components/common/Collapse';
import PropertiesListMultiple from 'components/points/PropertiesListMultiple';
import { useForm } from 'hooks/useForm';
import { generateReportName } from 'helpers/projects';
import { EMPTY_ARRAY } from 'constants/common';
import { getComputedPoints } from 'helpers/points';
import { scrollToElement } from 'helpers/common';
import { REPORT_TYPE_BY_CATEGORY } from 'constants/reports';

import { useStyles } from './styles';
import { validationRules } from './validationRules';
import { defaultValues } from './defaultValues';

/**
 * Local component
 * displays dropdown with checkbox
 */
const CheckboxCollapse = ({
  classes,
  isIncludeDistributions,
  checkboxHandler,
}) => {
  const { t } = useTranslation();
  return (
    <Collapse
      isOpen={false}
      title={t('report.advancedTitle')}
      id="advanced"
      className={classes.advancedBlock}
    >
      <div className={classes.advancedBlockWrapper}>
        <FormControlLabel
          className={classes.checkboxRow}
          control={
            <Checkbox
              name="includeDistributions"
              value={isIncludeDistributions}
              onChange={checkboxHandler}
              color="primary"
            />
          }
          label={t('report.includeMonthly')}
        />
        <Typography
          variant="body2"
          align="center"
          className={classes.checkboxSubtext}
        >
          {t('report.checkboxTitle')}
        </Typography>
      </div>
    </Collapse>
  );
};

/**
 * Local component
 * displays input for report name
 */
const ReportNameInput = ({
  classes,
  name,
  changeName,
  error,
  disabled = false,
}) => {
  const { t } = useTranslation();
  return (
    <div className={classes.fieldRow}>
      <Typography variant="body1" className={classes.fieldName}>
        {t('report.inputTitle')}
      </Typography>
      <TextField
        fullWidth
        required
        name="name"
        size="small"
        value={name}
        onChange={changeName}
        error={!!error}
        helperText={error}
        variant="outlined"
        disabled={disabled}
      />
    </div>
  );
};

/**
 * Project report page component
 * displays project reports
 */
const ProjectReport = ({
  points,
  categories,
  parts,
  changePoint,
  generateReport,
  cancelReport,
  isGeneratingReport,
  projectName,
  selectedMarkers,
  pointsIdName,
  partsIdName,
  reportButtonIdName,
  allowGenerateReport,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [selectedCategory, setSelectedCategory] = useState(EMPTY_ARRAY);
  const [selectedPart, setSelectedPart] = useState(EMPTY_ARRAY);
  const computedPoints = useMemo(() => getComputedPoints(points), [points]);

  const onSubmit = useCallback(
    ({ name, isIncludeDistributions }) => {
      generateReport({
        name,
        monthly: isIncludeDistributions,
        reportType: REPORT_TYPE_BY_CATEGORY[selectedCategory[0]],
        parts: selectedPart.length ? selectedPart : undefined,
      });
    },
    [generateReport, selectedCategory, selectedPart]
  );

  const { setField, values, handleSubmit, errors } = useForm({
    defaultValues,
    validationRules,
    onSuccess: onSubmit,
  });
  const { name, isIncludeDistributions } = values;

  useEffect(() => {
    const categoriesName = generateReportName(selectedCategory);
    const markersName = generateReportName(selectedMarkers);
    const partsName = generateReportName(selectedPart);
    const reportName = `${projectName}_S${categoriesName}_P${markersName}_D${partsName}`;
    setField('name', reportName);
  }, [projectName, selectedCategory, selectedMarkers, selectedPart, setField]);

  const handleChangeName = useCallback(
    ({ target }) => {
      setField('name', target.value);
    },
    [setField]
  );

  const checkboxHandler = useCallback(
    ({ target }) => {
      setField('isIncludeDistributions', target.checked);
    },
    [setField]
  );

  const changePart = useCallback(
    ({ id }) => {
      setSelectedPart((prevPart) =>
        prevPart.includes(id)
          ? prevPart.filter((selectedId) => selectedId !== id)
          : [...prevPart, id]
      );
      scrollToElement(reportButtonIdName);
    },
    [setSelectedPart, reportButtonIdName]
  );

  const changeCategory = useCallback(
    ({ id }) => {
      setSelectedCategory((prevCategory) =>
        prevCategory.includes(id) ? EMPTY_ARRAY : [id]
      );
      scrollToElement(pointsIdName);
    },
    [setSelectedCategory, pointsIdName]
  );

  const selectAllPart = useCallback(() => {
    setSelectedPart(EMPTY_ARRAY);
  }, [setSelectedPart]);

  const selectAllCategory = useCallback(() => {
    setSelectedCategory(EMPTY_ARRAY);
  }, [setSelectedCategory]);

  return (
    <>
      <PropertiesListMultiple
        items={categories}
        title={t('report.categoryTitle')}
        onItemClick={changeCategory}
        selectedIds={selectedCategory}
        onAllClick={selectAllCategory}
      />
      <PropertiesListMultiple
        items={computedPoints}
        title={t('report.pointsTitle')}
        onItemClick={changePoint}
        selectedIds={selectedMarkers}
        onAllClick={changePoint}
        idName={pointsIdName}
      />
      <PropertiesListMultiple
        items={parts}
        title={t('report.partsTitle')}
        onItemClick={changePart}
        selectedIds={selectedPart}
        onAllClick={selectAllPart}
        idName={partsIdName}
      />
      <CheckboxCollapse
        classes={classes}
        checkboxHandler={checkboxHandler}
        isIncludeDistributions={isIncludeDistributions}
      />
      {!isGeneratingReport && (
        <ReportNameInput
          classes={classes}
          name={name}
          changeName={handleChangeName}
          error={errors.name}
          disabled={!allowGenerateReport}
        />
      )}
      {isGeneratingReport && (
        <div className={classes.loaderContainer}>
          <Loader />
        </div>
      )}
      {isGeneratingReport ? (
        <Button
          className={classes.cancelReportGenerationButton}
          color="primary"
          variant="contained"
          onClick={cancelReport}
          fullWidth
        >
          {t('report.cancelButton')}
        </Button>
      ) : (
        <Button
          className={classes.submitButton}
          color="secondary"
          variant="contained"
          onClick={handleSubmit}
          fullWidth
          id={reportButtonIdName}
          disabled={!allowGenerateReport}
        >
          {t('report.generateButton')}
        </Button>
      )}
    </>
  );
};

ProjectReport.propTypes = {
  points: PropTypes.arrayOf(PropTypes.object).isRequired,
  categories: PropTypes.arrayOf(PropTypes.object).isRequired,
  parts: PropTypes.arrayOf(PropTypes.object).isRequired,
  changePoint: PropTypes.func.isRequired,
  generateReport: PropTypes.func.isRequired,
  projectName: PropTypes.string.isRequired,
  selectedMarkers: PropTypes.arrayOf(PropTypes.number).isRequired,
  pointsIdName: PropTypes.string.isRequired,
  partsIdName: PropTypes.string.isRequired,
  reportButtonIdName: PropTypes.string.isRequired,
  allowGenerateReport: PropTypes.bool.isRequired,
};

export default memo(ProjectReport);
