import React, { memo, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import DownloadGraphDataFileButtonGroupComponent from 'components/common/buttons/DownloadGraphDataFileButtonGroup';
import { VCLASS_TYPE_REGEXP } from 'constants/regexp';
import { downloadGraphFile, resetDownloadGraph } from 'ducks/projects/actions';
import {
  ANNUAL_INDEX,
  CHART_SVG_ID,
  CHART_TITLE_ID,
  DEFAULT_GRAPH_DATA_LOADING_FORMATS,
  HTML_CAPTURE_MODE,
  IMAGE_GRAPH_DATA_FORMAT,
  SVG_CAPTURE_MODE,
} from 'constants/graphs';
import {
  convertHtmlToPngByQuerySelector,
  convertSvgToPngByQuerySelector,
  triggerDownloadDataUrl,
} from 'helpers/dom';
import { selectGraphDownloadStatuses } from 'ducks/projects/selectors';
import { MONTH_NAMES } from 'constants/common';

/**
 * Container, contains buttons for downloading graph data files
 * Images are build via frontend, other files are requested from server
 * @param { string } salt - some unique string, addition for id
 * @param { number } projectId
 * @param { Array }  statsIds
 * @param { string } captureMode
 * @param { number } currentLevel
 * @param { number } monthNumber
 * @param { object } pdSelectRange
 * @param { string } pngFileName
 * @param { string[] } loadingFormats
 * @returns { JSX }
 */
const DownloadGraphDataFileButtonGroup = ({
  salt,
  projectId,
  statsIds,
  captureMode,
  currentLevel,
  monthNumber,
  pdSelectRange,
  pngFileName,
  loadingFormats,
}) => {
  const dispatch = useDispatch();
  const vclassType = statsIds.reduce((acc, item) => {
    const typesArray = VCLASS_TYPE_REGEXP.exec(item);
    acc = typesArray ? typesArray[1] : null;
    return acc;
  }, '');
  const downloadStatuses = useSelector(selectGraphDownloadStatuses);

  const downloadPng = useCallback(() => {
    const chartSelector = `#${CHART_SVG_ID + salt}`;
    const titleSelector = `#${CHART_TITLE_ID + salt}`;
    // add selector to get duration
    const durationSelector = 'mui-component-select-duration';
    const limitSelector = '[id^="mui-component-select"][id$="Limit"]';
    const quantileSelector = 'mui-component-select-quantiles';

    const monthName = MONTH_NAMES[monthNumber - 1];
    let fileName = pngFileName;
    fileName +=
      monthNumber && monthNumber !== ANNUAL_INDEX ? `_month_${monthName}` : '';
    fileName +=
      !!currentLevel || currentLevel === 0
        ? `_at_level_index_${currentLevel}`
        : '';

    const capturingFunction =
      captureMode === SVG_CAPTURE_MODE
        ? (selector) =>
            convertSvgToPngByQuerySelector(selector, {
              padding: 10,
              titleSelector,
              durationSelector,
              limitSelector,
              quantileSelector,
            })
        : (selector) =>
            convertHtmlToPngByQuerySelector(selector, { padding: 10 });

    capturingFunction(chartSelector)
      .then((dataUrl) => triggerDownloadDataUrl(dataUrl, `${fileName}.png`))
      .catch(
        // eslint-disable-next-line
        console.warn
      );
  }, [pngFileName, salt, captureMode, monthNumber, currentLevel]);

  const selectButton = useCallback(
    (_, fileFormat) => {
      const [fileType] = fileFormat;

      if (fileType === IMAGE_GRAPH_DATA_FORMAT) {
        return downloadPng();
      }

      dispatch(
        downloadGraphFile({
          projectId,
          currentLevel,
          monthNumber,
          pdSelectRange,
          statsIds,
          fileFormat,
          vclassType,
          id: fileType,
        })
      );
    },
    [
      dispatch,
      vclassType,
      statsIds,
      projectId,
      currentLevel,
      monthNumber,
      pdSelectRange,
      downloadPng,
    ]
  );

  useEffect(() => () => dispatch(resetDownloadGraph()), [dispatch]);

  return (
    <DownloadGraphDataFileButtonGroupComponent
      loadingFormats={loadingFormats}
      selectButton={selectButton}
      downloadStatuses={downloadStatuses}
      statsIds={statsIds}
    />
  );
};

DownloadGraphDataFileButtonGroup.propTypes = {
  salt: PropTypes.string,
  projectId: PropTypes.number,
  monthNumber: PropTypes.number,
  currentLevel: PropTypes.number,
  statsIds: PropTypes.array,
  pdSelectRange: PropTypes.object,
  pngFileName: PropTypes.string,
  captureMode: PropTypes.oneOf([HTML_CAPTURE_MODE, SVG_CAPTURE_MODE]),
  loadingFormats: PropTypes.arrayOf(PropTypes.string),
};

DownloadGraphDataFileButtonGroup.defaultProps = {
  statsIds: [],
  captureMode: SVG_CAPTURE_MODE,
  pngFileName: new Date().toISOString(),
  loadingFormats: DEFAULT_GRAPH_DATA_LOADING_FORMATS,
  salt: '',
};

export default memo(DownloadGraphDataFileButtonGroup);
