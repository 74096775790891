import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import React, { memo } from 'react';

import { useStyles } from './styles';

/**
 * CreateProjectButton component
 * Floating button for creating a new project.
 * Adds floating class to Button and label class for its label span.
 * @props props will be passed to Button
 * @param {Object} props - other props for button
 * @returns {jsx}
 * @see Button
 */
const CreateProjectButton = (props) => {
  const classes = useStyles();

  return (
    <Button
      variant="contained"
      className={classes.floatingButton}
      classes={{ label: classes.label }}
      startIcon={<AddIcon htmlColor="white" />}
      {...props}
    >
      new project
    </Button>
  );
};

export default memo(CreateProjectButton);
