import {
  takeLatest,
  put,
  call,
  select,
  delay,
  race,
  take,
} from 'redux-saga/effects';
import i18next from 'i18next';

import {
  getPoints,
  deletePoint,
  deletePoints,
  createPoints,
  uploadPoints,
  updatePoint,
  getPoint,
  createPointTask,
  runPointTask,
  cancelPointTask,
  downloadTask,
} from 'services/points';
import { jsonToFormData } from 'helpers/data';
import { closeAllModal, showModal } from 'ducks/modals/actions';
import { BACKDROP_LOADER_MODAL, NOTIFICATION_MODAL } from 'constants/modals';
import { SUPPORT_EMAIL, UPDATE_POINT_TIMEOUT } from 'common/config';
import { getEmptyTasks, getFailedTasks } from 'helpers/points';
import { triggerDownloadBlob } from 'helpers/dom';
import { trackPointCreation } from 'ducks/trackers/actions/workzone';

import {
  REQUEST_POINTS,
  REQUEST_POINT,
  DELETE_POINT,
  DELETE_PROJECT_POINTS,
  CREATE_POINTS_FROM_FILE,
  CREATE_POINT,
  UPDATE_POINT,
  RUN_POINT_TASK,
  STOP_UPDATE_POINT_TASK_JOB,
  START_UPDATE_POINT_TASK_JOB,
  CANCEL_POINT_TASK_JOB,
  DOWNLOAD_TASK,
} from './types';
import {
  requestPointsSuccess,
  requestPointsError,
  requestPointSuccess,
  requestPointError,
  deletePointSuccess,
  deletePointError,
  deleteProjectPointsError,
  deleteProjectPointsSuccess,
  createPointsFromFileSuccess,
  createPointsFromFileError,
  createPointSuccess,
  createPointError,
  updatePointSuccess,
  updatePointError,
  finishUpdatePointTaskJob,
  cancelPointTaskJobError,
  downloadTaskSuccess,
  downloadTaskError,
} from './actions';
import { selectPointById, selectPointTaskById } from './selectors';

/**
 * Requests all points
 */
export function* requestPointsWorker({ params }) {
  try {
    const points = yield call(getPoints, params);
    yield put(requestPointsSuccess(points));
  } catch (error) {
    yield put(requestPointsError(error));
  }
}

/**
 * request point worker, requests point by id
 */
export function* requestPointWorker({ id, params }) {
  try {
    const point = yield call(getPoint, id, params);
    yield put(requestPointSuccess(point));
  } catch (error) {
    yield put(requestPointError(error));
  }
}

/**
 * Delete point
 */
export function* deletePointWorker({ pointId, projectId }) {
  try {
    yield call(deletePoint, pointId);
    yield put(deletePointSuccess({ projectId, pointId }));
  } catch (error) {
    yield put(deletePointError(error));
  }
}

/**
 * Delete points
 */
export function* deleteProjectPointsWorker({ projectId }) {
  try {
    yield call(deletePoints, {
      params: { project: projectId },
      headers: { 'X-BULK-OPERATION': true },
    });
    yield put(deleteProjectPointsSuccess({ projectId }));
  } catch (error) {
    yield put(deleteProjectPointsError(error));
  }
}

/**
 * Requests for create point
 */
export function* createPointWorker({ data }) {
  try {
    const points = yield call(createPoints, data);
    yield put(createPointSuccess(points));
    yield put(trackPointCreation());
  } catch (error) {
    yield put(createPointError(error));
  }
}

/**
 * Requests for create points from file
 */
export function* createPointsFromFileWorker({ projectId, file }) {
  try {
    yield put(showModal({ modalType: BACKDROP_LOADER_MODAL }));
    const data = yield call(jsonToFormData, {
      project: projectId,
      file,
    });

    const points = yield call(uploadPoints, data);
    yield put(createPointsFromFileSuccess({ points, projectId }));
    yield put(closeAllModal());
  } catch (error) {
    yield put(closeAllModal());
    yield put(createPointsFromFileError(error));
  }
}

/**
 * Requests for update point
 */
export function* updatePointWorker({ point }) {
  try {
    const points = yield call(updatePoint, point.id, point);
    yield put(updatePointSuccess(points));
  } catch (error) {
    yield put(updatePointError(error));
  }
}

export function* runPointTaskWorker({ pointId, processType }) {
  // get empty task if exists before creating a new one
  const point = yield select(selectPointById(pointId));
  const failedTasks = getFailedTasks(point, processType);
  const emptyTasks = getEmptyTasks(point);
  const aloneTasks = !failedTasks.length ? emptyTasks : failedTasks;
  let pointTask;

  try {
    pointTask = !aloneTasks.length
      ? yield call(createPointTask, pointId)
      : (pointTask = aloneTasks[0]);
    const taskId = pointTask?.id;
    const runningPoint = yield call(runPointTask, { taskId, processType });
    yield put(requestPointSuccess(runningPoint));
  } catch (error) {
    const errMessage = error.message?.message;
    yield put(
      showModal({
        modalType: NOTIFICATION_MODAL,
        options: {
          message:
            errMessage ||
            i18next.t('points.errors.taskError', {
              email: SUPPORT_EMAIL,
            }),
          type: 'error',
        },
      })
    );
  }
}

function* updatePointTaskWorker({ pointId }) {
  while (true) {
    const point = yield call(getPoint, pointId);
    yield put(requestPointSuccess(point));
    yield delay(UPDATE_POINT_TIMEOUT);
  }
}

export function* startUpdatePointJobWorker({ pointId }) {
  yield race({
    point: call(updatePointTaskWorker, { pointId }),
    cancel: take(STOP_UPDATE_POINT_TASK_JOB),
  });

  yield put(finishUpdatePointTaskJob(pointId));
}

export function* cancelPointTaskWorker({ taskId }) {
  try {
    const point = yield call(cancelPointTask, taskId);
    yield put(requestPointSuccess(point));
  } catch (error) {
    yield put(cancelPointTaskJobError(error));
  }
}

function* downloadTaskWorker({ pointId, taskId }) {
  try {
    const { document } = yield select(selectPointTaskById(pointId, taskId));
    const task = yield call(downloadTask, taskId);
    yield call(triggerDownloadBlob, {
      blob: task,
      fileName: document.split(/[\\/]/).pop(),
      isDownload: true,
      isArrayBuffer: true,
    });
    yield put(downloadTaskSuccess(taskId));
  } catch (error) {
    // TODO : could show a modal with the error
    yield put(downloadTaskError(error, taskId));
  }
}

export default function* offersSaga() {
  yield takeLatest(REQUEST_POINTS, requestPointsWorker);
  yield takeLatest(DELETE_POINT, deletePointWorker);
  yield takeLatest(DELETE_PROJECT_POINTS, deleteProjectPointsWorker);
  yield takeLatest(CREATE_POINT, createPointWorker);
  yield takeLatest(CREATE_POINTS_FROM_FILE, createPointsFromFileWorker);
  yield takeLatest(UPDATE_POINT, updatePointWorker);
  yield takeLatest(REQUEST_POINT, requestPointWorker);
  yield takeLatest(RUN_POINT_TASK, runPointTaskWorker);
  yield takeLatest(START_UPDATE_POINT_TASK_JOB, startUpdatePointJobWorker);
  yield takeLatest(CANCEL_POINT_TASK_JOB, cancelPointTaskWorker);
  yield takeLatest(DOWNLOAD_TASK, downloadTaskWorker);
}
