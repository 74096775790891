import { takeLatest, put, call, select } from 'redux-saga/effects';

import { getZones, getZone } from 'services/zones';

import { REQUEST_ZONE, REQUEST_ZONES, REQUEST_ZONES_IF_NEED } from './types';
import {
  requestZones,
  requestZonesError,
  requestZonesSuccess,
  requestZoneError,
  requestZoneSuccess,
} from './actions';
import { selectZones } from './selectors';

/**
 * Request zone
 */
function* requestZoneWorker({ id }) {
  try {
    const zone = yield call(getZone, id);
    yield put(requestZoneSuccess(zone));
  } catch (error) {
    yield put(requestZoneError(error));
  }
}

/**
 * Check that zones collection exists, if not, requests it
 */
function* requestZonesIfNeedWorker() {
  const zones = yield select(selectZones);
  if (!Object.keys(zones.data).length) {
    yield put(requestZones());
  }
}

/**
 * Requests all zones
 */
function* requestZonesWorker() {
  try {
    const zones = yield call(getZones);
    yield put(requestZonesSuccess(zones));
  } catch (error) {
    yield put(requestZonesError(error));
  }
}

export default function* mainSaga() {
  yield takeLatest(REQUEST_ZONE, requestZoneWorker);
  yield takeLatest(REQUEST_ZONES, requestZonesWorker);
  yield takeLatest(REQUEST_ZONES_IF_NEED, requestZonesIfNeedWorker);
}
