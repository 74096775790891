import React, { useCallback, useMemo, memo } from 'react';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { useTranslation } from 'react-i18next';
import FormHelperText from '@material-ui/core/FormHelperText';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { useForm } from 'hooks/useForm';
import { MANAGER_ROLE } from 'constants/common';
import { editUserProfileWaitingRequest } from 'ducks/app/actions';

import { useStyles } from './styles';
import { validationRules } from './validationRules';

/**
 * Displays form for editing other profile
 * @param { Object } user - user information
 * @param { boolean } isCurrentUser - flag for current user
 * @param { string } modalId - current modal id
 */
const EditOtherProfileForm = ({ user, isCurrentUser, modalId }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { firstName, lastName, role, id } = user;
  const initialValues = useMemo(
    () => ({
      firstName,
      lastName,
      id,
      isStaff: role === MANAGER_ROLE,
    }),
    [firstName, lastName, role, id]
  );

  const onSuccess = useCallback(
    (values) => {
      dispatch(editUserProfileWaitingRequest({ values, modalId }));
    },
    [dispatch, modalId]
  );

  const { setField, handleBlur, values, errors, handleSubmit } = useForm({
    initialValues,
    validationRules,
    onSuccess,
  });

  const checkboxHandler = useCallback(
    ({ target }) => {
      setField('isStaff', target.checked);
    },
    [setField]
  );

  return (
    <form>
      <div className={classes.row}>
        <Typography variant="subtitle1" className={classes.fieldName}>
          {t('collaborators.firstName')}
        </Typography>
        <div>
          <TextField
            name="firstName"
            defaultValue={user.firstName}
            variant="outlined"
            onBlur={handleBlur}
          />
          <FormHelperText error className={classes.helperText}>
            {errors.firstName}
          </FormHelperText>
        </div>
      </div>
      <div className={classes.row}>
        <Typography variant="subtitle1" className={classes.fieldName}>
          {t('collaborators.lastName')}
        </Typography>
        <div>
          <TextField
            name="lastName"
            defaultValue={user.lastName}
            variant="outlined"
            onBlur={handleBlur}
          />
          <FormHelperText error className={classes.helperText}>
            {errors.lastName}
          </FormHelperText>
        </div>
      </div>
      {!isCurrentUser && (
        <FormControlLabel
          className={classes.checkboxRow}
          control={
            <Checkbox
              name="isStaff"
              checked={values.isStaff}
              onChange={checkboxHandler}
              color="primary"
            />
          }
          label={t('collaborators.isManagerLabel')}
        />
      )}
      <div className={classes.modalButton}>
        <Button variant="contained" color="secondary" onClick={handleSubmit}>
          {t('buttons.save')}
        </Button>
      </div>
    </form>
  );
};

EditOtherProfileForm.propTypes = {
  user: PropTypes.object.isRequired,
  isCurrentUser: PropTypes.bool.isRequired,
  modalId: PropTypes.string.isRequired,
};

export default memo(EditOtherProfileForm);
