import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    container: {
        width: 600,
    },
    buttons: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    button: {
        color: theme.palette.white.main,
        background: theme.palette.sinay.primary,
    },
}));