import { CircularProgress } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { EMPTY_VALUE } from 'constants/common';

import { useStyles } from './styles';

/**
 * Common progress loader component
 * @param { Boolean } center if true Loader would be placed in center
 * @param { Number } size in px
 * @param { Number } value part of 100%
 * @param { Object } props rest props
 * @param { Boolean } nomargin - delete the marginLeft set from 'classes.relative'
 * @param { Object } style - custom style for the value (override the valueContainer css class)
 * @see https://material-ui.com/ru/api/circular-progress
 */
export const ProgressLoader = ({
  center,
  size,
  value,
  nomargin,
  style,
  ...props
}) => {
  const classes = useStyles();
  const relativeClasses = !nomargin ? classes.relative : undefined;
  const wrapperClass = center ? classes.center : relativeClasses;
  return (
    <div className={classNames([classes.container, wrapperClass])}>
      <CircularProgress
        variant="determinate"
        size={size}
        value={100}
        className={classes.bgCircle}
      />
      <CircularProgress
        value={value}
        classes={{
          colorPrimary: classes.barColor,
        }}
        {...props}
        size={size}
      />
      <span style={{ ...style }} className={classes.valueContainer}>
        {value}%
      </span>
    </div>
  );
};

ProgressLoader.propTypes = {
  center: PropTypes.bool,
  size: PropTypes.number,
  nomargin: PropTypes.bool,
  style: PropTypes.object,
};

ProgressLoader.defaultProps = {
  center: false,
  size: 30,
  nomargin: false,
  style: EMPTY_VALUE,
};
