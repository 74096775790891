import { put, select, takeLatest } from 'redux-saga/effects';
import i18next from 'i18next';

import { EOL } from 'constants/common';
import { SUPPORT_EMAIL } from 'common/config';
import { NOTIFICATION_MODAL } from 'constants/modals';
import { createApiErrorsArraySelector } from 'helpers/apiErrors';
import { modalsActions } from 'ducks/modals';
import {
  satelliteComparisonsSelectors,
  satelliteComparisonsTypes,
} from 'ducks/satelliteComparisons';
import { buoysSelectors, buoysTypes } from 'ducks/buoys';
import { pointsTypes, pointsSelectors } from 'ducks/points';
import { reportsSelectors, reportsTypes } from 'ducks/reports';
import { projectsSelectors, projectsTypes } from 'ducks/projects';
import { planningsSelectors, planningsTypes } from 'ducks/plannings';

/**
 * Helper function that creates saga worker
 * @param { function } errorSelector
 * @param { string } i18error - i18 key for error
 */
const errorNotificationWorkerCreator = (
  errorSelector,
  i18error = 'errors.commonWithSupport'
) =>
  function* errorNotificationWorker() {
    const commonErrors = yield select(
      createApiErrorsArraySelector(errorSelector)
    );
    const message = commonErrors.length
      ? commonErrors.join(EOL)
      : i18next.t(i18error, {
          email: SUPPORT_EMAIL,
        });

    yield put(
      modalsActions.showModal({
        modalType: NOTIFICATION_MODAL,
        options: { message },
      })
    );
  };

/**
 * Show error notification on get buoy visualization error
 */
function* showNotificationAfterGetBuoyVisualizationErrorWorker({ buoyId }) {
  const buoysCommonErrors = yield select(
    createApiErrorsArraySelector(buoysSelectors.selectBuoysErrors)
  );
  const message = buoysCommonErrors.length
    ? buoysCommonErrors.join(EOL)
    : i18next.t('buoys.failedToLoadComparison', {
        email: SUPPORT_EMAIL,
        buoyId,
      });

  yield put(modalsActions.closeAllModal());
  yield put(
    modalsActions.showModal({
      modalType: NOTIFICATION_MODAL,
      options: { message },
    })
  );
}

export default function*() {
  yield takeLatest(
    satelliteComparisonsTypes.COMPUTE_SATELLITE_COMPARISON_ERROR,
    errorNotificationWorkerCreator(
      satelliteComparisonsSelectors.selectSatelliteComparisonsError,
      'points.comparisonError'
    )
  );
  yield takeLatest(
    satelliteComparisonsTypes.CREATE_SATELLITE_COMPARISON_ERROR,
    errorNotificationWorkerCreator(
      satelliteComparisonsSelectors.selectSatelliteComparisonsError,
      'points.createComparisonError'
    )
  );
  yield takeLatest(
    satelliteComparisonsTypes.REQUEST_SATELLITE_COMPARISON_STATS_ERROR,
    errorNotificationWorkerCreator(
      satelliteComparisonsSelectors.selectSatelliteComparisonsError,
      'points.visualizationSatelliteComparisonError'
    )
  );
  yield takeLatest(
    buoysTypes.REQUEST_BUOY_STATS_ERROR,
    showNotificationAfterGetBuoyVisualizationErrorWorker
  );
  yield takeLatest(
    pointsTypes.CREATE_POINTS_FROM_FILE_ERROR,
    errorNotificationWorkerCreator(
      pointsSelectors.selectPointsError,
      'points.errors.uploadError'
    )
  );
  yield takeLatest(
    projectsTypes.COMPUTE_PROJECT_STATISTICS_ERROR,
    errorNotificationWorkerCreator(
      projectsSelectors.selectProjectsError,
      'project.errors.computationError'
    )
  );
  yield takeLatest(
    projectsTypes.DOWNLOAD_GRAPH_FILE_ERROR,
    errorNotificationWorkerCreator(
      projectsSelectors.selectProjectsError,
      'project.errors.graphDownloadError'
    )
  );
  yield takeLatest(
    planningsTypes.UPLOAD_PLANNING_ERROR,
    errorNotificationWorkerCreator(
      planningsSelectors.selectPlanningsError,
      'plannings.errors.uploadPlanningsError'
    )
  );
  yield takeLatest(
    reportsTypes.GENERATE_REPORT_ERROR,
    errorNotificationWorkerCreator(
      reportsSelectors.selectReportsError,
      'report.errors.generateReport'
    )
  );
  yield takeLatest(
    reportsTypes.POST_REPORT_ERROR,
    errorNotificationWorkerCreator(
      reportsSelectors.selectReportsError,
      'report.errors.generateReport'
    )
  );
  yield takeLatest(
    reportsTypes.REQUEST_REPORTS_ERROR,
    errorNotificationWorkerCreator(
      reportsSelectors.selectReportsError,
      'report.errors.reportsRequest'
    )
  );
}
