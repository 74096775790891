import { takeLatest, put, call, delay, race, take } from 'redux-saga/effects';

import {
  computeSatelliteComparison,
  createSatelliteComparison,
  getSatelliteComparison,
  getSatelliteComparisons,
  getSatelliteComparisonStats,
  downloadSatelliteComparisonStat,
} from 'services/satelliteComparisons';
import {
  START_UPDATE_SATELLITE_COMPARISON_JOB,
  STOP_UPDATE_SATELLITE_COMPARISON_JOB,
} from 'ducks/satelliteComparisons/types';
import { UPDATE_SATELLITE_COMPARISON_TIMEOUT } from 'common/config';
import { ZIP_FILE_TYPE } from 'constants/common';
import { finishUpdateSatelliteComparisonJob } from 'ducks/satelliteComparisons/actions';
import { triggerDownloadBlob } from 'helpers/dom';
import { trackComparisonAgainstSat } from 'ducks/trackers/actions/datavalidation';

import {
  CREATE_SATELLITE_COMPARISON,
  COMPUTE_SATELLITE_COMPARISON,
  LAUNCH_SATELLITE_COMPARISON,
  REQUEST_SATELLITE_COMPARISONS,
  REQUEST_SATELLITE_COMPARISON_STATS,
  DOWNLOAD_SATELLITE_COMPARISON,
} from './types';
import {
  computeSatelliteComparisonError,
  computeSatelliteComparisonSuccess,
  createSatelliteComparisonError,
  createSatelliteComparisonSuccess,
  requestSatelliteComparisonsSuccess,
  requestSatelliteComparisonsError,
  requestSatelliteComparisonSuccess,
  requestSatelliteComparisonStatsError,
  requestSatelliteComparisonStatsSuccess,
  downloadSatelliteComparisonStatsSucess,
  downloadSatelliteComparisonStatsError,
} from './actions';

/**
 * Request satellite comparisons
 */
export function* requestSatelliteComparisonsWorker({ params }) {
  try {
    const satelliteComparisons = yield call(getSatelliteComparisons, params);
    yield put(requestSatelliteComparisonsSuccess(satelliteComparisons));
  } catch (error) {
    yield put(requestSatelliteComparisonsError(error));
  }
}

/**
 * Create satellite comparison
 */
export function* createSatelliteComparisonWorker({ data }) {
  try {
    const satelliteComparison = yield call(createSatelliteComparison, data);
    yield put(createSatelliteComparisonSuccess(satelliteComparison));
    return satelliteComparison;
  } catch (error) {
    yield put(createSatelliteComparisonError(error));
  }
}

/**
 * Compute satellite comparison
 */
export function* computeSatelliteComparisonWorker({ id }) {
  try {
    const satelliteComparison = yield call(computeSatelliteComparison, id);
    yield put(computeSatelliteComparisonSuccess(satelliteComparison));
  } catch (error) {
    yield put(computeSatelliteComparisonError(error));
  }
}

/**
 * Launch satellite comparison
 */
export function* launchSatelliteComparisonWorker({ data }) {
  const comparison = yield call(createSatelliteComparisonWorker, { data });
  if (comparison) {
    yield put(trackComparisonAgainstSat());
    yield call(computeSatelliteComparisonWorker, { id: comparison.id });
  }
}

/**
 * Background task for update project with timeout
 */
function* updateSatelliteComparisonTask({ comparisonId }) {
  while (true) {
    const satelliteComparison = yield call(
      getSatelliteComparison,
      comparisonId
    );
    yield put(requestSatelliteComparisonSuccess(satelliteComparison));
    yield delay(UPDATE_SATELLITE_COMPARISON_TIMEOUT);
  }
}

/**
 * Start / stop background task for update project
 */
export function* startUpdateProjectJobWorker({ comparisonId }) {
  yield race({
    comparison: call(updateSatelliteComparisonTask, { comparisonId }),
    cancel: take(STOP_UPDATE_SATELLITE_COMPARISON_JOB),
  });

  yield put(finishUpdateSatelliteComparisonJob(comparisonId));
}

/**
 * Request statistic for satellite comparison
 */
export function* requestSatelliteComparisonStatsWorker({ comparisonId }) {
  try {
    const stats = yield call(getSatelliteComparisonStats, comparisonId);
    yield put(requestSatelliteComparisonStatsSuccess({ stats, comparisonId }));
  } catch (error) {
    yield put(requestSatelliteComparisonStatsError(error));
  }
}

/**
 * downloads Satellite stat result  and opens it in new browser tab
 */
export function* downloadSatelliteComparisonStatsWorker({ comparisonId }) {
  try {
    const comparison = yield call(
      downloadSatelliteComparisonStat,
      comparisonId
    );
    yield call(triggerDownloadBlob, {
      blob: comparison,
      fileType: ZIP_FILE_TYPE,
      fileName: `satellite_comparison_${comparisonId}.zip`,
      isDownload: true,
      isOpenInNewTab: false,
    });
    yield put(downloadSatelliteComparisonStatsSucess({ comparisonId }));
  } catch (error) {
    yield put(downloadSatelliteComparisonStatsError({ error, comparisonId }));
  }
}

export default function* offersSaga() {
  yield takeLatest(
    REQUEST_SATELLITE_COMPARISONS,
    requestSatelliteComparisonsWorker
  );
  yield takeLatest(
    CREATE_SATELLITE_COMPARISON,
    createSatelliteComparisonWorker
  );
  yield takeLatest(
    COMPUTE_SATELLITE_COMPARISON,
    computeSatelliteComparisonWorker
  );
  yield takeLatest(
    LAUNCH_SATELLITE_COMPARISON,
    launchSatelliteComparisonWorker
  );
  yield takeLatest(
    START_UPDATE_SATELLITE_COMPARISON_JOB,
    startUpdateProjectJobWorker
  );
  yield takeLatest(
    REQUEST_SATELLITE_COMPARISON_STATS,
    requestSatelliteComparisonStatsWorker
  );
  yield takeLatest(
    DOWNLOAD_SATELLITE_COMPARISON,
    downloadSatelliteComparisonStatsWorker
  );
}
