import React from 'react';
import PropsTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Grid, Button, IconButton } from '@material-ui/core';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import AddCircleRoundedIcon from '@material-ui/icons/AddCircleRounded';
import DeleteIcon from '@material-ui/icons/Delete';

import {
  DEFAULT_FORMAT_LIST,
  DEFAULT_PERCENTILES_LIST,
} from 'constants/marineContractors';

import { useStyles } from './styles';

/**
 * Percentile option component - custom option used in the auto complete percentil text field
 * @param { string } value
 * @param { function } handleDelete
 * @param { isCustom } isCustom
 * @return { JSX }
 */
const PercentileOption = React.memo(({ value, handleDelete, isCustom }) => {
  const valueRowSize = isCustom ? 10 : 12;
  return (
    <Grid container direction="row" justifyContent="center" alignItems="center">
      <Grid
        container
        direction="row"
        item
        justifyContent="flex-start"
        xs={valueRowSize}
      >
        {value}
      </Grid>
      {isCustom ? (
        <Grid container direction="row" item justifyContent="flex-end" xs={2}>
          <Grid item>
            <IconButton onClick={() => handleDelete(value)} size="small">
              <DeleteIcon color="error" fontSize="small" />
            </IconButton>
          </Grid>
        </Grid>
      ) : (
        <></>
      )}
    </Grid>
  );
});

PercentileOption.propTypes = {
  value: PropsTypes.string.isRequired,
  handleDelete: PropsTypes.func.isRequired,
  isCustom: PropsTypes.bool,
};

PercentileOption.defaultProps = {
  isCustom: false,
};

/**
 * Marine contractors outputs component - display the customer percentiles and output format for the computation
 * @param { Array } percentiles
 * @param { Array } selectedPercentiles
 * @param { string } selectedOutputFormat
 * @param { function } handleNext
 * @param { function } handleBack
 * @param { function } handlePercentiles
 * @param { function } handleOutputFormat
 * @param { function } handleAddPercentile
 * @param { function } handleDelete
 * @returns
 */
const MCOutputsComponent = ({
  percentiles,
  selectedPercentiles,
  selectedOutputFormat,
  disabled,
  handleNext,
  handleBack,
  handlePercentiles,
  handleOutputFormat,
  handleAddPercentile,
  handleDelete,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
    >
      <h1 className={classes.title}>{t('marineContractors.outputs.title')}</h1>
      <Grid item>
        <h2 className={classes.containerTitle}>
          {t('marineContractors.outputs.chooseRisks')}
        </h2>
        <Grid
          container
          item
          direction="row"
          alignItems="center"
          justifyContent="center"
          className={classes.selectionContainer}
        >
          <Grid item xs={10}>
            <Autocomplete
              multiple
              options={percentiles}
              getOptionLabel={(option) => option}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label={t('marineContractors.outputs.percentiles')}
                  placeholder=""
                />
              )}
              renderOption={(value) => (
                <PercentileOption
                  value={value}
                  handleDelete={handleDelete}
                  isCustom={
                    !DEFAULT_PERCENTILES_LIST.includes(value.toString())
                  }
                />
              )}
              value={selectedPercentiles}
              onChange={handlePercentiles}
            />
          </Grid>
          <Grid item xs={2}>
            <IconButton
              className={classes.addButton}
              onClick={handleAddPercentile}
            >
              <AddCircleRoundedIcon className={classes.addButton} />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <h2 className={classes.containerTitle}>
          {t('marineContractors.outputs.format')}
        </h2>
        <Grid
          container
          item
          direction="row"
          alignItems="center"
          justifyContent="flex-start"
          className={classes.selectionContainer}
        >
          <Grid item>
            <FormControl component="fieldset">
              <RadioGroup
                aria-label="outputs"
                name="outputs"
                value={selectedOutputFormat}
                onChange={handleOutputFormat}
              >
                {DEFAULT_FORMAT_LIST.map((name) => (
                  <FormControlLabel
                    key={name}
                    value={name}
                    control={<Radio />}
                    label={t(`marineContractors.outputs.labels.format.${name}`)}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
      <Grid item className={classes.buttonComponent}>
        <Button
          className={classes.button}
          disabled={!handleBack}
          onClick={handleBack}
        >
          {t('marineContractors.buttons.back')}
        </Button>
        <Button
          variant="contained"
          color="secondary"
          className={classes.button}
          disabled={
            selectedPercentiles.length > 0 && selectedOutputFormat !== null
              ? disabled || !handleNext
              : true
          }
          onClick={handleNext && !disabled ? handleNext : null}
        >
          {t('marineContractors.buttons.launch')}
        </Button>
      </Grid>
    </Grid>
  );
};

MCOutputsComponent.propTypes = {
  percentiles: PropsTypes.array.isRequired,
  selectedPercentiles: PropsTypes.array.isRequired,
  selectedOutputFormat: PropsTypes.string.isRequired,
  handleNext: PropsTypes.func.isRequired,
  handleBack: PropsTypes.func.isRequired,
  handlePercentiles: PropsTypes.func.isRequired,
  handleOutputFormat: PropsTypes.func.isRequired,
  handleAddPercentile: PropsTypes.func.isRequired,
  handleDelete: PropsTypes.func.isRequired,
};

export default React.memo(MCOutputsComponent);
