import React, { memo, useCallback, useMemo } from 'react';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { useDispatch } from 'react-redux';

import FormInput from 'components/common/FormInput';
import { useForm } from 'hooks/useForm';
import { trackSubmit } from 'ducks/trackers/actions/AWD';

import { useStyles } from '../styles';
import PlanningFormSelect from '../PlanningFormSelect';
import LocationTitles from '../LocationTitles';
import SectionsCoordinatesTitles from '../SectionsCoordinatesTitles';

import { validationRules } from './validationRules';
import { defaultValues } from './defaultValues';

/**
 * Displays planning settings modal form
 * @param { function } onClose - close handler
 * @param { function } onSuccess - success handler
 * @param { Object } points - current project points
 * @param { string } fileName - loaded file name
 * @param { string } percentiles - series of integers between 1 and 99, separated by commas
 * @param { string } tasksCoords - coordinates of tasks table in planning
 * @param { string } envCoords - coordinates of environments table in planning
 * @param { string } userEmail - current user email
 * @param { Object } locationAliases - correspondence between planning location and project location
 * @param { Object } formats - output downtime format (mapping between format name and pretty name)
 */
const PlanningForm = ({
  onClose,
  onSuccess,
  points,
  fileName,
  percentiles,
  envCoords,
  tasksCoords,
  userEmail,
  locationAliases,
  formats,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const outputDir = fileName.split('.')[0];

  const initialValues = useMemo(
    () => ({
      outputDir,
      percentiles,
      envCoords,
      tasksCoords,
      locationAliases,
      formats,
    }),
    [outputDir, percentiles, envCoords, tasksCoords, locationAliases, formats]
  );

  const {
    handleSubmit,
    handleBlur,
    handleChange,
    values,
    errors,
    updateField,
  } = useForm({
    defaultValues,
    validationRules,
    onSuccess,
    initialValues,
  });

  const pointsOptionList = useMemo(
    () =>
      Object.entries(points).map(([value, option]) => ({
        value,
        option,
      })),
    [points]
  );

  const changeAlias = useCallback(
    ({ target }) => {
      updateField('locationAliases', (aliases) =>
        aliases.map((location) => {
          if (location.alias === target.name) {
            location.point = parseInt(target.value, 10);
          }
          return location;
        })
      );
    },
    [updateField]
  );

  const changeFormat = useCallback(
    ({ target }) => {
      updateField('formats', (fmts) =>
        fmts.map((f) => ({
          selected: f.value === target.value,
          value: f.value,
        }))
      );
    },
    [updateField]
  );

  const handleClickSave = (e) => {
    handleSubmit(e);
    dispatch(trackSubmit());
  };

  return (
    <form>
      <FormInput
        handleBlur={handleBlur}
        handleChange={handleChange}
        label={t('downtime.planningSettings.nameOfOutput')}
        name="outputDir"
        error={errors.outputDir}
        value={values.outputDir}
      />
      <FormInput
        handleBlur={handleBlur}
        handleChange={handleChange}
        label={t('downtime.planningSettings.percentiles')}
        name="percentiles"
        error={errors.percentiles}
        value={values.percentiles}
        helperText={t('downtime.planningSettings.percentilesTitle')}
      />
      <div className={classes.formGroup}>
        <LocationTitles />
        {locationAliases.map((location) => (
          <PlanningFormSelect
            key={location.alias}
            label={location.alias}
            name={location.alias}
            handleChange={changeAlias}
            value={location.point}
            optionList={pointsOptionList}
          />
        ))}
      </div>
      <div className={classes.formGroup}>
        <Typography align="center">
          <strong>{t('downtime.planningSettings.sectionCoordinates')}</strong>
        </Typography>
        <FormInput
          handleBlur={handleBlur}
          handleChange={handleChange}
          label={t('downtime.planningSettings.environmentsTable')}
          name="envCoords"
          error={errors.envCoords}
          value={values.envCoords}
          helperText={t('downtime.planningSettings.coordinatesHelpText')}
        />
        <FormInput
          handleBlur={handleBlur}
          handleChange={handleChange}
          label={t('downtime.planningSettings.tasksTable')}
          name="tasksCoords"
          error={errors.tasksCoords}
          value={values.tasksCoords}
          helperText={t('downtime.planningSettings.coordinatesHelpText')}
        />
      </div>

      <div className={classes.formGroup}>
        <Typography align="center">
          <strong>
            {t('downtime.planningSettings.sectionDowntimeFormat')}
          </strong>
        </Typography>
        <RadioGroup
          row
          name="formats"
          onChange={changeFormat}
          align="center"
          className={classes.radioGroup}
        >
          {values.formats.map((downtimeFormat) => (
            <FormControlLabel
              key={downtimeFormat.value}
              value={downtimeFormat.value}
              control={<Radio checked={downtimeFormat.selected} />}
              label={
                <small>
                  {t(
                    `downtime.planningSettings.downtimeFormat.${downtimeFormat.value}`
                  )}
                </small>
              }
              labelPlacement="bottom"
            />
          ))}
        </RadioGroup>
      </div>

      <SectionsCoordinatesTitles userEmail={userEmail} />
      <div className={classes.buttons}>
        <Button
          variant="contained"
          onClick={onClose}
          className={classes.closeButton}
        >
          {t('buttons.close')}
        </Button>
        <Button variant="contained" color="secondary" onClick={handleClickSave}>
          {t('buttons.submit')}
        </Button>
      </div>
    </form>
  );
};

PlanningForm.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  points: PropTypes.object.isRequired,
  tasksCoords: PropTypes.string.isRequired,
  fileName: PropTypes.string.isRequired,
  envCoords: PropTypes.string.isRequired,
  percentiles: PropTypes.string.isRequired,
  userEmail: PropTypes.string.isRequired,
  locationAliases: PropTypes.array.isRequired,
  formats: PropTypes.array.isRequired,
};

export default memo(PlanningForm);
