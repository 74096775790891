import React, { useState, useCallback, useEffect } from 'react';
import PropsTypes from 'prop-types';
import { Grid, Typography, Checkbox, IconButton } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import DeleteIcon from '@material-ui/icons/Delete';
import UpdateIcon from '@material-ui/icons/Update';
import classNames from 'classnames';

import BlockUpdateComponent from 'components/common/MarineContractors/BlockUpdateComponent/index';
import EditableTextField from 'components/common/MarineContractors/EditableTextField/index';

import { useStyles } from '../styles';

/**
 * Operation component - display the operation main informations name and duration
 * @param { number } id
 * @param { string } name
 * @param { number } duration
 * @param { boolean } isChecked
 * @param { function } handleOperations
 * @param { function } handleOnDelete
 * @param { object } forceFocus
 * @param { boolean } blockDeletion - hide delete button and display a circular progress
 * @returns { JSX }
 */
const OperationComponent = React.memo(
  ({
    operation,
    isChecked,
    isSelected,
    handleSelect,
    handleOperations,
    handleOnChange,
    handleOnDelete,
    handleHasValid,
    forceFocus,
    blockDeletion,
  }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [checked, setChecked] = useState(isChecked);

    useEffect(() => {
      setChecked(isChecked);
    }, [isChecked]);

    const handleOnCheck = useCallback(
      (e) => {
        setChecked(e.target.checked);
        handleOperations(e);
      },
      [setChecked, handleOperations]
    );

    return (
      <Grid
        onClick={() => handleSelect(operation.id)}
        container
        direction="row"
        alignItems="center"
        justifyContent="center"
        className={classNames(
          isSelected ? classes.gridItemSelected : classes.gridItem,
          classes.mainContainer
        )}
      >
        <Grid
          item
          container
          direction="row"
          alignItems="center"
          justifyContent="center"
          xs={2}
        >
          <Grid item>
            <Checkbox
              value={operation.id}
              checked={checked}
              onChange={handleOnCheck}
            />
          </Grid>
          <Grid>
            <Grid item xs={2} className={classes.gridCenter}>
              <UpdateIcon color="primary" />
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          item
          direction="row"
          alignItems="center"
          justifyContent="flex-start"
          xs={9}
        >
          <Grid item xs={8}>
            <EditableTextField
              object={operation}
              fieldName="name"
              canEdit={isSelected}
              forceFocus={forceFocus.name}
              forceTitle
              handleHasValid={handleHasValid}
              handleChange={handleOnChange}
            />
          </Grid>
          <Grid item xs={4}>
            <EditableTextField
              object={operation}
              fieldName="duration"
              isNumber
              canEdit={isSelected}
              placeholder="0"
              forceFocus={forceFocus.duration}
              forceTitle
              handleHasValid={handleHasValid}
              handleChange={handleOnChange}
            >
              <Typography>
                {t('marineContractors.fields.constraints.hourUnit')}
              </Typography>
            </EditableTextField>
          </Grid>
        </Grid>
        <Grid container item direction="row" xs={1}>
          <Grid item xs>
            <BlockUpdateComponent isUpdating={blockDeletion} noGrow isCircular>
              <IconButton onClick={handleOnDelete} size="small">
                <DeleteIcon color="error" fontSize="small" />
              </IconButton>
            </BlockUpdateComponent>
          </Grid>
        </Grid>
      </Grid>
    );
  }
);

OperationComponent.propTypes = {
  operation: PropsTypes.object.isRequired,
  isChecked: PropsTypes.bool,
  handleOperations: PropsTypes.func.isRequired,
  handleOnDelete: PropsTypes.func.isRequired,
  forceFocus: PropsTypes.object.isRequired,
  blockDeletion: PropsTypes.bool,
};

OperationComponent.defaultProps = {
  isChecked: false,
  blockDeletion: false,
};

export default React.memo(OperationComponent);
