import {
  roseHelpers,
  weatherHelpers,
  mvpHelpers,
  apdHelpers,
  jpdHelpers,
  pdSelectHelpers,
  tsHelpers,
  epdHelpers,
  extHelpers,
  extTableHelpers,
  eftTableHelpers,
} from 'helpers/graphs';
import DistributionRoseGraph from 'components/common/graphs/DistributionRoseGraph';
import MeanVerticalProfileGraph from 'components/common/graphs/MeanVerticalProfile';
import AnalyticalProbabilityDistributionGraph from 'components/common/graphs/AnalyticalProbabilityDistribution';
import WeatherGraph from 'components/common/graphs/WeatherGraph';
import JointProbabilityDistribution from 'components/common/graphs/JointProbabilityDistribution';
import TimeSeriesGraph from 'components/common/graphs/TimeSeriesGraph';
import {
  DP_VCLASS_TYPE,
  THETA_VCLASS_TYPE,
  THETAW_VCLASS_TYPE,
} from 'constants/graphs';
import EmpiricalProbabilityDistribution from 'components/common/graphs/EmpiricalProbabilityDistribution';
import ConcomitantTimeSeriesGraph from 'components/common/graphs/ConcomitantTimeSeriesGraph';
import ExtremeCharts from 'components/common/graphs/ExtremeCharts';
import NonCyclonicExtremeCharts from 'components/common/graphs/NonCyclonicExtremeCharts';
import ExtremeTableChart from 'components/common/graphs/ExtremeTableChart';
import ExportAllTimeSeriesCharts from 'components/common/graphs/ExportAllTimeSeriesCharts/index';

/**
 * @note don't import from graphs/constants here! (to avoid cyclic dependency)
 * separate file for graphs configuration
 * use it only for general graphs configurations. For specific graph constants use constants/graphs
 */

export const WEATHER_WINDOW_TYPE = 'WW';
export const DISTRIBUTION_ROSE_TYPE = 'DR';
export const MV_PROFILE_TYPE = 'MEANCOLSTD';
export const ANALYTICAL_PD_TYPE = 'APD';
export const WEATHER_DOWNTIME_TYPE = 'WD';
export const JOINT_PROBABILITY_TYPE = 'EJPD';
export const TIME_SERIES_TYPE = 'TS';
export const EMPIRICAL_PD_TYPE = 'EPD';
export const CONCOMITANT_TIME_SERIES_TYPE = 'CTS';
export const EXTREME_ESTIMATE_TYPE = 'EXT';
export const EXTREME_ESTIMATE_MAX_TYPE = 'MAEXT';
export const EXTREME_ESTIMATE_MIN_TYPE = 'MIEXT';
export const EXTREME_CURRENTS_TYPE = 'CUREXT';
export const EXTREME_WATER_HEIGHTS_TYPE = 'HEIEXT';
export const EXTREME_NON_CYCLONIC_ESTIMATE_TYPE = 'NOCYC';
export const EXPORT_ALL_TIME_SERIES = 'EFT';

export const MONTH_PERIODICITY = 'month';
export const ANNUAL_PERIODICITY = 'all';

export const PROP_NAMES_BY_PERIODICITY = {
  [MONTH_PERIODICITY]: 'monthlyData',
  [ANNUAL_PERIODICITY]: 'annualData',
};

export const GRAPHS_CONFIG_BY_TYPE = {
  [DISTRIBUTION_ROSE_TYPE]: {
    pngFileName: 'Distribution_Rose_at_point:{{POINT}}',
    component: DistributionRoseGraph,
    criticalProp: PROP_NAMES_BY_PERIODICITY[ANNUAL_PERIODICITY],
    prepareFunctions: {
      [MONTH_PERIODICITY]:
        roseHelpers.getPreparedDistributionRoseMonthlyGraphData,
      [ANNUAL_PERIODICITY]:
        roseHelpers.getPreparedDistributionRoseAnnualGraphData,
    },
  },
  [WEATHER_WINDOW_TYPE]: {
    component: WeatherGraph,
    pngFileName: 'Weather_Window_at_point:{{POINT}}',
    prepareFunctions: {
      [MONTH_PERIODICITY]: weatherHelpers.getPreparedWeatherGraphData,
    },
  },
  [MV_PROFILE_TYPE]: {
    component: MeanVerticalProfileGraph,
    pngFileName: 'Mean_Vertical_Profile_at_point:{{POINT}}',
    criticalProp: PROP_NAMES_BY_PERIODICITY[ANNUAL_PERIODICITY],
    prepareFunctions: {
      [MONTH_PERIODICITY]: mvpHelpers.getPreparedMvProfileMonthlyGraphData,
      [ANNUAL_PERIODICITY]: mvpHelpers.getPreparedMvProfileAnnualGraphData,
    },
  },
  [ANALYTICAL_PD_TYPE]: {
    pngFileName: 'Analytical_Probability_Distribution_at_point:{{POINT}}',
    component: AnalyticalProbabilityDistributionGraph,
    prepareFunctions: {
      [ANNUAL_PERIODICITY]:
        apdHelpers.getPreparedAnalyticalProbabilityGraphData,
    },
  },
  [WEATHER_DOWNTIME_TYPE]: {
    pngFileName: 'Weather_Downtime_at_point:{{POINT}}',
    component: WeatherGraph,
    prepareFunctions: {
      [MONTH_PERIODICITY]: weatherHelpers.getPreparedWeatherGraphData,
    },
  },
  [JOINT_PROBABILITY_TYPE]: {
    component: JointProbabilityDistribution,
    pngFileName: 'Empirical_Joint_Probability_Distribution_at_point:{{POINT}}',
    criticalProp: PROP_NAMES_BY_PERIODICITY[ANNUAL_PERIODICITY],
    prepareFunctions: {
      [MONTH_PERIODICITY]: jpdHelpers.getJPDistributionCommonData,
      [ANNUAL_PERIODICITY]: jpdHelpers.getJPDistributionCommonData,
    },
  },
  [TIME_SERIES_TYPE]: {
    pngFileName: 'Time_Series_at_point:{{POINT}}',
    component: TimeSeriesGraph,
    prepareFunctions: {
      [ANNUAL_PERIODICITY]: tsHelpers.getPreparedTimeSeriesData,
    },
  },
  [CONCOMITANT_TIME_SERIES_TYPE]: {
    pngFileName: 'Concomitant_Time_Series_at_point:{{POINT}}',
    component: ConcomitantTimeSeriesGraph,
    prepareFunctions: {
      [ANNUAL_PERIODICITY]: tsHelpers.getPreparedConcomitantTimeSeriesData,
    },
  },
  [EMPIRICAL_PD_TYPE]: {
    pngFileName: 'Empirical_Probability_Distribution_at_point:{{POINT}}',
    component: EmpiricalProbabilityDistribution,
    criticalProp: PROP_NAMES_BY_PERIODICITY[ANNUAL_PERIODICITY],
    prepareFunctions: {
      [MONTH_PERIODICITY]:
        epdHelpers.getPreparedEmpiricalProbabilityMonthlyGraphData,
      [ANNUAL_PERIODICITY]:
        epdHelpers.getPreparedEmpiricalProbabilityAnnualGraphData,
    },
  },
  [EXTREME_ESTIMATE_TYPE]: {
    pngFileName: 'Extreme_Estimate_at_point:{{POINT}}',
    component: ExtremeCharts,
    prepareFunctions: {
      [ANNUAL_PERIODICITY]: extHelpers.getPreparedExtremeValuesData,
    },
  },
  [EXTREME_ESTIMATE_MIN_TYPE]: {
    pngFileName: 'Extreme_Estimate_Min_at_point:{{POINT}}',
    component: ExtremeCharts,
    prepareFunctions: {
      [ANNUAL_PERIODICITY]: extHelpers.getPreparedExtremeValuesData,
    },
  },
  [EXTREME_ESTIMATE_MAX_TYPE]: {
    pngFileName: 'Extreme_Estimate_Max_at_point:{{POINT}}',
    component: ExtremeCharts,
    prepareFunctions: {
      [ANNUAL_PERIODICITY]: extHelpers.getPreparedExtremeValuesData,
    },
  },
  [EXTREME_CURRENTS_TYPE]: {
    pngFileName: 'Extreme_Currents_at_point:{{POINT}}',
    component: ExtremeTableChart,
    prepareFunctions: {
      [ANNUAL_PERIODICITY]: extTableHelpers.getPreparedExtremeTablesData,
    },
  },
  [EXTREME_WATER_HEIGHTS_TYPE]: {
    pngFileName: 'Extreme_Water_Heights_at_point:{{POINT}}',
    component: ExtremeTableChart,
    prepareFunctions: {
      [ANNUAL_PERIODICITY]: extTableHelpers.getPreparedExtremeTablesData,
    },
  },
  [EXTREME_NON_CYCLONIC_ESTIMATE_TYPE]: {
    pngFileName: 'Non_Cyclonic_Extreme_Estimate_at_point:{{POINT}}',
    component: NonCyclonicExtremeCharts,
    prepareFunctions: {
      [ANNUAL_PERIODICITY]: extHelpers.getPreparedNonCyclonicExtremeValuesData,
    },
  },
  [EXPORT_ALL_TIME_SERIES]: {
    pngFileName: 'Export_All_Time_Series_at_point:{{POINT}}',
    component: ExportAllTimeSeriesCharts,
    prepareFunctions: {
      [ANNUAL_PERIODICITY]: eftTableHelpers.formatData,
    },
  },
};

export const WIDGETS_PREPARE_FUNCTIONS_BY_TYPE = {
  [DP_VCLASS_TYPE]: {
    [MONTH_PERIODICITY]: pdSelectHelpers.getPreparedPDSelectMonthlyData,
    [ANNUAL_PERIODICITY]: pdSelectHelpers.getPreparedPDSelectAnnualData,
  },
  [THETAW_VCLASS_TYPE]: {
    [MONTH_PERIODICITY]: pdSelectHelpers.getPreparedPDSelectMonthlyData,
    [ANNUAL_PERIODICITY]: pdSelectHelpers.getPreparedPDSelectAnnualData,
  },
  [THETA_VCLASS_TYPE]: {
    [MONTH_PERIODICITY]: pdSelectHelpers.getPreparedPDSelectMonthlyData,
    [ANNUAL_PERIODICITY]: pdSelectHelpers.getPreparedPDSelectAnnualData,
  },
};

/**
 * graph types that are ready to show
 * TODO get rid of this when all graphs are ready
 */
export const READY_GRAPH_TYPES = [
  MV_PROFILE_TYPE,
  WEATHER_WINDOW_TYPE,
  DISTRIBUTION_ROSE_TYPE,
  ANALYTICAL_PD_TYPE,
  WEATHER_DOWNTIME_TYPE,
  JOINT_PROBABILITY_TYPE,
  TIME_SERIES_TYPE,
  CONCOMITANT_TIME_SERIES_TYPE,
  EMPIRICAL_PD_TYPE,
  EXTREME_ESTIMATE_TYPE,
  EXTREME_ESTIMATE_MIN_TYPE,
  EXTREME_ESTIMATE_MAX_TYPE,
  EXTREME_WATER_HEIGHTS_TYPE,
  EXTREME_CURRENTS_TYPE,
  EXTREME_NON_CYCLONIC_ESTIMATE_TYPE,
];

/**
 * types that are not implemented yet for some reason
 * TODO rm when ready, or remove on server if not needed this types anymore
 */
export const HIDDEN_GRAPH_TYPES = ['MEXT', 'EXC'];

/* stats to run */
export const STATS_TO_RUN = ['EFT'];
