import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@material-ui/core/styles';

import { MapPolygon } from 'components/leaflet';
import { sortZoneByArea } from 'helpers/map';

/**
 * Render zones for map
 * @param { array } zones - available zones
 * @param { array } offerZoneIds - zone ids available for selected offer
 * @param { Number } selectedZoneId
 * @param { React.ReactNode } children
 * @param { Function } add
 * @param { Function } remove
 * @param { Function } onClick
 * @param { Object } props
 */
const ZonesGroup = ({
  zones,
  offerZoneIds,
  add,
  remove,
  onClick,
  selectedZoneId,
  children,
  ...props
}) => {
  const { palette } = useTheme();

  const greenColor = palette.secondary.main;
  const blueColor = palette.blue.main;
  const sortedZones = useMemo(() => sortZoneByArea(zones, offerZoneIds), [
    zones,
    offerZoneIds,
  ]);

  return sortedZones.map(({ id, name, geom, isWorld, isDemo }) => {
    const inOffer = offerZoneIds.includes(id);

    const fillOpacity = isWorld ? 0 : 0.1;
    const color = {
      [isDemo]: greenColor,
      [inOffer]: blueColor,
    }.true;

    return (
      <MapPolygon
        {...props}
        onClick={onClick}
        key={id}
        add={add}
        remove={remove}
        geometry={geom}
        fillOpacity={fillOpacity}
        color={color}
        allowOpenPopupOnSelect
        selected={selectedZoneId === id}
        customProps={{ id, name, inOffer, isDemo }}
        highlightColor
      >
        {children}
      </MapPolygon>
    );
  });
};

ZonesGroup.propTypes = {
  zones: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      geom: PropTypes.string,
      isWorld: PropTypes.bool,
      isDemo: PropTypes.bool,
    })
  ).isRequired,
  offerZoneIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  add: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  remove: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

export default memo(ZonesGroup);
